import React, { useState, useRef, useEffect } from 'react';
import FsAccordion from '../../../../components/baseComponents/fsAccordion/FsAccordion';
import FsModal from '../../../../components/baseComponents/fsModal/FsModal';
import FsButton from '../../../../components/baseComponents/fsButton/FsButton';
import ApplicantFormSubsection from '../../ApplicantFormSubsection';
import { accountsModalSchema } from '../../../../../utils/affordablePurchase/accountsModalSchema';
import { AgGridReact } from 'ag-grid-react';
import linkRenderer from '../../../../components/DeleteLinkRenderer';
import {
  addErrorField,
  getFieldHasError,
  removeErrorField,
} from '../../../../../utils/affordablePurchase/formStateUtils';
import './financials.scss';

const Financials = ({
  errorFields,
  formState,
  handleChange,
  handleSave,
  handleUpload,
  layout,
  setErrorFields,
  setFormState,
}) => {
  const [isMobile, setIsMobile] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [flag, setFlag] = useState(false);
  const [columnDefs, setColumnDefs] = useState([
    {
      field: 'accountName',
      headerName: 'Name',
      sortable: 'true',
      filter: 'agTextColumnFilter',
      width: 150,
    },
    {
      field: 'accountType',
      headerName: 'Type',
      sortable: 'true',
      filter: 'agTextColumnFilter',
      width: 150,
    },
    {
      field: 'balance',
      headerName: 'Balance',
      sortable: 'true',
      filter: 'agTextColumnFilter',
      width: 150,
    },
    {
      field: 'financialInstitution',
      headerName: 'Financial Institution',
      sortable: 'true',
      filter: 'agTextColumnFilter',
      width: 200,
    },
    {
      field: 'accountOwner',
      headerName: 'Account Owner',
      sortable: 'true',
      filter: 'agTextColumnFilter',
      width: 200,
    },
    {
      headerName: 'Action',
      width: 120,
      cellRenderer: 'linkRenderer',
      pinned: 'right',
    },
  ]);
  const [documentUploaded, setDocumentUploaded] = useState(false);
  const [hasAddedAccount, setHasAddedAccount] = useState(false);
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);
  const [newAccount, setNewAccount] = useState({});
  const [showAccountsModal, setShowAccountsModal] = useState(false);

  useEffect(() => {
    if (!showAccountsModal) return;
    let updatedIsSaveButtonDisabled = true;

    const fieldsNotValid = accountsModalSchema().some((field) => {
      const fieldHasError = getFieldHasError(
        field,
        newAccount[field.id],
        newAccount
      );
      if (fieldHasError) return true;
      if (errorFields.includes(field.id)) return true;
      return false;
    });

    if (!fieldsNotValid && documentUploaded) {
      updatedIsSaveButtonDisabled = false;
    }
    setIsSaveButtonDisabled(updatedIsSaveButtonDisabled);
  }, [documentUploaded, newAccount]);

  const gridRef = useRef();

  const handleAddAccountButtonClick = () => {
    setShowAccountsModal(true);
  };

  const handleAccountsModalClose = () => {
    setDocumentUploaded(false);
    setNewAccount({});
    let updatedErrorFields = errorFields;
    accountsModalSchema().forEach((field) => {
      updatedErrorFields = removeErrorField(updatedErrorFields, field.id);
    });
    setErrorFields(updatedErrorFields);
    setShowAccountsModal(false);
    setIsSaveButtonDisabled(true);
  };

  const handleAddAccount = () => {
    let updatedAccounts = formState.accounts || [];
    const updatedBankStatements = formState.bankStatements || [];

    newAccount.bankStatements.forEach((bankStatement) => {
      updatedBankStatements.push(bankStatement);
    });

    let filtered = errorFields.filter((field) => {
      return field !== 'accounts';
    });

    setErrorFields(filtered);

    delete newAccount.bankStatements;
    updatedAccounts.push(newAccount);

    setFormState({
      ...formState,
      accounts: updatedAccounts,
      bankStatements: updatedBankStatements,
    });

    gridRef.current.api.setRowData(updatedAccounts);
    setHasAddedAccount(true);
    setShowAccountsModal(false);
  };

  const handleUploadModal = (e) => {
    let targetField = e.target.id;
    const filelist = e.target.files;

    const prevDocumentFiles = newAccount[targetField] || [];
    const updatedDocumentFiles = [...prevDocumentFiles, ...filelist];
    setNewAccount({ ...newAccount, [targetField]: updatedDocumentFiles });

    if (setDocumentUploaded) setDocumentUploaded(true);
    setErrorFields((prevErrorFields) =>
      prevErrorFields.filter((field) => field !== targetField)
    );
  };

  const onModalChange = (e, field) => {
    setNewAccount({ ...newAccount, [field.id]: e.target.value });

    let updatedErrorFields = errorFields;
    const fieldHasError = getFieldHasError(field, e.target.value, formState);

    if (fieldHasError) {
      updatedErrorFields = addErrorField(updatedErrorFields, field.id);
    } else {
      updatedErrorFields = removeErrorField(updatedErrorFields, field.id);
    }

    // unique change for account balance
    if (field.id === 'balance') {
      const inputValue = parseFloat(e.target.value || 0);
      if (inputValue > 5000000) {
        updatedErrorFields = addErrorField(updatedErrorFields, 'balance');
      }
    }

    setErrorFields(updatedErrorFields);
  };

  useEffect(() => {
    if (hasAddedAccount) {
      handleSave();
      setDocumentUploaded(false);
      setIsSaveButtonDisabled(true);
      setNewAccount({});
    }
    setHasAddedAccount(false);
    setAccounts(formState.accounts);
  }, [formState]);

  useEffect(() => {
    if (flag) {
      handleSave();
      setFlag(false);
    }
  }, [flag]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (isMobile) {
      setColumnDefs([
        {
          field: 'accountName',
          headerName: 'Name',
          sortable: 'true',
          filter: 'agTextColumnFilter',
          width: 150,
        },
        {
          field: 'accountOwner',
          headerName: 'Account Owner',
          sortable: 'true',
          filter: 'agTextColumnFilter',
          width: 200,
        },
        {
          headerName: 'Action',
          width: 120,
          cellRenderer: 'linkRenderer',
          pinned: 'right',
        },
      ]);
    }
  }, [isMobile]);

  const onGridReady = () => {
    gridRef.current.api.sizeColumnsToFit();
  };

  const accountsModalContent = (
    <ApplicantFormSubsection
      errorFields={errorFields}
      formState={formState}
      handleUpload={handleUploadModal}
      isModal={true}
      isJointApplication={false}
      layout={accountsModalSchema(formState)}
      onChange={onModalChange}
      overrideFormState={newAccount}
      setDocumentUploaded={setDocumentUploaded}
      setErrorFields={setErrorFields}
      setFormState={setFormState}
    />
  );

  const deleteAccount = (id) => {
    const filtered = accounts.filter((account) => {
      return account.id !== id;
    });
    setAccounts(filtered);

    setFormState({ ...formState, accounts: filtered });
    setFlag(true);
  };

  const accountsBodyContent = (
    <>
      <div className="button-wrapper-div">
        <FsButton
          buttonText="Add Account"
          onClick={handleAddAccountButtonClick}
          id="addAccountButton"
        />
      </div>
      <div
        className="ag-theme-material"
        style={{
          height: '400px',
          overflow: 'scroll',
        }}
      >
        <AgGridReact
          ref={gridRef}
          height={100}
          reactUi={true}
          rowData={accounts}
          defaultColDef={{
            sortable: 'true',
            resizable: true,
            cellStyle: { textAlign: 'left' },
            editable: false,
          }}
          columnDefs={columnDefs}
          context={{
            deleteCall: (id) => deleteAccount(id),
          }}
          frameworkComponents={{
            linkRenderer: linkRenderer,
          }}
          overlayNoRowsTemplate={
            '<span style="padding: 10px;  color:red; background: white">You must upload at least 1 account *</span>'
          }
        ></AgGridReact>
      </div>
    </>
  );

  const mortgageBodyContent = layout.map((section, index) => {
    return (
      <ApplicantFormSubsection
        errorFields={errorFields}
        formState={formState}
        handleSave={handleSave}
        handleUpload={handleUpload}
        isJointApplication={false}
        key={index}
        layout={section}
        onChange={handleChange}
        setErrorFields={setErrorFields}
        setFormState={setFormState}
      />
    );
  });

  return (
    <div className="financials-wrapper">
      <h5 className="affordable-purchase-form-section-header">Financials</h5>
      <FsAccordion headerContent="Accounts" bodyContent={accountsBodyContent} />
      <FsAccordion
        headerContent="Mortgage Approval"
        bodyContent={mortgageBodyContent}
      />

      {showAccountsModal && (
        <FsModal
          content={accountsModalContent}
          handleClose={handleAccountsModalClose}
          handleSave={handleAddAccount}
          headerText="Add Account"
          isSaveButtonDisabled={isSaveButtonDisabled}
        />
      )}
    </div>
  );
};

export default Financials;
