import React, { useState, useEffect } from 'react';
import ApplicantDetailsTopper from './ApplicantDetailsTopper';
import ApplicantsTab from './applicantsTab/ApplicantsTab';
import HouseholdTab from './householdTab/HouseholdTab';
import FinancesTab from './financesTab/FinancesTab';
import CommentsTab from './commentsTab/CommentsTab';
import DocumentsList from '../../components/DocumentsList';
import useAxios from '../../hooks/useAxios';

import './applicantDetails.scss';

const ApplicantDetails = ({ id }) => {
  const [applicantData, setApplicantData] = useState({});
  const [applicationData, setApplicationData] = useState({});
  const [jointApplicantData, setJointApplicantData] = useState({});
  const [documents, setDocuments] = useState([]);
  const [documentCount, setDocumentCount] = useState();
  const [documentTypes, setDocumentTypes] = useState([]);
  const [activeTab, setActiveTab] = useState('Applicants');
  const [comments, setComments] = useState([]);

  const { callAxios } = useAxios();

  const getApplicationDetails = async () => {
    const res = await callAxios('get', `/application/${id}`);

    setApplicantData(res.data.applicants?.mainApplicant);
    setApplicationData(res.data.application);
    setJointApplicantData(res.data.applicants?.jointApplicant ?? {});
    setDocuments(res.data.documents);
    setDocumentCount(res.data.documentCount);
    setDocumentTypes(res.data.documentTypes ?? []);
    setComments(res.data.application.comments ?? []);
  };

  useEffect(() => {
    getApplicationDetails();
  }, []);

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  return (
    <>
      <ApplicantDetailsTopper
        activeTab={activeTab}
        handleTabClick={handleTabClick}
        applicantData={applicantData}
        applicationData={applicationData}
      />
      <div className="applicant-details-main-content">
        {activeTab === 'Applicants' && (
          <ApplicantsTab
            applicantData={applicantData}
            applicationData={applicationData}
            jointApplicantData={jointApplicantData}
          />
        )}
        {activeTab === 'Household' && (
          <HouseholdTab applicantData={applicantData} />
        )}
        {activeTab === 'Finances' && (
          <FinancesTab
            applicantData={applicantData}
            jointApplicantData={jointApplicantData}
          />
        )}
        {activeTab === 'Documents' && (
          <DocumentsList
            applicantData={applicantData}
            applicationData={applicationData}
            documents={documents}
            documentCount={documentCount}
            documentTypes={documentTypes}
            getApplicationDetails={getApplicationDetails}
            setApplicantData={setApplicantData}
          />
        )}
        {activeTab === 'Comments' && (
          <CommentsTab
            comments={comments}
            setComments={setComments}
            applicationId={id}
          />
        )}
      </div>
    </>
  );
};

export default ApplicantDetails;
