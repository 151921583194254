import React, { Component } from 'react';
import EditSharpIcon from '@material-ui/icons/EditSharp';
import DeleteForeverSharpIcon from '@mui/icons-material/DeleteForeverSharp';

export default class EDlinkRenderer extends Component {
  render() {
    const rowId = this.props.data.id;
    return (
      <div>
        {this.props.context.editCall && (
          <span className={'tableIconButton'}>
            <EditSharpIcon
              onClick={() => {
                this.props.context.editCall(rowId);
              }}
            />
          </span>
        )}
        {this.props.context.deleteCall && (
          <span className={'tableIconButton'}>
            <DeleteForeverSharpIcon
              onClick={() => {
                this.props.context.deleteCall(rowId);
              }}
            />
          </span>
        )}
      </div>
    );
  }
}
