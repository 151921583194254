import React from 'react';

const InfoText = () => {
  return (
    <div className="info-text-wrapper">
      <h5 className="affordable-purchase-form-section-header">
        Affordable Purchase Overview
      </h5>

      <p className="affordable-purchase-form-text-area">
        The ‘Local Authority Affordable Purchase Scheme’ sees local authorities
        make new homes available for purchase by eligible applicants who are
        seeking to purchase a newly built home but need to additional funding to
        bridge the gap between their mortgage and deposit to cover the entire
        cost of the home by way of taking an equity stake or share of the
        ownership of the property under the Affordable Housing Scheme. The
        ‘Affordable Price’ is what is paid by applicant when Local Authorities
        equity share has been deducted from the full market price. Equity shares
        are calculated on an individual basis and are linked to applicants’
        income, savings (if any) and mortgage capacity (without any exemption
        from the{' '}
        <a
          href="https://www.centralbank.ie/financial-system/financial-stability/macro-prudential-policy/mortgage-measures"
          target="_blank"
        >
          Central Bank of Ireland Mortgage Measures
        </a>
        ), in accordance with government regulations.
      </p>
    </div>
  );
};

export default InfoText;
