import React, { useEffect, useState } from 'react';
import FsButton from '../../components/baseComponents/fsButton/FsButton';
import { getClassNameFromString } from '../../../utils/getClassNameFromString';
import { useNavigate } from 'react-router-dom';
import {
  getFormattedDateUtil,
  isCurrentDateAfter,
  isCurrentDateBefore,
} from '../../../utils/utils';

import './developmentCard.scss';

const DevelopmentCard = ({ development }) => {
  const navigate = useNavigate();

  const {
    applicationId,
    applicationStatus,
    developmentClosingDate,
    developmentOpeningDate,
    developmentName,
    developmentId,
  } = development;

  const [developmentStatus, setDevelopmentStatus] = useState(false);

  const handleButtonClick = () => {
    navigate(`/applicant/applicationForm/${developmentId}`);
  };

  const handleDocumentsClick = () => {
    navigate(`/applicant/documents/${developmentId}`);
  };

  const getButtonText = () => {
    if (applicationStatus.toLowerCase() === 'not started') {
      return 'Start';
    } else if (applicationStatus.toLowerCase() === 'in progress') {
      return 'Continue';
    }
  };

  useEffect(() => {
    if (isCurrentDateBefore(development.developmentOpeningDate)) {
      return setDevelopmentStatus('isOpening');
    } else if (isCurrentDateAfter(development.developmentClosingDate)) {
      return setDevelopmentStatus('isClosed');
    }
    return setDevelopmentStatus('isOpen');
  }, [development]);

  return (
    <div className="development-card-wrapper">
      <header className="development-card-header">
        <div
          className={`${getClassNameFromString(applicationStatus)}-badge badge`}
        >
          {applicationStatus}
        </div>
      </header>
      <div className="development-card-content">
        <h2>{developmentName}</h2>

        {developmentStatus === 'isOpening' && (
          <p>
            Opening Date:{' '}
            {getFormattedDateUtil({
              dateString: developmentOpeningDate,
              includeTime: true,
            })}
          </p>
        )}

        {developmentStatus === 'isClosed' && (
          <div className="closed-badge badge">Applications Closed</div>
        )}

        {developmentStatus === 'isOpen' && (
          <p>
            Closing Date:{' '}
            {getFormattedDateUtil({
              dateString: developmentClosingDate,
              includeTime: true,
            })}
          </p>
        )}

        {applicationStatus === 'Valid Application' && (
          <div>
            <span>
              Application ID: {applicationId}
              <br />
            </span>
            <span>
              <i>Your application is no longer editable </i>
            </span>
          </div>
        )}
      </div>
      <div className="development-card-button-wrapper">
        {applicationStatus.toLowerCase() !== 'not started' && (
          <FsButton buttonText="Documents" onClick={handleDocumentsClick} />
        )}

        <div>
          {developmentStatus === 'isOpen' &&
            (applicationStatus.toLowerCase() === 'not started' ||
              applicationStatus.toLowerCase() === 'in progress') && (
              <FsButton
                buttonText={getButtonText()}
                onClick={handleButtonClick}
              />
            )}
        </div>
      </div>
    </div>
  );
};

export default DevelopmentCard;
