import React, { useContext } from 'react';
import { SessionExpiryContext } from '../../contexts/sessionExpiryStore';
import FsModal from '../baseComponents/fsModal/FsModal';
import CountdownTimer from '../baseComponents/countdownTimer/CountdownTimer';

import './expiryModal.scss';
const ExpiryModal = () => {
  const { handleResetTimer, handleLogout, setIsExpiryModalOpen } =
    useContext(SessionExpiryContext);
  const modalContent = (
    <div>
      <div className="expiry-text">
        Your session is about to time out. Please click continue to stay logged
        in.
      </div>

      <CountdownTimer />
    </div>
  );

  const handleClose = () => {
    setIsExpiryModalOpen(false);
    handleLogout();
  };

  const handleContinue = () => {
    handleResetTimer();
  };

  return (
    <FsModal
      headerText="Session expiring"
      handleClose={handleClose}
      content={modalContent}
      handleSave={handleContinue}
      isSaveButtonDisabled={false}
      leftButtonText="Log Out"
      rightButtonText="Continue"
      halfWidth={true}
      hasCloseButton={false}
    />
  );
};

export default ExpiryModal;
