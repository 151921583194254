import { MONEY_FORMAT } from '../regexUtils';

export const selfEmployedSchema = (isJoint) => {
  const targetField = isJoint
    ? 'jointApplicant_employmentStatus'
    : 'employmentStatus';

  const occupationId = isJoint ? 'jointApplicant_occupation' : 'occupation';
  const typeOfBusinessId = isJoint
    ? 'jointApplicant_typeOfBusiness'
    : 'typeOfBusiness';
  const grossIncomeId = isJoint ? 'jointApplicant_grossIncome' : 'grossIncome';
  const proofOfIncomeId = isJoint
    ? 'jointApplicant_proofOfIncome'
    : 'proofOfIncome';

  const checkIsVisible = (formState) => {
    if (formState[targetField] === 'Self Employed') {
      return true;
    }
    return false;
  };

  const requiredRulesEmployed = (formState) => {
    if (
      formState[targetField] === 'Employed' ||
      formState[targetField] === 'Self Employed'
    ) {
      return true;
    }
    return false;
  };

  const requiredRulesAllEmployment = (formState) => {
    if (
      formState[targetField] === 'Employed' ||
      formState[targetField] === 'Self Employed' ||
      formState[targetField] === 'Not Employed'
    ) {
      return true;
    }
    return false;
  };

  return [
    {
      errorMessage: 'Field required',
      id: occupationId,
      checkIsVisible,
      placeholder: 'Occupation',
      requiredRules: requiredRulesEmployed,
      type: 'text',
    },
    {
      errorMessage: 'Field required',
      id: typeOfBusinessId,
      checkIsVisible,
      placeholder: 'Type of Business',
      requiredRules: requiredRulesEmployed,
      type: 'text',
    },
    {
      errorMessage: 'Not a valid value',
      id: grossIncomeId,
      checkIsVisible,
      placeholder: 'Annual Gross Income',
      regex: MONEY_FORMAT,
      requiredRules: requiredRulesEmployed,
      startAdornment: '€',
      type: 'number',
    },
    {
      errorMessage: 'Document required',
      id: proofOfIncomeId,
      checkIsVisible,
      name: 'Proof of income *',
      requiredRules: requiredRulesAllEmployment,
      tooltip: 'Self Employed Income Help',
      type: 'uploader',
    },
  ];
};
