import React, { useContext, useRef, useState } from 'react';
import FsCard from '../fsCard/FsCard';
import FsButton from '../fsButton/FsButton';

import './fsWizard.scss';
import { FaCheck, FaRegWindowClose } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { SelectedDevelopmentContext } from '../../../contexts/selectedDevelopmentStore';

const FsWizard = ({
  activeStep,
  checkFieldsForErrors,
  completedSteps,
  formState,
  onSubmit,
  setActiveStep,
  steps,
  submitButtonDisabled,
  handleSave,
}) => {
  const [showCantSubmitModal, setShowCantSubmitModal] = useState(false);

  const navigate = useNavigate();
  const { selectedDevelopment } = useContext(SelectedDevelopmentContext);

  const handleStepClick = async (index) => {
    const response = await handleSave();
    if (response) setActiveStep(index);
  };

  const handleBack = async () => {
    const response = await handleSave();
    if (response) setActiveStep(activeStep - 1);
  };

  const handleNext = async () => {
    const response = await handleSave();
    if (response) setActiveStep(activeStep + 1);
  };

  const handleSubmit = () => {
    if (submitButtonDisabled) {
      setShowCantSubmitModal(true);
      // Update every field in errorFields where needed
      checkFieldsForErrors();
    } else {
      onSubmit();
    }
  };

  const handleDocumentsClick = () => {
    handleSave();
    navigate(`/applicant/documents/${selectedDevelopment.id}`);
  };

  const wizardStepClassName = (activeStep, index) => {
    const baseClassName = 'wizard-steps-list-item';
    const isActive = activeStep === index;
    const isStepCompleted = completedSteps.includes(index);

    if (isActive && isStepCompleted) return baseClassName + ' active-complete';
    if (isActive) return baseClassName + ' active';
    if (isStepCompleted) return baseClassName + ' complete';
    return baseClassName;
  };

  const getCantSubmitTextContent = () => {
    let unfinishedSteps = [];
    steps.forEach((step, index) => {
      if (!completedSteps.includes(index)) {
        unfinishedSteps.push(steps[index]?.name);
      }
    });
    let baseText =
      'Please review the highlighted steps and populate all mandatory fields before you proceed.\n\n';
    if (unfinishedSteps.length) {
      baseText += 'Incomplete:\n';
      unfinishedSteps.forEach((unfinishedStep, index) => {
        baseText += '  ' + unfinishedStep + '\n';
      });
    }
    return baseText;
  };

  const useOutsideClick = (callback) => {
    const ref = useRef();

    React.useEffect(() => {
      const handleClick = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          callback();
        }
      };
      document.addEventListener('click', handleClick, true);

      return () => {
        document.removeEventListener('click', handleClick, true);
      };
    }, [ref]);

    return ref;
  };
  const handleClickOutside = () => {
    setShowCantSubmitModal(false);
  };
  const ref = useOutsideClick(handleClickOutside);

  const wizardContent = (
    <div className="wizard-content-wrapper">
      <div className="wizard-heading">
        <h2>{formState.developmentType}</h2>
        <FsButton
          buttonText={'Documents'}
          className="react-strap-button-primary"
          color="primary"
          onClick={handleDocumentsClick}
          variant="contained"
        ></FsButton>
      </div>
      <header className="wizard-header">
        <div className="wizard-steps">
          <ul className="wizard-steps-list">
            {steps.map((step, index) => {
              return (
                <div key={index} id={'wizardStep_' + index}>
                  <li
                    className={wizardStepClassName(activeStep, index)}
                    onClick={() => handleStepClick(index)}
                  >
                    {completedSteps.includes(index) ? (
                      <FaCheck />
                    ) : (
                      step.icon || index + 1
                    )}
                    <span className="wizard-step-name">{step.name}</span>
                  </li>
                </div>
              );
            })}
          </ul>
        </div>
      </header>

      <FsCard
        className="wizard-main-content"
        content={steps[activeStep].component}
      />

      <nav className="wizard-controls">
        <FsButton
          buttonText="Back"
          onClick={handleBack}
          disabled={activeStep === 0}
          className={`fs-button ${activeStep === 0 && 'fs-button-hidden'}`}
        />
        <FsButton
          onClick={handleSubmit}
          buttonText="Submit"
          className={`submit-button ${
            submitButtonDisabled && 'fs-button-disabled'
          }`}
        />

        {showCantSubmitModal && (
          <div className="cant-submit-modal" ref={ref}>
            <div className="cant-submit-content">
              <span>{getCantSubmitTextContent()}</span>
              <div
                className="close-cant-submit-modal"
                onClick={() => setShowCantSubmitModal(false)}
              >
                <FaRegWindowClose style={{ height: '22px', width: '22px' }} />
              </div>
            </div>
          </div>
        )}

        <FsButton
          buttonText="Next"
          onClick={handleNext}
          disabled={activeStep === steps.length - 1}
          className={`fs-button ${
            activeStep === steps.length - 1 && 'fs-button-hidden'
          }`}
        />
      </nav>
    </div>
  );

  return <div>{wizardContent}</div>;
};

export default FsWizard;
