import React, { useState, useEffect } from 'react';
import useAxios from '../../hooks/useAxios';
import DevelopmentCard from './DevelopmentCard';

import './applicationsLandingPage.scss';
const ApplicationsLandingPage = () => {
  const { callAxios } = useAxios();

  const [developments, setDevelopments] = useState([]);

  useEffect(() => {
    const getDevelopments = async () => {
      const response = await callAxios('get', '/my-developments');
      setDevelopments(response.data);
    };

    getDevelopments();
  }, []);

  return (
    <div className="application-landing-page-wrapper">
      <div>
        <h2>Walkthrough</h2>
      </div>
      <iframe
        className="video-iframe"
        src="https://www.youtube.com/embed/HmD0VxheEns"
        // title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>

      <div className="applications-header-wrapper">
        <h2>Applications</h2>
      </div>

      <div className="application-landing-page-card-wrapper">
        {developments.map((development, index) => {
          return (
            <DevelopmentCard
              key={`${development.applicationId}_${index}`}
              development={development}
            />
          );
        })}
      </div>
    </div>
  );
};

export default ApplicationsLandingPage;
