import { MONEY_FORMAT } from '../../utils/regexUtils';

const getAccountsModalSchema = (formState) => {
  let schema = [
    {
      errorMessage: 'Field required',
      id: 'accountName',
      placeholder: 'Account Name',
      required: true,
      type: 'text',
    },

    {
      errorMessage: 'Field required',
      id: 'accountType',
      label: 'What type of account is it?',
      options: [{ value: 'Current Account' }, { value: 'Savings Account' }],
      required: true,
      type: 'select',
    },
    {
      errorMessage: 'Not a valid value ( Up to 5 million )',
      id: 'balance',
      placeholder: 'Balance',
      regex: MONEY_FORMAT,
      required: true,
      startAdornment: '€',
      type: 'number',
      limit: 11,
    },
    {
      errorMessage: 'Field required',
      id: 'financialInstitution',
      placeholder: 'Financial Institution',
      required: true,
      type: 'text',
    },
    {
      errorMessage: 'Documents required',
      id: 'bankStatements',
      name: 'Upload 6 months banks statements',
      required: true,
      tooltip: 'Bank Statements',
      type: 'uploader',
    },
  ];

  // Meath seems to want it to always show
  // if (formState?.isJointApplication) {
  schema.push({
    errorMessage: 'Field required',
    id: 'accountOwner',
    label: 'Account Owner',
    required: true,
    type: 'select',
    options: [
      { value: 'Main Applicant' },
      { value: 'Joint Applicant' },
      { value: 'Both Applicants' },
    ],
  });
  // }

  return schema;
};

export const accountsModalSchema = (formState) => {
  return getAccountsModalSchema(formState);
};
