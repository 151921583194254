import React from 'react'
import DevelopmentDetails from "../components/DevelopmentDetails";
import { useParams } from 'react-router-dom';

function Development() {
    const { id } = useParams();
    return (
        <div>
            <DevelopmentDetails par={id} />
        </div>
      );
}


export default Development;
