import React, { Component } from 'react';
import { FaPrint, FaDownload, FaChevronRight } from 'react-icons/fa';
import {
  CircularProgress,
  Paper,
  FormControl,
  OutlinedInput,
  InputLabel,
  Card,
  List,
  ListItemText,
  ListItem,
  ListItemIcon,
} from '@material-ui/core';

import { Button, CardBody, CardTitle } from 'reactstrap';
import { Link } from 'react-router-dom';
import { getFormattedDateUtil } from '../../utils/utils';

class DevelopmentDetails extends Component {
  state = {
    development: [],
    proptypes: [],
    loading: true,
  };

  fetchdevelopment = async () => {
    const data = { id: this.props.par };
    const res = await axios.post('/findDevelopment', data);
    if (res.data.status === 200) {
      this.setState({ development: res.data.development });
      //console.log(this.state.development);
      const res2 = await axios.get('/getproptypes');
      if (res2.data.status === 200) {
        let proptypes = [];
        res2.data.proptypes.map((prop) => {
          if (
            typeof this.state.development.propimages[prop.id] !== 'undefined'
          ) {
            let proptype = {
              id: prop.id,
              name: prop.name,
              image: this.state.development.propimages[prop.id],
            };
            proptypes.push(proptype);
          }
        });
        this.setState({ proptypes: proptypes });
        //console.log(this.state.proptypes);

        this.setState({ loading: false });
      }
    } else {
      alert('ERROR:: ' + JSON.stringify(error.response.data));
      console.log('ERROR:: ', error.response.data);
    }
  };
  formatNumber(pre, number) {
    return (
      pre +
      ' ' +
      Math.floor(number)
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    );
  }
  componentDidMount() {
    this.fetchdevelopment();
  }

  render() {
    if (this.state.loading) {
      return (
        <div className="circProgress">
          <CircularProgress size={40} />
        </div>
      );
    }
    const development = this.state.development;
    const proptypes = this.state.proptypes;
    const cardElements = [
      {
        title: 'Development name',
        value: development.name,
        id: 'name',
        class: 'half',
      },
      {
        title: 'Address',
        value: development.address,
        id: 'address',
        class: 'full',
      },
      {
        title: 'Closing date',
        value: getFormattedDateUtil({
          dateString: development.closingDate,
        }),
        id: 'clsdate',
        class: 'half',
      },
      {
        title: 'Income threshold',
        value: this.formatNumber('€', development.income),
        id: 'income',
        class: 'half',
      },
    ];

    return (
      <div className={'developmentWrapper'}>
        <Paper className="separateHeader" elevation={4}>
          <div className="pageContainerHeader">
            <div>
              <h2 className={'head'}>Development ID: {development.id}</h2>
            </div>
            <div>
              <Button
                className="react-strap-button-primary"
                color="primary"
                size="small"
                variant="outlined"
              >
                <FaDownload /> Download (.pdf)
              </Button>
              <Button
                className="react-strap-button-primary"
                color="primary"
                size="small"
                variant="outlined"
              >
                <FaPrint /> Print
              </Button>
            </div>
          </div>
        </Paper>

        <div className="cardContainer">
          <Card elevation={4}>
            <CardTitle>Development Details</CardTitle>
            <CardBody>
              {cardElements.map((elem) => (
                <FormControl
                  key={elem.id}
                  variant="outlined"
                  className={'item-' + elem.class}
                >
                  <InputLabel htmlFor={elem.id}>{elem.title}</InputLabel>
                  <OutlinedInput
                    id={elem.id}
                    value={elem.value}
                    label={elem.title}
                    readOnly
                  />
                </FormControl>
              ))}
            </CardBody>
          </Card>

          <Card elevation={4}>
            <CardTitle>Property Types</CardTitle>
            <CardBody>
              <List className={'propertyTextList'}>
                {proptypes.map((type) => (
                  <ListItem key={type.id}>
                    <ListItemIcon>
                      <FaChevronRight />
                    </ListItemIcon>
                    <ListItemText primary={type.name} />
                  </ListItem>
                ))}
              </List>
            </CardBody>
          </Card>
        </div>

        <div className={'row propertyImageList'}>
          {proptypes.map((type) => (
            <div
              key={type.id}
              className={'col-xs-12 col-sm-6 col-md-4 col-lg-3'}
            >
              <img
                src={'/images/' + type.image}
                alt={type.name + ' image'}
                className={'propertyImage'}
              />
              <p>{type.name}</p>
            </div>
          ))}
        </div>

        <div className={'row'}>
          <div className={'col text-center'}>
            <Link className={'btn abtn'} to={'/app/developments'}>
              Back to Developments List
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default DevelopmentDetails;
