import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class linkRenderer extends Component {
  render() {
    const data = this.props.node.data;
    const linkText = '/app/applicant/' + data.id;
    return (
      <Link className="link" to={linkText}>
        {this.props.value}
      </Link>
    );
  }
}
