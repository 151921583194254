import React, { useEffect, useState } from 'react';
import FsModal from '../../../../components/baseComponents/fsModal/FsModal';
import { otherIncomeModalSchema } from '../../../../../utils/affordablePurchase/otherIncomeModalSchema';
import {
  addErrorField,
  getFieldHasError,
  removeErrorField,
} from '../../../../../utils/affordablePurchase/formStateUtils';
import ApplicantFormSubsection from '../../ApplicantFormSubsection';

const AddAdditionalIncomeModal = ({
  formState,
  modalDataId,
  isJointApplication,
  setErrorFields,
  setFlag,
  setFormState,
  setShowModal,
  rowIndex,
  tableGridRef,
}) => {
  const [documentUploaded, setDocumentUploaded] = useState(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [localErrors, setLocalErrors] = useState([]);
  const [newOtherIncome, setNewOtherIncome] = useState({});

  useEffect(() => {
    let updatedIsSaveButtonDisabled = true;
    const errorsExist = otherIncomeModalSchema(isJointApplication).some(
      (field) => {
        return getFieldHasError(field, newOtherIncome[field.id], formState);
      }
    );

    if (!errorsExist && documentUploaded) {
      updatedIsSaveButtonDisabled = false;
    }

    if (newOtherIncome.hasOwnProperty('documents')) {
      updatedIsSaveButtonDisabled = false;
      setDocumentUploaded(true);
    }

    setIsSaveDisabled(updatedIsSaveButtonDisabled);
  }, [documentUploaded, newOtherIncome]);

  useEffect(() => {
    let newOtherIncome;
    if (!rowIndex) return;
    formState.otherIncomeList.forEach((member) => {
      if (member.id === rowIndex) {
        newOtherIncome = member;
      }
    });

    if (newOtherIncome.otherIncomeType === 'Additional Employment') {
      global.showExtraAdditionalIncomeFields = true;
    } else {
      global.showExtraAdditionalIncomeFields = false;
    }

    setNewOtherIncome(newOtherIncome);
  }, [rowIndex]);

  const handleCloseModal = () => {
    setNewOtherIncome({});
    setIsSaveDisabled(true);
    setShowModal(false);
  };

  const handleUploadModal = (e) => {
    let targetField = e.target.id;
    const filelist = e.target.files;

    const prevDocumentFiles = newOtherIncome[targetField] || [];
    const updatedDocumentFiles = [...prevDocumentFiles, ...filelist];
    setNewOtherIncome({
      ...newOtherIncome,
      [targetField]: updatedDocumentFiles,
    });

    setDocumentUploaded(true);
    setLocalErrors((prevErrorFields) =>
      prevErrorFields.filter((field) => field !== targetField)
    );
  };

  const handleAddOtherIncome = (modalDataId) => {
    // const otherIncomeListId = 'otherIncomeList';

    let errors = [];
    let layout = [];

    if (modalDataId === 'otherIncomeList') {
      layout = otherIncomeModalSchema(false);
    }
    if (modalDataId === 'jointApplicant_otherIncomeList') {
      layout = otherIncomeModalSchema(true);
    }

    layout.forEach((e) => {
      let isVisible =
        typeof e.checkIsVisible == 'function' ? e.checkIsVisible() : true;
      if (isVisible && e.required) {
        if (
          typeof newOtherIncome[e.id] == 'array' &&
          newOtherIncome[e.id].length == 0
        ) {
          errors = [...errors, e.id];
        } else if (
          !newOtherIncome.hasOwnProperty(e.id) ||
          newOtherIncome[e.id] == ''
        ) {
          errors = [...errors, e.id];
        }
        setLocalErrors(errors);
      }
    });

    if (errors.length == 0) {
      const otherIncomeListId = isJointApplication
        ? 'jointApplicant_otherIncomeList'
        : 'otherIncomeList';
      const otherIncomeProofId = isJointApplication
        ? 'jointApplicant_proofOfAdditionalIncome'
        : 'proofOfAdditionalIncome';

      let updatedOtherIncome = formState[modalDataId] || [];
      const updatedProofOfOtherIncome = formState[otherIncomeProofId] || [];
      newOtherIncome['documents']?.forEach((proofOfAdditionalIncome) => {
        updatedProofOfOtherIncome.push(proofOfAdditionalIncome);
      });

      delete newOtherIncome[otherIncomeProofId];

      newOtherIncome.applicant_id = isJointApplication
        ? formState.jointApplicant_id
        : formState.applicant_id;
      newOtherIncome.jointTenant = isJointApplication;
      if (rowIndex) {
        const selectedIndex = updatedOtherIncome.findIndex(
          (e) => e.id == rowIndex
        );
        if (selectedIndex != -1)
          updatedOtherIncome[selectedIndex] = newOtherIncome;
      } else updatedOtherIncome.push(newOtherIncome);

      setFormState({
        ...formState,
        [otherIncomeListId]: updatedOtherIncome,
        [otherIncomeProofId]: updatedProofOfOtherIncome,
      });
      setErrorFields((prevErrorFields) =>
        prevErrorFields.filter((field) => field !== otherIncomeListId)
      );
      setDocumentUploaded(false);
      setFlag(true);
      setShowModal(false);
      tableGridRef?.current?.api?.setRowData(formState[otherIncomeListId]);
    }
  };

  const onOtherIncomeModalChange = (e, field) => {
    setNewOtherIncome({
      ...newOtherIncome,
      [field.id]: e.target.value,
    });

    let updatedLocalErrorFields = localErrors;
    const fieldHasError = getFieldHasError(field, e.target.value, formState);
    if (fieldHasError) {
      updatedLocalErrorFields = addErrorField(
        updatedLocalErrorFields,
        field.id
      );
    } else {
      updatedLocalErrorFields = removeErrorField(
        updatedLocalErrorFields,
        field.id
      );
    }

    setLocalErrors(updatedLocalErrorFields);
  };

  const getModalContent = () => {
    if (!modalDataId) return;
    let layout = [];

    if (modalDataId === 'otherIncomeList') {
      layout = otherIncomeModalSchema(false);
    }
    if (modalDataId === 'jointApplicant_otherIncomeList') {
      layout = otherIncomeModalSchema(true);
    }

    return (
      <ApplicantFormSubsection
        errorFields={localErrors}
        formState={formState}
        handleUpload={handleUploadModal}
        isModal={true}
        isJointApplication={isJointApplication}
        layout={layout}
        onChange={onOtherIncomeModalChange}
        overrideFormState={newOtherIncome}
        setErrorFields={setLocalErrors}
        setFormState={setFormState}
      />
    );
  };

  return (
    <FsModal
      content={getModalContent(modalDataId)}
      handleClose={handleCloseModal}
      handleSave={() => handleAddOtherIncome(modalDataId)}
      headerText="Add Additional Income"
      isSaveButtonDisabled={false}
    />
  );
};

export default AddAdditionalIncomeModal;
