import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import useAxios from '../hooks/useAxios';
import ReactDOMServer from 'react-dom/server';
import Modal from 'react-modal';
import { Button, Form, Input, Label } from 'reactstrap';
import {
  Paper,
  CircularProgress,
  Snackbar,
  IconButton,
} from '@material-ui/core';
import { AgGridReact } from 'ag-grid-react';

import {
  FaDownload,
  FaTimes,
  FaTicketAlt,
  FaExclamationTriangle,
} from 'react-icons/fa';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import linkRenderer from '../components/linkRenderer';
import StatusRenderer from '../components/baseComponents/fsStatusRenderer/StatusRenderer';
import 'ag-grid-enterprise';
import { getFormattedDateUtil } from '../../utils/utils';
import { GoFilter } from 'react-icons/go';

const customStyles = {
  content: {
    width: '500px',
    height: '550px',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};
const frameworkComponents = {
  linkRenderer: linkRenderer,
  statusRenderer: React.memo(StatusRenderer),
};

function Applications() {
  const { callAxios } = useAxios();
  const gridRef = useRef();
  const [applicationCount, setApplicationCount] = useState(0);

  const [developments, setDevelopments] = useState([]);
  const [devPropTypes, setDevPropTypes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [lotteryButtonStatus, setLotteryButtonStatus] = useState(false);
  const [paginationPageSize, setPaginationPageSize] = useState(100);
  const [rowData, setRowData] = useState([]);
  const [saveChanged, setSaveChanged] = useState(true);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedDev, setSelectedDev] = useState({});
  const [selectedProp, setSelectedProp] = useState();
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [showLotteryModal, setShowLotteryModal] = useState(false);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [showWaitModal, setShowWaitModal] = useState(false);
  const [snackMsg, setSnackMsg] = useState('');
  const [quickFilterText, setQuickFilterText] = useState('');
  const [snackOpen, setSnackOpen] = useState(false);
  const [showResetFilters, setShowResetFilters] = useState(false);
  const [snackType, setSnackType] = useState('bg-success');
  const [statusChangeButtonDisabled, setStatusChangeButtonDisabled] =
    useState(true);
  const [statuses, setStatuses] = useState([]);
  const [filterModel, setFilterModel] = useState(null);
  const [columnState, setColumnState] = useState(null);

  const fetchSortingAndFilters = useCallback(async () => {
    try {
      const response = await axios.get('/getSortingAndFilters');
      if (response.data.filters.filters) {
        const obj = JSON.parse(response.data.filters.filters);
        if (obj.sort.length > 0 || obj.filter.length > 0) {
          setShowResetFilters(true);
        }
        setFilterModel(obj.filter);
        setColumnState({
          state: obj.sort,
          defaultState: { sort: null },
        });
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  const fetchStatuses = useCallback(async () => {
    try {
      const response = await axios.post('/getStatuses', {
        type: 'Application',
      });
      if (response.data.statuses) {
        const status = [];
        response.data.statuses.forEach((st) => {
          status[st.id] = st;
        });
        setStatuses(status);
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    const getApplications = async () => {
      const response = await callAxios('get', '/application');
      setRowData(response.data.applications);
    };

    Promise.all([
      getApplications(),
      fetchSortingAndFilters(),
      fetchStatuses(),
    ]).then(() => {
      setLoading(false);
    });
  }, []);

  const affordabilityColumns = useMemo(
    () => [
      {
        field: 'status.name',
        headerName: 'Status',
        sortable: 'true',
        filter: 'agSetColumnFilter',
        width: 120,
        cellRenderer: 'statusRenderer',
      },
      {
        field: 'affordability.total_income',
        headerName: 'Total Income',
        columnGroupShow: 'open',
        enableValue: true,
      },
      {
        field: 'affordability.deposit',
        headerName: '10% Deposit',
        columnGroupShow: 'open',
        enableValue: true,
      },
      {
        field: 'affordability.total_savings',
        headerName: 'Total Savings',
        columnGroupShow: 'open',
        enableValue: true,
      },
      {
        field: 'affordability.max_equity',
        headerName: 'Max Equity',
        columnGroupShow: 'open',
        enableValue: true,
      },
      {
        field: 'affordability.local_authority_share',
        headerName: 'LA Share',
        columnGroupShow: 'open',
        enableValue: true,
      },
      {
        field: 'affordability.price',
        headerName: 'Price of House',
        columnGroupShow: 'open',
        enableValue: true,
      },
      {
        field: 'affordability.affordable_purchase_price',
        headerName: 'Affordable Purchase Price',
        columnGroupShow: 'open',
        enableValue: true,
      },
      {
        field: 'affordability.applicant_shortfall',
        headerName: 'Applicant Shortfall',
        columnGroupShow: 'open',
        enableValue: true,
      },
    ],
    []
  );

  const columnDefs = useMemo(
    () => [
      {
        checkboxSelection: true,
        field: 'applicant.application_id',
        filter: false,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        headerName: 'Ref',
        menuTabs: [],
        sortable: 'true',
        width: 40,
      },
      {
        field: 'name',
        headerName: 'Name',
        sortable: 'true',
        filter: 'agTextColumnFilter',
        cellRenderer: 'linkRenderer',
        valueGetter: (params) => {
          return (
            params.data.applicant?.firstname +
            ' ' +
            params.data.applicant?.surname
          );
        },
      },
      {
        field: 'applicant.email',
        headerName: 'Email',
        flex: '2',
        sortable: 'true',
        filter: 'agTextColumnFilter',
      },
      {
        field: 'applicant.phone',
        headerName: 'Phone number',
        hide: true,
        sortable: 'true',
        filter: 'agTextColumnFilter',
      },
      {
        field: 'developmentName',
        headerName: 'Development',
        flex: '1',
        sortable: 'true',
        filter: 'agSetColumnFilter',
      },
      {
        field: 'propertyName',
        headerName: 'Property type',
        sortable: 'true',
        filter: 'agSetColumnFilter',
      },
      // {
      //   field: 'net_income',
      //   headerName: 'Income',
      //   sortable: 'true',
      //   filter: 'agNumberColumnFilter',
      //   valueFormatter: currencyFormatter,
      // },
      {
        field: 'createdAt',
        headerName: 'Registration Date and Time',
        sortable: 'true',
        type: 'date',
        width: 400,
        filter: 'agDateColumnFilter',
        valueFormatter: (params) => {
          return getFormattedDateUtil({
            dateString: params.data.createdAt,
            includeTime: true,
          });
        },
      },
      // {
      //   field: 'lottery_date',
      //   headerName: 'Lottery date',
      //   sortable: 'true',
      //   width: 100,
      //   filter: 'agDateColumnFilter',
      //   valueFormatter: dateFormatter,
      // },
      // {
      //   field: 'lottery_result',
      //   headerName: 'Lottery result',
      //   sortable: 'true',
      //   filter: 'agNumberColumnFilter',
      //   width: 50,
      // },

      {
        headerName: 'Affordability',
        marryChildren: true,
        children: affordabilityColumns,
      },
    ],
    [affordabilityColumns]
  );

  const checkDisplayedRows = () => {
    const displayedRows = gridRef.current?.api?.getDisplayedRowCount();
    if (displayedRows == 0) {
      gridRef.current?.api?.showNoRowsOverlay();
    } else {
      gridRef.current?.api?.hideOverlay();
    }
  };

  const onGridReady = async (params) => {
    gridRef.current = params;
    params?.api?.setFilterModel(filterModel);
    params?.columnApi?.applyColumnState(columnState);
    params?.api?.addEventListener('filterChanged', checkDisplayedRows);
    const updateData = (data) => {
      setRowData(data);
    };
  };

  const onBtnExport = useCallback(() => {
    gridRef.current?.api.exportDataAsExcel();
  }, []);

  const closeSnack = () => {
    setSnackOpen(false);
    setSnackMsg('');
    setSnackType();
  };

  const selectionChange = () => {
    setSelectedRows(gridRef.current.api.getSelectedRows());
  };

  const getSort = () => {
    var colState = gridRef.current.columnApi.getColumnState();
    var sortModel = colState
      .filter(function (s) {
        return s.sort != null;
      })
      .map(function (s) {
        return { colId: s.colId, sort: s.sort, sortIndex: s.sortIndex };
      });
    return sortModel;
  };

  const saveFilterSortStore = async () => {
    const filters = {
      filters: {
        sort: getSort(),
        filter: gridRef.current.api.getFilterModel(),
      },
    };
    await axios
      .post('/storeSortingAndFilters', filters)
      .then((res) => {})
      .catch((error) => {
        console.log('ERROR:: ', error);
      });

    if (
      Object.keys(filters.filters.filter).length > 0 ||
      Object.keys(filters.filters.sort).length > 0
    ) {
      setShowResetFilters(true);
    } else {
      setShowResetFilters(false);
    }
  };

  const clearFilters = () => {
    gridRef.current.api.setFilterModel(null);
    gridRef.current.columnApi.applyColumnState({
      defaultState: { sort: null },
    });
    saveFilterSortStore();
    setSaveChanged(true);
  };

  const onChangeFilters = () => {
    saveFilterSortStore();
  };

  const openStatusModal = () => {
    setSelectedRows(gridRef.current.api.getSelectedRows());

    if (selectedRows.length < 1) {
      setSnackMsg('Please select applicants to change the status');
      setSnackType('bg-danger');
      setSnackOpen(true);
      return false;
    }
    setStatusChangeButtonDisabled(true);
    setShowStatusModal(true);
  };

  const handleStatusChange = (value) => {
    setSelectedStatus(statuses[value]);
    if (value) {
      setStatusChangeButtonDisabled(false);
    } else {
      setStatusChangeButtonDisabled(true);
    }
  };

  const changeStatusOnSelected = async () => {
    const urlData = {
      applications: selectedRows,
      status: selectedStatus,
    };

    await axios
      .post('/setMassStatus', urlData)
      .then(() => {
        setShowStatusModal(false);
        selectedRows.forEach((element) => {
          element.status = selectedStatus;
          element.status_id = selectedStatus.id;
        });
        gridRef.current.api.deselectAll();
        setSelectAll(false);
        gridRef.current.api.redrawRows();
      })
      .catch((response) => {
        console.log('ERROR', response);
      });
    setSelectedStatus({});
  };

  const selectAllRows = () => {
    if (selectAll) gridRef.current.api.selectAll();
  };
  const deselectAllRows = () => {
    if (gridRef.current) gridRef.current.api.deselectAll();
  };
  useEffect(() => {
    if (selectAll) {
      selectAllRows();
    } else {
      deselectAllRows();
    }
  }, [selectAll]);

  const getPropTypes = async (e) => {
    setDevPropTypes([]);
    setSelectedDev({});
    const dev = e.target.value;
    await axios
      .post('/getpropTypes', { development: dev })
      .then((res) => {
        setDevPropTypes(res.data.types);
        setSelectedDev(res.data.development);
      })
      .catch((error) => {
        this.setState({ showWaitModal: false });
        this.state.snackMsg = 'Something went wrong';
        this.state.snackType = 'bg-danger';
        this.handleOpenSnack();
        console.log('ERROR:: ', error);
      });
  };
  const getAppCount = async (e) => {
    setSelectedProp(e.target.value);
    const prop = {
      development: selectedDev,
      propType: e.target.value,
    };

    await axios
      .post('/getAppsCount', prop)
      .then((res) => {
        setApplicationCount(res.data.count);
        setCountRan(res.data.count_ran);
        setSelectedProp(res.data.property_type);
        if (
          res.data.count_ran === 0 &&
          dateInPast(new Date(selectedDev.closingDate), new Date()) &&
          res.data.count !== 0
        ) {
          setLotteryButtonStatus(true);
        } else {
          setLotteryButtonStatus(false);
        }
      })
      .catch((error) => {
        this.setState({ showWaitModal: false });
        this.state.snackMsg = 'Something went wrong';
        this.state.snackType = 'bg-danger';
        this.handleOpenSnack();
        console.log('ERROR:: ', error);
      });
  };
  const dateInPast = (firstDate, secondDate) => {
    return firstDate.setHours(0, 0, 0, 0) <= secondDate.setHours(0, 0, 0, 0);
  };

  const runLottery = async () => {
    const data = {
      development: selectedDev.id,
      propType: selectedProp.id,
    };
    setShowLotteryModal(false);
    setLotteryButtonStatus(false);
    setShowWaitModal(true);
    await axios
      .post('/lottery', data)
      .then((res) => {
        const filterModel = {
          development: {
            type: 'set',
            values: [selectedDev.name],
          },
          property: {
            type: 'set',
            values: [selectedProp.name],
          },
        };
        const sortModel = [{ colId: 'lottery_result', sort: 'asc' }];
        gridRef.current.api.setFilterModel(filterModel);
        gridRef.current.columnApi.applyColumnState({
          state: sortModel,
          defaultState: { sort: null },
        });

        setShowWaitModal(false);
        setSnackMsg(res.data.message);
        setSnackType('bg-success');
        setSnackOpen(true);
      })
      .catch((error) => {
        setShowLotteryModal(false);
        setSnackMsg('Something went wrong');
        setSnackType('bg-danger');
        setSnackOpen(true);
        console.log('ERROR:: ', error);
      });
  };

  if (loading) {
    return (
      <div className="circProgress">
        <CircularProgress size={40} />
      </div>
    );
  }

  return (
    <>
      <Paper className={'paper'} elevation={3}>
        <div className={'pageContainerHeader'}>
          <div>
            <h2 className="head">Applications</h2>
          </div>
          <div className="example-header">
            <input
              type="text"
              id="filter-text-box"
              style={{ width: 300, height: 'auto' }}
              placeholder="Search..."
              onInput={({ target }) => {
                setQuickFilterText(target?.value);
              }}
            />
          </div>
          <div>
            <Button
              className="react-strap-button-primary"
              variant="contained"
              color="primary"
              title="Change the Status of the selected Applicants"
              onClick={() => {
                openStatusModal();
              }}
            >
              <FaTicketAlt /> Change Status
            </Button>

            <Button
              className="react-strap-button-primary"
              variant="contained"
              color="primary"
              onClick={() => onBtnExport()}
            >
              <FaDownload /> CSV export
            </Button>
            {showResetFilters && (
              <Button
                className="button-red"
                variant="contained"
                color="primary"
                onClick={clearFilters}
              >
                Reset Filters
              </Button>
            )}
          </div>
        </div>
        <div
          className="ag-theme-material"
          style={{ height: '82vh', width: '100%' }}
        >
          <AgGridReact
            ref={gridRef}
            quickFilterText={quickFilterText}
            defaultColDef={{
              flex: 1,
              minWidth: 200,
              filter: true,
              suppressNavigable: true,
              editable: false,
              cellClass: 'no-border',
            }}
            rowSelection="multiple"
            pagination={true}
            columnDefs={columnDefs}
            onGridReady={onGridReady}
            rowData={rowData}
            paginationPageSize={paginationPageSize}
            frameworkComponents={frameworkComponents}
            onSelectionChanged={selectionChange}
            onFilterChanged={onChangeFilters}
            onSortChanged={onChangeFilters}
            onFirstDataRendered={checkDisplayedRows}
            overlayNoRowsTemplate={`<h6 style="color:#e14c4c; font-weight:bold;">No rows to display. Please check the selected filters ${ReactDOMServer.renderToString(
              <GoFilter style={{ fontSize: 18, color: '#e14c4c' }} />
            )} or reset them.</h6>`}
          ></AgGridReact>
        </div>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={snackOpen}
          autoHideDuration={2000}
          onClose={closeSnack}
          message={snackMsg}
          className={snackType}
          action={
            <React.Fragment>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={closeSnack}
              >
                <FaTimes />
              </IconButton>
            </React.Fragment>
          }
        />
      </Paper>

      <Modal
        style={customStyles}
        isOpen={showStatusModal}
        onRequestClose={() => {
          setShowStatusModal(false);
          setLoading(false);
        }}
      >
        <div id={'smallModalContainer'}>
          <div>
            <h2 className="head">Change Status</h2>
          </div>
          <div>
            You have selected {selectedRows.length} Applications. Please choose
            a status below to update all selected rows.
          </div>
          <div>&nbsp;</div>

          <Label for="appStatus">Select Status</Label>
          <Input
            type="select"
            name="appStatus"
            id="appStatus"
            defaultValue={null}
            onChange={(e) => {
              handleStatusChange(e.target.value);
            }}
          >
            <option key={0} value="">
              -- select an option --
            </option>
            {statuses.map((status) => (
              <option key={status.id} value={status.id}>
                {status.name}
              </option>
            ))}
          </Input>
          <div className={'infoBar'}>
            {selectedStatus && selectedStatus.autosend && (
              <div>
                <p className="redIcon">
                  <FaExclamationTriangle />
                </p>
                <p>
                  The status you have chosen is linked to the "
                  {selectedStatus.emailTemplate}" email template and will
                  dispatch the email automatically when you change the status.
                </p>
              </div>
            )}
          </div>
          <div className={'infoBar'}>
            <Button
              className="react-strap-button-primary"
              variant="contained"
              color="primary"
              disabled={statusChangeButtonDisabled}
              onClick={() => {
                changeStatusOnSelected();
              }}
            >
              Change Status
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={showLotteryModal}
        style={customStyles}
        onRequestClose={() => {
          setShowLotteryModal(false);
          setSelectedDev(null);
          setSelectedProp(null);
        }}
      >
        <div id={'lotteryFormContainer'}>
          <Form>
            <Label for="devSelectLabel">Select Development</Label>
            <Input
              type="select"
              name="devSelect"
              id="devSelect"
              defaultValue="Default"
              onChange={(e) => {
                getPropTypes(e);
              }}
            >
              <option key={0} disabled value="Default">
                {' '}
                -- select an option --{' '}
              </option>
              {developments.map((dev) => (
                <option key={dev.id} value={dev.id}>
                  {dev.name}
                </option>
              ))}
            </Input>

            <Label id="propSelectLabel">Select Property Type</Label>
            <Input
              type="select"
              name="propSelect"
              id="propSelect"
              defaultValue="Default"
              onChange={(e) => {
                getAppCount(e);
              }}
            >
              <option key={0} disabled value="Default">
                {' '}
                -- select an option --{' '}
              </option>
              {devPropTypes.map((prop) => (
                <option key={prop.id} value={prop.id}>
                  {prop.name}
                </option>
              ))}
            </Input>
            <div className={'infoBar'}>
              {selectedProp !== null && applicationCount > 0 && (
                <p>
                  There are {applicationCount} applications for this development
                  and property type in the "Applied" status.
                  <br />
                  Only these applications will be included in the lottery
                </p>
              )}
              {selectedProp !== null && applicationCount === 0 && (
                <p>
                  There are no applications for this development and property
                  type.
                </p>
              )}
            </div>
            <div className={'infoBar'}>
              {selectedDev !== null && (
                <p>
                  The closing date for this development is{' '}
                  {selectedDev.closingDate}.
                </p>
              )}
              <p>
                You can only run the lottery ONCE and you can only run the
                lottery AFTER the closing date.
              </p>
              <Button
                className="react-strap-button-primary"
                color="primary"
                onClick={() => {
                  runLottery();
                }}
                disabled={!lotteryButtonStatus}
                variant="contained"
              >
                Run Lottery
              </Button>
            </div>
          </Form>
        </div>
      </Modal>
      <Modal isOpen={showWaitModal} style={customStyles}>
        <div id={'modalContainer'}>
          <div className={'pageContainerHeader'}>
            <div>
              <h2 className="head">Please Wait</h2>
            </div>
          </div>
          <div>
            <p className={'text-center'}>
              Please wait while the pdf file is generated. <br />
            </p>
            <p className={'text-center'}>
              Lottery results will be displayed on the next page. <br />
            </p>
            <CircularProgress
              color={'primary'}
              size={'10rem'}
              className={'waitForPdf'}
            />
          </div>
        </div>
      </Modal>
    </>
  );
}
export default React.memo(Applications);
