import React from 'react';
import { documentRequirements } from '../../../../../utils/affordablePurchase/documentRequirements';
import { FaExclamationCircle } from 'react-icons/fa';

const documentRequirementsToShow = [
  'Photographic Identification',
  'Proof of Present Address dated within the last 6 months',
  'Proof of Residency in the Republic of Ireland',
  'Proof of Right to Reside in Ireland for non-Irish/EU nationals',
  'Proof of income',
  'Proof of being or having been resident in the administrative area of Meath County Council for a minimum period of 5 years for applicants to qualify under the 30% Residency Rule.',
  'Proof of PPSN/Tax Registration Number',
  'Evidence of savings/deposit',
  'If not a First Time Buyer',
  'Confirmation of eligibility for Help to Buy Scheme',
  'Confirmation of Mortgage Approval In Principle',
  'Employer Reference',
  'Landlord Reference',
  'Bank Statements',
];

const documentRequirementsFiltered = documentRequirements.filter(
  (documentRequirement) => {
    return documentRequirementsToShow.includes(documentRequirement.requirement);
  }
);

const DocumentRequirements = () => {
  return (
    <div>
      <h5 className="affordable-purchase-form-section-header">
        Document Requirements
      </h5>
      <div className="affordable-purchase-form-text-area">
        <ul className="document-requirements-list">
          {documentRequirementsFiltered.map((reqObj, index) => {
            return (
              <div
                className="document-requirements-section"
                key={'document-requirement-' + index}
              >
                <li key={index} className="document-requirements-li">
                  <span className="requirement-span">{reqObj.requirement}</span>
                </li>
                <div className="tooltip-wrapper">
                  <FaExclamationCircle />
                  <div className="tooltip-hover-list">
                    <ul className="tooltip-list">
                      {reqObj.tooltip.map((li, index) => {
                        return <li key={'document-tooltip-' + index}>{li}</li>;
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default DocumentRequirements;
