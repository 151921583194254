import React, { useRef, useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import Modal from 'react-modal';
import TypeSelect from '../../TypeSelect';
import { DropzoneArea } from 'material-ui-dropzone';
import CssTextField from '../../baseComponents/fsTextfield/textField';
import FsButton from '../../baseComponents/fsButton/FsButton';

const PropertyUpdateModal = ({
  activeProperty,
  setActiveProperty,
  handleSave,
  setShowModal,
  isEditing,
  editProperty,
}) => {
  const [saveDisabled, setSaveDisabled] = useState(true);
  const [errorFields, setErrorFields] = useState([]);

  const formLayout = [
    {
      placeholder: 'Name',
      id: 'name',
      type: 'text',
      required: true,
      errorMessage: 'Not a valid name',
    },
    {
      placeholder: 'Bedrooms',
      id: 'bedrooms',
      type: 'numeric',
      required: true,
    },
    {
      placeholder: 'Price',
      id: 'price',
      type: 'numeric',
    },
    {
      placeholder: 'affordable_purchase_price',
      id: 'affordablePurchasePrice',
      type: 'numeric',
    },
    {
      id: 'price_per_month',
      type: 'numeric',
    },
    {
      id: 'type_id',
      type: 'select',
      required: true,
    },
  ];

  const onChange = (e, fieldId) => {
    let newInput = e.target.value || '';
    setActiveProperty({
      ...activeProperty,
      [e.target.id]: newInput,
    });

    let updatedErrorFields = errorFields;
    if (
      newInput.length === 0 &&
      formLayout.find((field) => field.id === fieldId)?.required
    ) {
      if (updatedErrorFields.indexOf(e.target.id) === -1) {
        updatedErrorFields.push(e.target.id);
      }
      setErrorFields(updatedErrorFields);
    } else {
      updatedErrorFields = updatedErrorFields.filter(
        (field) => field !== e.target.id
      );
      setErrorFields(updatedErrorFields);
    }
  };

  const checkRequiredFields = () => {
    return formLayout.every((field) => {
      if (!field.required) return true;

      if (typeof activeProperty[field.id] === 'number') return true;
      if (
        typeof activeProperty[field.id] === 'string' &&
        activeProperty[field.id]?.length
      )
        return true;

      return !!activeProperty[field.id];
    });
  };

  const handleTypeSelect = (e, v) => {
    setActiveProperty({
      ...activeProperty,
      type_id: v,
      type_name: e.name,
    });
  };

  const handleImageUpload = (e) => {
    setActiveProperty({ ...activeProperty, files: e });
    //TODO
    // This does not pass in the file correctly currently. Need to fix at some point
    // const updatedFiles = files
    // updatedFiles.push(file)
    // setFiles(updatedFiles);
    // setFiles(files);
  };

  const handleSaveClick = () => {
    if (isEditing) {
      editProperty(activeProperty);
    } else {
      handleSave(activeProperty);
    }
  };

  const handleClose = () => {
    setShowModal(false);
  };

  useEffect(() => {
    setSaveDisabled(!checkRequiredFields());
  }, [activeProperty]);

  return (
    <Modal isOpen={true}>
      <div>
        <form onSubmit={handleSave} encType="multipart/form-data">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <h2 className="header text-center">
                {activeProperty.id ? 'Edit Property' : 'Create New Property'}
              </h2>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6}>
              <div className="full-height full-width">
                <DropzoneArea
                  clearOnUnmount={true}
                  dropzoneText="Drop Property images here"
                  fileObjects={activeProperty.id ? activeProperty.images : []}
                  filesLimit={10}
                  onChange={(e) => handleImageUpload(e)}
                  showFileNamesInPreview={true}
                />
              </div>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <CssTextField
                    id="name"
                    error={errorFields.includes('name')}
                    fullWidth
                    label="Name"
                    onChange={(e) => {
                      onChange(e, 'name');
                    }}
                    placeholder="Name"
                    required={true}
                    size="medium"
                    value={activeProperty.name}
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <CssTextField
                    id="bedrooms"
                    error={errorFields.includes('bedrooms')}
                    fullWidth
                    label="Bedrooms"
                    inputProps={{ type: 'number' }}
                    onChange={(e) => {
                      onChange(e, 'bedrooms');
                    }}
                    placeholder="Bedrooms"
                    required={true}
                    size="medium"
                    value={activeProperty.bedrooms}
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <CssTextField
                    id="price"
                    error={errorFields.includes('price')}
                    fullWidth
                    label="Price of House"
                    inputProps={{ type: 'number' }}
                    onChange={(e) => {
                      onChange(e, 'price');
                    }}
                    placeholder="Price of House"
                    required={true}
                    size="medium"
                    value={activeProperty.price}
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <CssTextField
                    id="affordable_purchase_price"
                    error={errorFields.includes('affordable_purchase_price')}
                    fullWidth
                    label="Affordable Purchase Price"
                    inputProps={{ type: 'number' }}
                    onChange={(e) => {
                      onChange(e, 'affordable_purchase_price');
                    }}
                    placeholder="Affordable Purchase Price"
                    required={true}
                    size="medium"
                    value={activeProperty.affordable_purchase_price}
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TypeSelect
                    id="type-name"
                    func={handleTypeSelect}
                    selected={activeProperty.type_id}
                    type={'Properties'}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <div className={'mailsend-buttons'}>
                <FsButton buttonText="Cancel" onClick={handleClose} />

                <FsButton
                  buttonText="Save"
                  disabled={saveDisabled}
                  variant="contained"
                  onClick={handleSaveClick}
                />
              </div>
            </Grid>
          </Grid>
        </form>
      </div>
    </Modal>
  );
};

export default PropertyUpdateModal;
