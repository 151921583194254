import React, { useState, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import StepConnector from '@material-ui/core/StepConnector';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { Grid } from '@material-ui/core';
import clsx from 'clsx';
import { Button } from 'reactstrap';
import DevelopmentInfoWizardStep from './DevelopmentInfoWizardStep';
import PropertiesStep from './PropertiesStep';
import CssTextField from '../../components/baseComponents/fsTextfield/textField';
import {
  dateStringToDateUtil,
  getBooleanValueDisplayUtil,
} from '../../../utils/utils';
import FsModal from '../../components/baseComponents/fsModal/FsModal';
import './developmentWizard.scss';

const DevelopmentWizardStep0Layout = [
  {
    placeholder: 'Name',
    id: 'name',
    type: 'text',
    required: true,
    errorMessage: 'Not a valid name',
  },
  {
    placeholder: 'Address',
    id: 'address',
    type: 'text',
    required: true,
    errorMessage: 'Not a valid address',
  },
  {
    id: 'type_id',
    type: 'select',
    required: true,
  },
  {
    id: 'income',
    type: 'numeric',
    required: true,
  },
  {
    id: 'openingDate',
    type: 'date',
    required: true,
  },
  {
    id: 'closingDate',
    type: 'date',
    required: true,
  },
];

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    '& $line': {
      backgroundColor: 'var(--primaryLocal)',
    },
  },
  completed: {
    '& $line': {
      backgroundColor: 'var(--primaryLocal)',
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    alignItems: 'center',
    backgroundColor: '#ccc',
    borderRadius: '50%',
    color: '#fff',
    display: 'flex',
    fontSize: 'large',
    height: 30,
    'justify-content': 'center',
    width: 30,
    zIndex: 1,
  },
  active: {
    backgroundColor: 'var(--primaryLocal)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  completed: {
    backgroundColor: 'var(--primaryLocal)',
  },
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: '1',
    2: '2',
    3: '3',
    4: '4',
    5: '5',
  };
  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

function DevelopmentWizard({
  activeDevelopment,
  handleClose,
  setDevelopments,
}) {
  // TODO
  // Readd images steps when using again
  // const steps = ['Development Info', 'Images', 'Properties', 'Summary'];
  const steps = ['Development Info', 'Properties', 'Summary'];
  const [activeStep, setActiveStep] = useState(0);
  const [
    isPublishedDevelopmentWarningModalOpen,
    setIsPublishedDevelopmentWarningModalOpen,
  ] = useState(false);
  const [errorFields, setErrorFields] = useState([]);
  const [files, setFiles] = useState([]);
  const [FormState, setFormState] = useState(activeDevelopment);
  const [properties, setProperties] = useState(
    activeDevelopment.properties || []
  );
  const [nextButtonDisabled, setNextButtonDisabled] = useState(true);
  const [skipped, setSkipped] = useState(new Set());
  const [initialDevelopmentStatus, setInitialDevelopmentStatus] =
    useState('Unpublished');

  useEffect(() => {
    setInitialDevelopmentStatus(activeDevelopment.status || 'Unpublished');
  }, []);

  const isStepOptional = (step) => {
    // TODO
    // Image step when re-added can be optional
    // return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    if (activeStep === steps.length - 1) {
      if (
        initialDevelopmentStatus === 'Unpublished' &&
        FormState.status === 'Unpublished'
      ) {
        onSubmit(FormState);
      } else {
        setIsPublishedDevelopmentWarningModalOpen(true);
      }
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleAddImages = (files) => {
    setFiles(files);
  };

  const onSubmit = async (FormState) => {
    FormState.properties = properties;
    FormState.images = files;

    if (FormState.id) {
      await axios
        .patch(`/developments/${FormState.id}`, FormState)
        .then((res) => {
          console.log('res', res);
        });
    } else {
      await axios.post('/developments', FormState).then((res) => {
        console.log('res', res);
      });
    }

    await axios.get('/developments').then((res) => {
      setDevelopments(res.data.developments);
    });
    handleClose();
  };

  const getDevelopmentFieldLabel = (key) => {
    if (key === 'auto_status') return 'Auto Status Change';
    if (key === 'pre_reg') return 'Only registered users may apply';
    if (key === 'closingDate') return 'Closing date';
    if (key === 'openingDate') return 'Opening date';
    return key.charAt(0).toUpperCase() + key.substr(1).toLowerCase();
  };

  const publishedDevelopmentWarningModalContent = () => {
    let updateWarningText =
      'This development is currently published, are you sure you want to update?';
    if (
      initialDevelopmentStatus === 'Unpublished' &&
      FormState.status === 'Published'
    ) {
      updateWarningText =
        'This development will become published and available to applicants with this change. Are you sure you want to update?';
    } else if (
      initialDevelopmentStatus === 'Published' &&
      FormState.status === 'Unpublished'
    ) {
      updateWarningText =
        'This development will become unpublished and unavailable to applicants with this change. Are you sure you want to update?';
    }

    return <div className="modal-text-content">{updateWarningText}</div>;
  };

  return (
    <>
      <form
        onSubmit={onSubmit}
        style={{ height: '100%', display: 'flex', flexDirection: 'column' }}
      >
        <div>
          <Stepper
            alternativeLabel
            activeStep={activeStep}
            connector={<ColorlibConnector />}
          >
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel StepIconComponent={ColorlibStepIcon}>
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </div>
        {activeStep == 0 && (
          <DevelopmentInfoWizardStep
            errorFields={errorFields}
            FormState={FormState}
            formLayout={DevelopmentWizardStep0Layout}
            setErrorFields={setErrorFields}
            setFormState={setFormState}
            setNextButtonDisabled={setNextButtonDisabled}
          />
        )}
        {/* TODO
            We are temporarily removing this images adding step until further BA work is done
            */}
        {/* {activeStep == 1 && (
              <div className="developments-wizard-form-container">
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <div className="full-height full-width">
                      <DropzoneArea
                        fileObjects={files}
                        clearOnUnmount={true}
                        filesLimit={10}
                        onChange={(file) => handleAddImages(file)}
                        dropzoneText="Drag and Drop or Click to Select"
                      />
                    </div>
                  </Grid>
                </Grid>
              </div>
            )} */}
        {activeStep == 1 && (
          <PropertiesStep
            properties={properties}
            setProperties={setProperties}
          />
        )}
        {activeStep == 2 && (
          <div className="developments-wizard-form-container">
            <Grid container spacing={2}>
              {/* TODO
                  Uncomment images uploaded when we introduce uploading images for developments */}
              {/* <Grid item xs={6} sm={6} md={6} lg={6}>
                    <CssTextField
                      fullWidth
                      label="Images Uploaded"
                      placeholder="Images Uploaded"
                      readOnly={true}
                      size="small"
                      value={files.length}
                      variant="outlined"
                    />
                  </Grid> */}
              {Object.keys(FormState).map((key) => {
                let valueDisplay = FormState[key];
                const dateFields = ['openingDate', 'closingDate'];
                if (dateFields.includes(key)) {
                  valueDisplay = dateStringToDateUtil(
                    FormState[key]
                  ).toLocaleString();
                }
                if (
                  key !== 'created_at' &&
                  key !== 'updated_at' &&
                  key !== 'properties' &&
                  key !== 'movingDate' &&
                  key.slice(-2) !== 'id'
                ) {
                  if (valueDisplay === true || valueDisplay === false) {
                    valueDisplay = getBooleanValueDisplayUtil(valueDisplay);
                  }
                  const label = getDevelopmentFieldLabel(key);
                  return (
                    <Grid item xs={6} sm={6} md={6} lg={6} key={key}>
                      <CssTextField
                        fullWidth
                        label={label}
                        placeholder={label}
                        readOnly={true}
                        size="small"
                        value={valueDisplay}
                        variant="outlined"
                      />
                    </Grid>
                  );
                }
              })}
              <Grid item xs={6} sm={6} md={6} lg={6}>
                <CssTextField
                  fullWidth
                  label="Properties Selected"
                  placeholder="Properties Selected"
                  readOnly={true}
                  size="small"
                  value={properties.length}
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </div>
        )}
        {activeStep == 3 && (
          <div className="developments-wizard-form-container"></div>
        )}
        <div className="fs-modal-action-buttons">
          <Button type="button" className="btn-danger" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            className="btn-primary react-strap-button-primary"
            disabled={activeStep === 0}
            onClick={handleBack}
            type="button"
          >
            Back
          </Button>
          {isStepOptional(activeStep) && (
            <Button
              className="btn-primary react-strap-button-primary"
              onClick={handleSkip}
              type="button"
            >
              Skip
            </Button>
          )}

          <Button
            className="btn-primary react-strap-button-primary"
            disabled={nextButtonDisabled}
            onClick={handleNext}
            type="button"
          >
            {activeStep === steps.length - 1 ? 'Save' : 'Next'}
          </Button>
        </div>
      </form>
      {isPublishedDevelopmentWarningModalOpen && (
        <FsModal
          content={publishedDevelopmentWarningModalContent()}
          handleClose={() => setIsPublishedDevelopmentWarningModalOpen(false)}
          handleSave={() => onSubmit(FormState)}
          headerText="Are you sure you want to save this development?"
          rightButtonText="Save"
          isSaveButtonDisabled={false}
        />
      )}
    </>
  );
}

export default DevelopmentWizard;
