import {
  DATE_FORMAT_REGEX,
  AGE_REGEX,
  PPSN_REGEX,
} from '../../utils//regexUtils';

const getHouseholdMembersModalSchema = () => {
  let schema = [
    {
      errorMessage: 'Field required',
      id: 'householdMemberFirstName',
      placeholder: 'First name',
      required: true,
      type: 'text',
    },
    {
      errorMessage: 'Field required',
      id: 'householdMemberLastName',
      placeholder: 'Last name',
      required: true,
      type: 'text',
    },
    {
      errorMessage: 'Field must be a valid date',
      id: 'householdMemberDateOfBirth',
      placeholder: 'Date of Birth *',
      regex: DATE_FORMAT_REGEX,
      required: true,
      type: 'date',
    },
    {
      errorMessage: 'Field required',
      id: 'householdMemberAge',
      placeholder: 'Age',
      regex: AGE_REGEX,
      required: true,
      type: 'number',
    },
    {
      errorMessage: 'Field required',
      id: 'householdMemberPPSN',
      placeholder: 'PPSN',
      regex: PPSN_REGEX,
      required: true,
      type: 'text',
    },
    {
      errorMessage: 'Field required',
      id: 'householdMemberRelationshipToApplicant',
      label: 'Relationship to Applicant *',
      options: [
        { value: 'Son' },
        { value: 'Daughter' },
        { value: 'Mother' },
        { value: 'Father' },
        { value: 'Sibling' },
        { value: 'Other' },
      ],
      required: true,
      type: 'select',
    },
  ];

  return schema;
};

export const householdMembersModalSchema = (formState) => {
  return getHouseholdMembersModalSchema(formState);
};
