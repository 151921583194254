import React, { useState } from 'react';
import { Grid, Card } from '@material-ui/core';
import { Button } from 'reactstrap';
import CssTextField from '../components/baseComponents/fsTextfield/textField';
function OAuthSettings() {
  return (
    <Card className="text-center h100 padding20" elevation={4}>
      <Grid
        container
        justifyContent="flex-start"
        spacing={3}
        direction="column"
        className={'emailSettingsContainer'}
      >
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <img className="officeLogoImg" src="../../office365_logo.png" />
        </Grid>
        <Grid>
          <h3>Office 365 Settings</h3>
        </Grid>

        <Grid item xs={12} sm={6} md={12} lg={12}>
          <CssTextField
            fullWidth
            variant="outlined"
            label="Office 365 Client Id"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <CssTextField
            fullWidth
            variant="outlined"
            label="Office 365 Client Secret"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <hr className={'simple-line'} />
          <div>
            <Button
              className="react-strap-button-primary"
              color="primary"
              onClick={() => this.emailSubmitDialog()}
              variant="contained"
            >
              Disable
            </Button>
            <Button
              className="react-strap-button-primary"
              color="primary"
              onClick={() => this.setState({ showSendModal: true })}
              variant="contained"
            >
              Enable
            </Button>
          </div>
        </Grid>
      </Grid>
    </Card>
  );
}
export default OAuthSettings;
