import React from 'react';
import Grid from '@material-ui/core/Grid';
import CssTextField from '../../../components/baseComponents/fsTextfield/textField';

const SelfEmployedFormExtras = ({ applicantData }) => {
  return (
    <>
      <Grid item xs={12} sm={12} md={3} lg={3}>
        <CssTextField
          fullWidth
          id="outlined-read-only-input"
          label="Occupation"
          defaultValue={applicantData?.employment?.occupation}
          InputProps={{
            readOnly: true,
          }}
          InputLabelProps={{ shrink: !!applicantData?.employment?.occupation }}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={3} lg={3}>
        <CssTextField
          fullWidth
          id="outlined-read-only-input"
          label="Annual Gross Income"
          defaultValue={applicantData?.employment?.grossIncome}
          InputProps={{
            readOnly: true,
          }}
          InputLabelProps={{ shrink: !!applicantData?.employment?.grossIncome }}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={3} lg={3}>
        <CssTextField
          fullWidth
          id="outlined-read-only-input"
          label="Type Of Business"
          defaultValue={applicantData?.employment?.typeOfBusiness}
          InputProps={{
            readOnly: true,
          }}
          InputLabelProps={{
            shrink: !!applicantData?.employment?.typeOfBusiness,
          }}
        />
      </Grid>
    </>
  );
};

export default SelfEmployedFormExtras;
