import React, { useEffect, useState } from 'react';
import { CircularProgress, Grid } from '@material-ui/core';
import 'react-datepicker/dist/react-datepicker.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import axios from 'axios';
import DevelopmentsView from '../views/developments/DevelopmentsView';
import useAxios from '../hooks/useAxios';
import { getFormattedDateUtil } from '../../utils/utils';

import 'ag-grid-enterprise';

function Developments() {
  const [loading, setLoading] = useState(true);
  const [developments, setDevelopments] = useState({});
  const { callAxios } = useAxios();

  const columnDefs = [
    {
      field: 'name',
      headerName: 'Name',
    },
    {
      field: 'address',
      headerName: 'Address',
    },
    {
      field: 'openingDate',
      headerName: 'Applications Opening Date',
      filter: 'agDateColumnFilter',
      valueFormatter: (params) => {
        return getFormattedDateUtil({
          dateString: params.value,
          includeTime: true,
        });
      },
    },
    {
      field: 'closingDate',
      headerName: 'Applications Closing Date',
      filter: 'agDateColumnFilter',
      valueFormatter: (params) => {
        return getFormattedDateUtil({
          dateString: params.value,
          includeTime: true,
        });
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      cellRenderer: 'statusRenderer',
    },
    {
      field: 'empty',
      headerName: 'Action',
      cellRenderer: 'linkRenderer',
    },
  ];

  useEffect(async () => {
    setLoading(true);
    await axios.get('/developments').then((res) => {
      setDevelopments(res.data.developments);
    });
    setLoading(false);
  }, []);

  if (loading) {
    return (
      <div className="circProgress">
        <CircularProgress size={40} />
      </div>
    );
  }

  const handleDeleteDevelopment = async (id) => {
    return await callAxios('delete', `/development/${id}`)
      .then(async () => {
        await axios.get('/developments').then((res) => {
          setDevelopments(res.data.developments);
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <Grid>
        <DevelopmentsView
          columnDefs={columnDefs}
          handleDeleteDevelopment={handleDeleteDevelopment}
          developments={developments}
          setDevelopments={setDevelopments}
        />
      </Grid>
    </div>
  );
}

export default Developments;
