import React, { Component } from 'react';
import EditSharpIcon from '@material-ui/icons/EditSharp';
import DeleteForeverSharpIcon from '@mui/icons-material/DeleteForeverSharp';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';

import './appStatusLinkRenderer.scss';

export default class appStatusLinkRenderer extends Component {
  render() {
    const statusId = this.props.node.data.id;
    const IsSystemDependant = this.props.node.data.system_dependant;
    const deleteIconTooltipText = IsSystemDependant
      ? 'Cannot Delete: System Dependant'
      : 'Delete Status';

    return (
      <div className="link-renderer-container">
        <TooltipComponent style={{ display: 'inline' }} content="Edit Status">
          <span className={'tableIconButton'}>
            <EditSharpIcon
              onClick={() => {
                this.props.context.editStatus(statusId);
              }}
            />
          </span>
        </TooltipComponent>
        <TooltipComponent
          content={deleteIconTooltipText}
          style={{ display: 'inline' }}
        >
          <span
            className={`tableIconButton ${IsSystemDependant ? 'disabled' : ''}`}
          >
            <DeleteForeverSharpIcon
              className={IsSystemDependant ? 'disabled' : ''}
              onClick={() => {
                if (IsSystemDependant) return;
                this.props.context.deleteStatus(statusId);
              }}
            />
          </span>
        </TooltipComponent>
      </div>
    );
  }
}
