import React, { useState, useEffect, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import vdlinkRenderer from '../VDlinkRenderer';
import axios from 'axios';
import { IconButton, Snackbar } from '@material-ui/core';
import { FaTimes } from 'react-icons/fa';

import FsButton from '../../components/baseComponents/fsButton/FsButton';
import useAxios from '../../hooks/useAxios';

import PropertyUpdateModal from './properties/PropertyUpdateModal';
import PropertyViewModal from './properties/PropertyViewModal';

import './propertiesListView.scss';

const PropertiesListView = () => {
  const { callAxios } = useAxios();
  const gridRef = useRef();

  const columnDefs = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
    },
    {
      field: 'bedrooms',
      headerName: 'No. of Beds',
      flex: 1,
    },
    {
      field: 'price',
      headerName: 'Price of House',
      flex: 1,
    },
    {
      field: 'affordable_purchase_price',
      headerName: 'Affordable Purchase Price',
      flex: 1,
      valueFormatter: (params) => {
        return '€' + params.value;
      },
    },
    {
      field: 'property_type.name',
      headerName: 'Property Type',
      flex: 1,
      valueGetter: function (params) {
        return params.data.property_type?.name;
      },
    },
    {
      field: 'empty',
      headerName: 'Action',
      flex: 1,
      cellRenderer: 'vdlinkRenderer',
    },
  ];

  const [isEditing, setIsEditing] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [activeProperty, setActiveProperty] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);
  const [snackMsg, setSnackMsg] = useState('');
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackType, setSnackType] = useState('');

  // On first load, fetch all properties.
  useEffect(() => {
    const fetchProperties = async () => {
      const response = await axios.get('/properties');
      setRowData(response.data.properties);
    };

    fetchProperties();
  }, []);

  useEffect(() => {
    if (snackMsg !== '') {
      setSnackOpen(true);
    }
  }, [snackMsg]);

  const closeSnack = () => {
    setSnackOpen(false);
  };
  // CLICK HANDLERS
  const handleCreatePropertyClick = () => {
    setIsEditing(false);
    setActiveProperty({});
    setShowModal(true);
  };

  const handleClickEdit = (id) => {
    setIsEditing(true);
    const targetIndex = rowData.findIndex((row) => row.id === id);
    setActiveProperty(rowData[targetIndex]);
    setShowModal(true);
  };

  const handleClickView = (id) => {
    const targetIndex = rowData.findIndex((row) => row.id === id);
    setActiveProperty(rowData[targetIndex]);
    setShowViewModal(true);
  };

  // CRUD FUNCTIONS
  const addNewProperty = async (newProperty) => {
    try {
      const response = await callAxios('post', '/property', newProperty);

      if (response.error) {
        setSnackMsg('');
        setSnackMsg(response.error);
        setSnackType('bg-error');
      } else {
        setRowData((prevData) => [...prevData, response.data.property]);
        setShowModal(false);
      }
    } catch (error) {
      setSnackMsg('');
      setSnackMsg(error.message);
      setSnackType('bg-error');
    }
  };

  const deleteProperty = async (id) => {
    try {
      const response = await callAxios('delete', `/property/${id}`);
      if (response.status === 200) {
        setRowData((prevData) => prevData.filter((row) => row?.id !== id));
      } else if (response.status === 418) {
        setSnackMsg('');
        setSnackMsg(response.data.message);
        setSnackType('bg-danger');
      } else {
        setSnackMsg('');
        setSnackMsg(response.error);
        setSnackType('bg-danger');
        setSnackOpen(true);
      }
    } catch (error) {
      setSnackMsg('');
      setSnackMsg(error.message);
      setSnackType('bg-danger');
    }
  };

  const editProperty = async (newProperty) => {
    try {
      const response = await callAxios(
        'put',
        `property/${newProperty.id}`,
        newProperty
      );
      if (response.status === 200) {
        setRowData((prevData) =>
          prevData.map((row) => (row?.id === id ? response.data : row))
        );
        setShowModal(false);
      } else {
        setSnackMsg(response.error);
        setSnackType('bg-error');
      }
    } catch (error) {
      setSnackMsg(error.message);
      setSnackType('bg-error');
    }
  };

  return (
    <>
      <div className="property-list-topper">
        <FsButton buttonText="Create" onClick={handleCreatePropertyClick} />
      </div>
      <div
        className="ag-theme-material"
        style={{ height: '52vh', width: '100%' }}
      >
        <AgGridReact
          ref={gridRef}
          reactUi={true}
          rowData={rowData}
          defaultColDef={{
            sortable: 'true',
            editable: false,
            cellStyle: { textAlign: 'left' },
          }}
          context={{
            deleteCall: (id) => deleteProperty(id),
            editCall: (id) => handleClickEdit(id),
            viewCall: (id) => handleClickView(id),
          }}
          columnDefs={columnDefs}
          frameworkComponents={{
            vdlinkRenderer: vdlinkRenderer,
          }}
        />
      </div>

      {showViewModal && (
        <PropertyViewModal
          activeProperty={activeProperty}
          setShowViewModal={setShowViewModal}
        />
      )}

      {showModal && (
        <PropertyUpdateModal
          activeProperty={activeProperty}
          setActiveProperty={setActiveProperty}
          handleSave={addNewProperty}
          setShowModal={setShowModal}
          isEditing={isEditing}
          editProperty={editProperty}
        />
      )}

      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={snackOpen}
        autoHideDuration={2500}
        onClose={closeSnack}
        message={snackMsg}
        className={snackType}
        action={
          <>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={closeSnack}
            >
              <FaTimes />
            </IconButton>
          </>
        }
      />
    </>
  );
};

export default PropertiesListView;
