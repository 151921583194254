import React, { useEffect, useState } from 'react';
import { CircularProgress, Snackbar, Card } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { Button } from 'reactstrap';
import axios from 'axios';
import useAxios from '../hooks/useAxios';

function emailOauthSettings() {
  const { isLoading, callAxios } = useAxios();
  const [loading, setLoading] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackMsg, setSnackMsg] = useState('');
  const [snackType, setSnackType] = useState('bg-success');
  const [authorized, setAuthorized] = useState(false);
  const [mailbox, setMailbox] = useState('');
  const [mailAuthUrl, setMailAuthUrl] = useState('');
  const [mailRefresh, setMailRefresh] = useState(false);

  const closeSnack = () => {
    setSnackOpen(false);
    setSnackMsg('');
    setSnackType();
  };

  const checkMailAuthorization = async () => {
    return await callAxios('get', '/mail/check-authorization');
  };

  const deauthorizeMailbox = async () => {
    return await callAxios('put', '/mail/deauthorize');
  };

  useEffect(async () => {
    const [mailAuthSettings] = await Promise.all([checkMailAuthorization()]);
    setAuthorized(mailAuthSettings.data.authorized);
    setMailbox(mailAuthSettings.data.mailbox);
    setMailAuthUrl(mailAuthSettings.data.auth_url);
  }, [mailRefresh]);

  const deauthorizeMail = async () => {
    setLoading(true);
    const [mailDeauthorized] = await Promise.all([deauthorizeMailbox()]);
    if (mailDeauthorized.status == '200') {
      setAuthorized(false);
      setSnackMsg('You mailbox has been deauthorized');
      setSnackType('bg-success');
      setSnackOpen(true);
      setMailRefresh(true);
    }
    setLoading(false);
  };

  if (loading || isLoading) {
    return (
      <div className="circProgress">
        <CircularProgress size={40} />
      </div>
    );
  }

  return (
    <div>
      <Card className="text-center h100 padding20" elevation={4}>
        <div>
          <Grid
            container
            justifyContent="space-evenly"
            spacing={3}
            direction="column"
            className={'emailSettingsContainer'}
          >
            <Grid container direction="row" id="officelogogrid">
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <img className="officeLogoImg" src="../../office365_logo.png" />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <h3>Office 365 Mailbox</h3>
                {!authorized && (
                  <p>
                    {' '}
                    Please log in to your office 365 mailbox to authorise email
                    sending
                  </p>
                )}
                {authorized && <p>{mailbox.userPrincipalName} </p>}
              </Grid>
            </Grid>

            <Grid
              container
              spacing={2}
              justifyContent="center"
              alignItems="center"
              direction="row"
            >
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <div>
                  <a href={mailAuthUrl}>
                    <Button
                      className="react-strap-button-primary"
                      color="primary"
                      disabled={authorized}
                      variant="contained"
                    >
                      Authorise Your Mailbox
                    </Button>
                  </a>
                  <Button
                    className="react-strap-button-primary"
                    color="primary"
                    disabled={!authorized}
                    onClick={deauthorizeMail}
                    variant="contained"
                  >
                    Deauthorise
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Card>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={snackOpen}
        autoHideDuration={2000}
        onClose={closeSnack}
        message={snackMsg}
        className={snackType}
      />
    </div>
  );
}
export default emailOauthSettings;
