import React, { useState } from 'react';
import logo from '../../../../../../public/images/logo.png';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
} from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { Button } from 'reactstrap';

import './navigation.scss';

const Navigation = () => {
  const [isOpen, setIsOpen] = useState(false);

  function logout(e) {
    e.preventDefault();
    document.getElementById('logout-applicant-form').submit();
  }
  return (
    <Navbar className="navbarMeath" dark expand="md">
      <NavbarBrand className="navbar-brand">
        <img src={logo} className="Byn_navbar-logo" />
      </NavbarBrand>
      <NavbarToggler
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      />
      <Collapse isOpen={isOpen} navbar>
        <Nav className="mr-auto nav-wrapper" navbar>
          <NavItem>
            <NavLink
              className="navbar-dark navbar-nav nav-link header-item"
              to="/applicant/my-applications"
            >
              Home
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className="navbar-dark navbar-nav nav-link header-item"
              to="/applicant/supportpage"
            >
              Support
            </NavLink>
          </NavItem>
        </Nav>
        <Nav className="navbar-right navbar-dark navbar-nav nav-link" navbar>
          <NavLink to="/applicant/login" onClick={(e) => logout(e)}>
            <Button
              className="react-strap-button-primary"
              color="primary"
              variant="contained"
            >
              Log Out
            </Button>
          </NavLink>
        </Nav>
      </Collapse>
    </Navbar>
  );
};

export default Navigation;
