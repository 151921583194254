import React, { useState, useContext, useEffect } from 'react';
import Sidebar from '../layout/AdminView/Sidebar';
import Main from '../layout/AdminView/Main';
import Mainapplicant from '../layout/ApplicantVIew/Mainapplicant';
import { BrowserRouter } from 'react-router-dom';
import { SessionExpiryContext } from '../contexts/sessionExpiryStore';

import ExpiryModal from './expiryModal/ExpiryModal';

const RouterContainer = ({ role }) => {
  const { isExpiryModalOpen } = useContext(SessionExpiryContext);

  return (
    <>
      <BrowserRouter>
        <div className={'pageContainer'}>
          {role === 'Super Admin' && (
            <div className={'wrapper'}>
              <div className={'sidebar'}>
                <Sidebar />
              </div>
              <div className={'maincontent'}>
                <Main />
              </div>
              {isExpiryModalOpen && <ExpiryModal />}
            </div>
          )}
          {role === 'Admin' && (
            <div className={'wrapper'}>
              <div className={'sidebar'}>
                <Sidebar />
              </div>
              <div className={'maincontent'}>
                <Main />
              </div>
              {isExpiryModalOpen && <ExpiryModal />}
            </div>
          )}
          {role === 'Applicant' && (
            <div className={'wrapper'}>
              <div className={'maincontent'}>
                <Mainapplicant />
              </div>

              {isExpiryModalOpen && <ExpiryModal />}
            </div>
          )}
        </div>
      </BrowserRouter>
    </>
  );
};

export default RouterContainer;
