export const addErrorField = (updatedErrorFields, fieldId) => {
  if (!updatedErrorFields.find((error) => error === fieldId)) {
    updatedErrorFields.push(fieldId);
  }
  return updatedErrorFields;
};

export const removeErrorField = (updatedErrorFields, fieldId) => {
  updatedErrorFields = updatedErrorFields.filter((field) => field !== fieldId);
  return updatedErrorFields;
};

export const getFieldHasError = (field, value, formState = {}) => {
  let fieldHasError = false;
  let isRequiredByRule = field.requiredRules
    ? field.requiredRules(formState)
    : false;
  const isRequired = field.required || isRequiredByRule;
  if (isRequired && !value) {
    fieldHasError = true;
  } else if (field.regex) {
    if (!!value && !field.regex.test(value)) fieldHasError = true;
  } else if (isRequired && Array.isArray(value)) {
    if (value.length === 0) fieldHasError = true;
  }
  return fieldHasError;
};
