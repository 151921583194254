//import useState hook to create menu collapse state
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Spin as Hamburger } from 'hamburger-react';

//import react pro sidebar components
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from 'react-pro-sidebar';
//import icons from react icons
import { FaList } from 'react-icons/fa';
import { FiHome, FiLogOut, FiArchive } from 'react-icons/fi';
import { BiCog, BiWrench } from 'react-icons/bi';
import 'react-pro-sidebar/dist/css/styles.css';
import '../../../../css/sidebar.css';

var username = '';
var email = '';
const Sidebar = () => {
  //create initial menuCollapse state using useState hook
  const [menuCollapse, setMenuCollapse] = useState(false);

  //create a custom function that will change menucollapse state from false to true and true to false
  const menuIconClick = () => {
    //condition checking to change state from true to false and vice versa
    menuCollapse ? setMenuCollapse(false) : setMenuCollapse(true);
  };
  if (document.getElementById('username')) {
    username = $('#username').attr('data-username');
    email = $('#userEmail').attr('data-useremail');
  }
  function logout(e) {
    e.preventDefault();
    document.getElementById('logout-form').submit();
  }

  return (
    <div id="sidebar">
      <ProSidebar collapsed={menuCollapse}>
        <SidebarHeader id="logoandburger">
          <center>
            <div>
              <p>
                <img
                  src={
                    menuCollapse ? '/images/favicon.png' : '/images/logo.png'
                  }
                  alt={'Logo'}
                  className={menuCollapse ? 'collapsed' : 'logo'}
                />
              </p>
            </div>
            <div className="closemenu" onClick={menuIconClick}>
              <Hamburger />
            </div>
          </center>
        </SidebarHeader>

        <SidebarContent>
          <Menu iconShape="circle">
            <MenuItem icon={<FaList />}>
              <Link to="/app/applications" />
              Applications
            </MenuItem>
            <MenuItem icon={<FaList />}>
              <Link to="/app/reporting" />
              Reporting
            </MenuItem>
            {/* <MenuItem icon={<BiCog />}>
                            <Link to="/app/form" />
                            Forms
                        </MenuItem> */}
            {/* <SubMenu title={'Forms'} icon={<BiWrench />}>
                            <MenuItem> <Link to="/app/form" /> Cost Rental</MenuItem>
                            <MenuItem> <Link to="/app/APform" /> Affordable Purchase </MenuItem>
                        </SubMenu>  */}
            <MenuItem icon={<FiHome />}>
              <Link to="/app/developments" />
              Developments
            </MenuItem>
            {/* <MenuItem  icon={<FiArchive/>}>
                            <Link to="/app/lottery" />
                            Audits
                        </MenuItem> */}
            <MenuItem icon={<BiWrench />}>
              <Link to="/app/settings" />
              Settings
            </MenuItem>
            {/* <SubMenu title={'Settings'} icon={<BiWrench />}>
                            <MenuItem> <Link to="/app/emailSettings" /> Email Settings </MenuItem>
                            <MenuItem> <Link to="/app/emailTemplates" /> Email Templates </MenuItem>
                            <MenuItem> <Link to="/app/loginSettings" /> Login Settings </MenuItem>
                            <MenuItem> <Link to="/app/brandingSettings" /> Branding Settings </MenuItem>
                        </SubMenu>                  */}
          </Menu>
        </SidebarContent>
        <SidebarFooter>
          <Menu iconShape="circle">
            <MenuItem onClick={(e) => logout(e)} icon={<FiLogOut />}>
              Logout
            </MenuItem>
          </Menu>
        </SidebarFooter>
      </ProSidebar>
    </div>
  );
};

export default Sidebar;
