import React, { Component } from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteForeverSharpIcon from '@mui/icons-material/DeleteForeverSharp';
import DownloadIcon from '@mui/icons-material/Download';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';

export default class documentLinkRenderer extends Component {
  render() {
    const document = this.props.node.data;
    const node = this.props.node;
    return (
      <div>
        {this.props.context.viewDoc && (
          <TooltipComponent
            style={{ display: 'inline' }}
            content="View Document"
          >
            <span className={'tableIconButton'}>
              <VisibilityIcon
                onClick={() => {
                  this.props.context.viewDoc(document, node);
                }}
              />
            </span>
          </TooltipComponent>
        )}
        {this.props.context.deleteDoc && (
          <TooltipComponent
            style={{ display: 'inline' }}
            content="Delete Document"
          >
            <span className={'tableIconButton'}>
              <DeleteForeverSharpIcon
                onClick={() => {
                  this.props.context.deleteDoc(document);
                }}
              />
            </span>
          </TooltipComponent>
        )}
        {this.props.context.downloadDoc && (
          <TooltipComponent
            style={{ display: 'inline' }}
            content="Download Document"
          >
            <span className={'tableIconButton'}>
              <DownloadIcon
                onClick={() => {
                  this.props.context.downloadDoc(document);
                }}
              />
            </span>
          </TooltipComponent>
        )}
      </div>
    );
  }
}
