import React, { forwardRef } from 'react';

import {
  RichTextEditorComponent,
  Toolbar,
  Inject,
  Image,
  Link,
  HtmlEditor,
  Count,
  QuickToolbar,
  Table,
} from '@syncfusion/ej2-react-richtexteditor';

const FsRichTextEditor = forwardRef((props, ref) => {
  const toolbarSettings = {
    items: [
      {
        tooltipText: 'Insert Variable Placeholders',
        template:
          '<button id="toolsRTE_toolbar_variables" class="e-rte-dropdown-btn e-control e-dropdown-btn e-lib e-btn e-rte-dropdown-popup e-rte-dropdown-items e-formats-tbar-btn e-rte-elements e-tbar-btn" aria-haspopup="true" aria-expanded="false" aria-owns="toolsRTE_toolbar_Formats-popup" type="button" aria-label="Variables"" tabindex="-1"><span style="display: inline-flex;width:65px"><span class="e-rte-dropdown-btn-text">Variables</span></span><span class="e-btn-icon e-icons e-icon-right e-caret"></span></button>',
      },
      '|',
      'Bold',
      'Italic',
      'Underline',
      'FontName',
      'FontSize',
      'FontColor',
      'BackgroundColor',
      'LowerCase',
      'UpperCase',
      '|',
      'Formats',
      'Alignments',
      'NumberFormatList',
      'BulletFormatList',
      'Outdent',
      'Indent',
      '|',
      'CreateTable',
      'CreateLink',
      '|',
      'ClearFormat',
      'SourceCode',
      'FullScreen',
      '|',
      'Undo',
      'Redo',
    ],
  };

  const quickToolbarSettings = {
    table: [
      'TableHeader',
      'TableRows',
      'TableColumns',
      'TableCell',
      '-',
      'BackgroundColor',
      'TableRemove',
      'TableCellVerticalAlign',
      'Styles',
    ],
  };

  return (
    <div className="rte-control-section">
      <RichTextEditorComponent
        ref={ref}
        id="toolsRTE"
        height="53vh"
        enableHtmlEncode={true}
        toolbarSettings={toolbarSettings}
        quickToolbarSettings={quickToolbarSettings}
      >
        <div>{props.body}</div>
        <Inject
          services={[
            Toolbar,
            Image,
            Link,
            HtmlEditor,
            Count,
            QuickToolbar,
            Table,
          ]}
        />
      </RichTextEditorComponent>
    </div>
  );
});

export default FsRichTextEditor;
