import React from 'react';
import HouseholdList from '../../../components/HouseholdList';
const HouseholdTab = ({ applicantData }) => {
  return (
    <HouseholdList
      list={applicantData?.household_members}
      hideAddButton={true}
    />
  );
};

export default HouseholdTab;
