import { IconButton, Paper } from "@material-ui/core";
import React from "react";
import { useParams } from "react-router-dom";
import PrintSharpIcon from "@material-ui/icons/PrintSharp";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles({
    paper: {
        width: "100%",
        height: "100%",
        minHeight: "920px",
    },
});

function Summary() {
    const classes = useStyles();
    const { id } = useParams();

    return (
        <Paper className={classes.paper} elevation={3}>
            <center>
                <div>
                    <img src="../../images/logo.png" width="150px" />
                    <h3>Your application was submitted successfully.</h3>
                    <p>
                        You will receive an email shortly with details of your
                        application. Our Property Manager will review your
                        application and we will be in contact in due course{" "}
                    </p>
                    <p>
                        Your application ID is {id}. Please retain this and the
                        email to identify your application in any correspondence
                        with us.
                    </p>
                    <IconButton
                        onClick={() => {
                            window.print();
                        }}
                    >
                        <PrintSharpIcon />
                    </IconButton>
                </div>
            </center>
        </Paper>
    );
}

export default Summary;
