import { useState, useContext } from 'react';
import axios from 'axios';
import { SessionExpiryContext } from '../contexts/sessionExpiryStore';

function useAxios() {
  const TOKEN = document.getElementById('userToken').getAttribute('token');

  const { handleResetTimer } = useContext(SessionExpiryContext);

  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const instance = axios.create({
    baseURL: '/api',
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${TOKEN}`,
    },
  });

  const callAxios = async (method, endpoint, body = null) => {
    handleResetTimer();

    const formData = new FormData();

    for (const field in body) {
      // Skip any empty or null fields
      if (!body[field] || body[field].length === 0) {
        continue;
      }

      if (Array.isArray(body[field]) && body[field].length !== 0) {
        body[field].forEach((file) => {
          if (file instanceof File) {
            formData.append(field + '[]', file);
          } else {
            formData.append(field + '[]', JSON.stringify(file));
          }
        });
      } else {
        formData.append(field, body[field]);
      }
    }
    let response;
    if (method === 'put') {
      method = 'post';
      formData.append('_method', 'put');
    }

    setIsLoading(true);
    try {
      response = await instance[method](endpoint, formData);
    } catch (err) {
      response = err.response;
      setError(err.response ? err.response.data : 'Something went wrong');
    }
    setIsLoading(false);
    return response;
  };

  return { error, isLoading, callAxios };
}

export default useAxios;
