import React from 'react';
import FsModal from '../../../components/baseComponents/fsModal/FsModal';

import './confirmationModal.scss';

const ConfirmationModal = ({ setShowConfirmationModal, handleSubmit }) => {
  const handleClose = () => {
    setShowConfirmationModal(false);
  };

  const handleSave = () => {
    handleSubmit();
    handleClose();
  };

  const modalContent = (
    <div className="confirmation-modal-content">
      <p>
        Once you submit your application it cannot be edited. Please ensure all
        the information you entered is accurate and correct.
      </p>
    </div>
  );

  return (
    <FsModal
      headerText="Are you sure ?"
      centeredHeader={true}
      handleClose={handleClose}
      content={modalContent}
      handleSave={handleSave}
      isSaveButtonDisabled={false}
      leftButtonText="Cancel"
      rightButtonText="Submit"
      hasCloseButton={false}
    />
  );
};

export default ConfirmationModal;
