import React from 'react';

import './applicationSubmitted.scss';
import FsButton from '../../../components/baseComponents/fsButton/FsButton';
import { FaPrint } from 'react-icons/fa';

const ApplicationSubmitted = ({ applicationId }) => {
  return (
    <div className="application-submitted-page-container">
      <h1>Application Submitted Successfully</h1>
      <p>
        Your application has been submitted successfully with the following
        application ID:
      </p>
      <h2>{applicationId}</h2>
      <p>
        You can use this application ID to track the progress of your
        application.
      </p>
      <p>
        The next step in the application process will be communicated to you via
        email or phone.
      </p>
      <FsButton
        buttonText="Print"
        onClick={() => {
          window.print();
        }}
        icon={<FaPrint style={{ marginLeft: '10px' }} />}
      ></FsButton>
    </div>
  );
};

export default ApplicationSubmitted;
