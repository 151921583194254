import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import CssTextField from '../../components/baseComponents/fsTextfield/textField';
import InputAdornment from '@mui/material/InputAdornment';
import { DateTimePickerComponent } from '@syncfusion/ej2-react-calendars';
import TypeSelect from '../../components/TypeSelect';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import HelpIcon from '@material-ui/icons/Help';
import { SwitchComponent } from '@syncfusion/ej2-react-buttons';
import {
  getDevelopmentPublishedStatusUtil,
  getSyncFusionDatepickerClass,
} from '../../../utils/utils';

const DevelopmentInfoWizardStep = ({
  errorFields,
  formLayout = {},
  FormState,
  setErrorFields,
  setFormState,
  setNextButtonDisabled,
}) => {
  let selectedDevelopmentType = {};

  const onChange = (e, fieldId) => {
    let newInput = e.target.value || '';
    let developmentStatus = FormState.status || 'Unpublished';

    if (['openingDate', 'closingDate'].includes(fieldId)) {
      developmentStatus = getDevelopmentPublishedStatusUtil({
        value: e.target.value,
        fieldId,
        FormState,
      });
    }

    setFormState({
      ...FormState,
      [e.target.id]: newInput,
      status: developmentStatus,
    });

    let updatedErrorFields = errorFields;
    const field = formLayout.find((field) => field.id === fieldId);

    if (newInput.length === 0 && field?.required) {
      if (!updatedErrorFields.includes(e.target.id)) {
        updatedErrorFields = [...updatedErrorFields, e.target.id];
        setErrorFields(updatedErrorFields);
      }
    } else {
      updatedErrorFields = updatedErrorFields.filter(
        (errorField) => errorField !== e.target.id
      );
      setErrorFields(updatedErrorFields);
    }
  };

  const checkRequiredFields = () => {
    const meetsRequirements = formLayout.every((field) => {
      if (!field.required) return true;

      const value = FormState[field.id];

      if (typeof value === 'number') return true;
      if (typeof value === 'string' && value.length > 0) return true;
      if (typeof value === 'object' && value !== null) return true;

      return false;
    });

    return meetsRequirements;
  };

  useEffect(() => {
    if (!Object.keys(FormState).length) return;

    selectedDevelopmentType = {
      id: FormState.type_id,
      name: FormState.type,
    };
  }, [FormState]);

  useEffect(() => {
    const hasRequiredFields = checkRequiredFields();
    const hasNoErrors = !errorFields.length;
    setNextButtonDisabled(!(hasRequiredFields && hasNoErrors));
  }, [errorFields]);

  const handleTypeSelect = (e, v) => {
    setFormState({
      ...FormState,
      type: e.name,
      type_id: v,
    });
  };

  const handleToggle = (e) => {
    setFormState((prevFormState) => ({
      ...prevFormState,
      auto_status: e.checked,
    }));
  };

  const handlePreRegToggle = (e) => {
    setFormState((prevFormState) => ({
      ...prevFormState,
      pre_reg: e.checked,
    }));
  };

  const getDateValue = (stringDate) => {
    if (!stringDate) return '';
    return new Date(stringDate)?.toISOString() ?? '';
  };

  const getMinimumClosingDate = (openingDate) => {
    if (typeof openingDate === 'string') {
      return new Date(openingDate);
    }
    return openingDate;
  };

  return (
    <div className="developments-wizard-form-container">
      <Grid container spacing={2}>
        <Grid item xs={6} sm={6} md={6} lg={6}>
          <CssTextField
            id="name"
            error={errorFields.includes('name')}
            fullWidth
            label="Name"
            onChange={(e) => {
              onChange(e, 'name');
            }}
            placeholder="Name"
            required={true}
            size="small"
            style={{ marginTop: '15px' }}
            value={FormState.name}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={6}>
          <CssTextField
            id="address"
            error={errorFields.includes('address')}
            fullWidth
            label="Address"
            onChange={(e) => {
              onChange(e, 'address');
            }}
            placeholder="Address"
            required={true}
            size="small"
            style={{ marginTop: '15px' }}
            value={FormState.address}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={6}>
          <TypeSelect
            id="type"
            fullWidth
            func={handleTypeSelect}
            isRequired={true}
            selected={selectedDevelopmentType}
            selectedType={FormState.type}
            selectedTypeId={FormState.type_id}
            type={'Developments'}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={6}>
          <CssTextField
            id="income"
            placeholder="Income Threshold"
            error={errorFields.includes('income')}
            fullWidth
            label="Income Threshold"
            InputProps={{
              pattern: '[0-9]*',
              startAdornment: (
                <InputAdornment position="start">€</InputAdornment>
              ),
            }}
            onChange={(e) => {
              onChange(e, 'income');
            }}
            size="small"
            value={FormState.income}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <DateTimePickerComponent
            allowEdit={false}
            cssClass={`${getSyncFusionDatepickerClass({
              datepickerError: errorFields.includes('openingDate'),
              datepickerFormData: FormState?.openingDate,
            })} sync-fusion-datetime-picker-custom`}
            floatLabelType="Auto"
            format="dd/MM/yyyy HH:mm"
            id="openingDate"
            name="openingDate"
            openOnFocus={true}
            onChange={(e) => {
              onChange(e, 'openingDate');
            }}
            placeholder="Opening Date and Time"
            value={getDateValue(FormState.openingDate)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <DateTimePickerComponent
            allowEdit={false}
            cssClass={`${getSyncFusionDatepickerClass({
              datepickerError: errorFields.includes('closingDate'),
              datepickerFormData: FormState?.closingDate,
            })} sync-fusion-datetime-picker-custom`}
            enabled={!!FormState.openingDate}
            floatLabelType="Auto"
            format="dd/MM/yyyy HH:mm"
            id="closingDate"
            min={getMinimumClosingDate(FormState.openingDate)}
            name="closingDate"
            onChange={(e) => {
              onChange(e, 'closingDate');
            }}
            openOnFocus={true}
            placeholder="Closing Date and Time"
            value={getDateValue(FormState.closingDate)}
          />
        </Grid>

        <Grid item xs={6} sm={6} md={6} lg={6}>
          Auto Status Change{' '}
          <TooltipComponent
            style={{ display: 'inline' }}
            content="Automatically change status of the development based on the opening and closing dates. <br/> The form will be available only between the opening and closing dates. <br/>You can also choose to manually open and close the applications by manually changing the status"
          >
            <HelpIcon />
          </TooltipComponent>
          <br />
          <SwitchComponent
            id="switch"
            cssClass="handle-color"
            checked={FormState.auto_status}
            change={(e) => {
              handleToggle(e);
            }}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={6}>
          Only Registered Users Can Apply
          <TooltipComponent
            content="Applicants must registered users before making application. The form will only be available in the User Interface after login"
            style={{ display: 'inline' }}
          >
            <HelpIcon />
          </TooltipComponent>
          <br />
          <SwitchComponent
            id="switch"
            cssClass="handle-color"
            checked={FormState.pre_reg}
            change={(e) => {
              handlePreRegToggle(e);
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default DevelopmentInfoWizardStep;
