import React, { useState, useEffect, useContext } from 'react';
import { SessionExpiryContext } from '../../../contexts/sessionExpiryStore';

import './countdownTimer.scss';

const CountdownTimer = () => {
  const { isExpiryModalOpen, handleLogout } = useContext(SessionExpiryContext);
  const [seconds, setSeconds] = useState(120);
  const [timeLeft, setTimeLeft] = useState(120);

  let intervalId;

  useEffect(() => {
    intervalId = setInterval(() => {
      setTimeLeft((prevTimeLeft) => prevTimeLeft - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft]);

  useEffect(() => {
    if (timeLeft === 0) {
      handleLogout();
    }
  }, [timeLeft]);

  return (
    <div className="countdown-timer">
      <svg className="countdown-timer-svg">
        <circle
          className="countdown-timer-circle-bg"
          r="50%"
          cx="50%"
          cy="50%"
        />
        <circle
          className="countdown-timer-circle"
          r="50%"
          cx="50%"
          cy="50%"
          style={{ strokeDasharray: `${(timeLeft / seconds) * 290} 283` }}
        />
      </svg>
      <div className="countdown-timer-label">
        {Math.floor(timeLeft / 60)}:
        {timeLeft % 60 < 10 ? `0${timeLeft % 60}` : timeLeft % 60}
      </div>
    </div>
  );
};

export default CountdownTimer;
