import React, { useEffect, useState } from 'react';
import validator from 'validator';
import {
  CircularProgress,
  Snackbar,
  Card,
  Grid,
  TextField,
} from '@material-ui/core';
import { Button } from 'reactstrap';
import axios from 'axios';
import CssTextField from '../components/baseComponents/fsTextfield/textField';

function ReportingSettings() {
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackMsg, setSnackMsg] = useState('');
  const [snackType, setSnackType] = useState('bg-success');
  const [url, setURL] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const closeSnack = () => {
    setSnackOpen(false);
    setSnackMsg('');
    setSnackType();
  };

  const handleUpdate = async () => {
    setLoading(true);
    const data = {
      url: url,
    };
    await axios.post('/setReportingURL', data).then((res) => {
      setSnackOpen(true);
      setSnackMsg('The reporting URL has been updated');
      setSnackType('bg-success');
    });
    setLoading(false);
  };
  const validate = (value) => {
    if (validator.isURL(value)) {
      setDisabled(false);
      setErrorMessage('');
    } else {
      setDisabled(true);
      setErrorMessage(
        'This Is Not A Valid URL. Please ensure you input a valid URL'
      );
    }
  };

  useEffect(async () => {
    setLoading(true);
    await axios.get('/getReportingURL').then((res) => {
      setURL(res.data.reportingURL);
    });
    setLoading(false);
  }, []);

  if (loading) {
    return (
      <div className="circProgress">
        <CircularProgress size={40} />
      </div>
    );
  }
  return (
    <div>
      <Card className="text-center h100 padding20" elevation={4}>
        <div>
          <Grid
            container
            justifyContent="space-evenly"
            spacing={3}
            direction="column"
            className={'emailSettingsContainer'}
          >
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <CssTextField
                variant="outlined"
                label="Reporting Dashboard URL"
                defaultValue={url}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => {
                  validate(e.target.value);
                  setURL(e.target.value);
                }}
              />
            </Grid>
            {errorMessage != '' && (
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <span
                  style={{
                    fontWeight: 'bold',
                    color: 'red',
                  }}
                >
                  {errorMessage}
                </span>
              </Grid>
            )}

            <Grid item xs={12} sm={12} md={12} lg={12}>
              <div>
                <Button
                  className="react-strap-button-primary"
                  disabled={disabled}
                  color="primary"
                  onClick={handleUpdate}
                  variant="contained"
                >
                  Update Reporting URL
                </Button>
              </div>
            </Grid>
          </Grid>
        </div>
      </Card>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={snackOpen}
        autoHideDuration={2000}
        onClose={closeSnack}
        message={snackMsg}
        className={snackType}
      />
    </div>
  );
}
export default ReportingSettings;
