import React, { useEffect, useState } from 'react';

import { SessionExpiryStore } from '../contexts/sessionExpiryStore';
import RouterContainer from '../components/RouterContainer';
const token = $('meta[name="csrf-token"]').attr('content');
function Layout() {
  const [role, setRole] = useState();

  useEffect(async () => {
    let isMounted = true;
    await axios
      .post('/getUserRole', { headers: { 'X-CSRF-TOKEN': token } })
      .then((response) => {
        if (isMounted) {
          setRole(response.data.role[0]);
        }
        return () => {
          isMounted = false;
        };
      });
  }, []);

  return (
    <SessionExpiryStore>
      <RouterContainer role={role} />
    </SessionExpiryStore>
  );
}

export default Layout;
