import React, { Component } from 'react';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import RemoveIcon from '@mui/icons-material/Remove';

export default class ShowLinkRenderer extends Component {
  render() {
    const data = this.props.data;
    const rowId = this.props.data.id;
    return (
      <div>
        <span className={'tableIconButton'}>
          {data.income_type === 'Additional Employment' ? (
            <ExpandCircleDownIcon
              onClick={() => {
                this.props.context.showCell(rowId);
              }}
            />
          ) : (
            <RemoveIcon />
          )}
        </span>
      </div>
    );
  }
}
