import React, { useState, useEffect } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@material-ui/core/Grid';
import CssTextField from '../../../components/baseComponents/fsTextfield/textField';

import AccountsList from '../../../components/AccountsList';

import './financesTab.scss';
import { getBooleanValueDisplayUtil } from '../../../../utils/utils';

const FinancesTab = ({ applicantData, jointApplicantData }) => {
  const [accountsList, setAccountsList] = useState([]);
  const [count, setCount] = useState(0);

  useEffect(() => {
    let jointAccounts = jointApplicantData?.accounts ?? [];

    const mergedAccounts = [...applicantData?.accounts, ...jointAccounts];
    setAccountsList(mergedAccounts);

    countDocs();
  }, [applicantData, jointApplicantData]);

  const countDocs = () => {
    var count = 0;
    accountsList.map((doc) => {
      if (doc.type === 'Bank Statements') {
        count++;
      }
    });
    setCount(count);
  };

  return (
    <>
      <Accordion disableGutters elevation={2}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className="finances-tab-accordion-header"
        >
          <div>
            <h2>Accounts</h2>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <AccountsList
            list={accountsList}
            hideAddButton={true}
            count={count}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion disableGutters elevation={2}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className="finances-tab-accordion-header"
        >
          <div>
            <h2>Mortgage Approval</h2>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <Grid
            alignItems="center"
            container
            direction="row"
            justifyContent="center"
            spacing={2}
          >
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <CssTextField
                fullWidth
                id="outlined-read-only-input"
                label="Mortgage Provider"
                defaultValue={applicantData?.mortgage_provider}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <CssTextField
                fullWidth
                id="outlined-read-only-input"
                label="Mortgage Amount"
                defaultValue={applicantData?.mortgage_amount ?? ''}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6}>
              <CssTextField
                fullWidth
                id="outlined-read-only-input"
                label="HTB Approved"
                defaultValue={getBooleanValueDisplayUtil(
                  applicantData?.htb_approved
                )}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <CssTextField
                fullWidth
                id="outlined-read-only-input"
                label="HTB Amount"
                defaultValue={applicantData?.htb_amount ?? ''}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default FinancesTab;
