import React from 'react';
import FsInput from '../../components/baseComponents/fsInput/FsInput';
import Uploader from '../../components/Uploader';
import AddAdditionalIncomeSection from './steps/households/AddAdditionalIncomeSection';

import './applicantFormSubsection.scss';

const ApplicantFormSubsection = ({
  documentUploaded,
  errorFields,
  formState,
  handleSave,
  handleUpload,
  isModal = false,
  isJointApplication = false,
  layout,
  onChange,
  overrideFormState,
  setErrorFields,
  setFlag,
  setFormState,
}) => {
  const anyElementVisible = layout.some((field) => {
    if (field.checkIsVisible) {
      return field.checkIsVisible(formState);
    }
    return true;
  });

  if (anyElementVisible) {
    const inputElements = [];
    const uploaderElements = [];
    const modalElements = [];

    layout.forEach((input) => {
      if (input.type !== 'uploader' && input.type !== 'modal') {
        let isVisible = true;
        if (input.checkIsVisible) {
          isVisible = input.checkIsVisible(formState);
        }
        if (isVisible) {
          inputElements.push(
            <div
              className={
                input.type === 'radioGroup'
                  ? 'applicant-form-subsection-radio-group'
                  : 'applicant-form-subsection-inputs'
              }
              key={input.id}
            >
              <FsInput
                changeHandler={onChange}
                errorFields={errorFields}
                field={input}
                formState={formState}
                isJointApplication={isJointApplication}
                overrideFormState={overrideFormState}
              />
            </div>
          );
        }
      } else if (input.type === 'uploader') {
        let isVisible = true;
        if (input.checkIsVisible) {
          isVisible = input.checkIsVisible(formState);
        }
        if (isVisible) {
          uploaderElements.push(
            <Uploader
              errorFields={errorFields}
              field={input}
              formState={formState}
              handleSave={handleSave}
              isModal={isModal}
              key={'uploader-' + input.id}
              onChange={handleUpload}
              overrideFormState={overrideFormState}
            />
          );
        }
      } else if (
        input.type === 'modal' &&
        (input.modalDataId === 'otherIncomeList' ||
          input.modalDataId === 'jointApplicant_otherIncomeList')
      ) {
        let isVisible = true;
        if (input.checkIsVisible) {
          isVisible = input.checkIsVisible(formState);
        }
        if (isVisible) {
          modalElements.push(
            <AddAdditionalIncomeSection
              columnDefs={input.columnDefs}
              errorFields={errorFields}
              formState={formState}
              errorMessage={input.errorMessage}
              handleSave={handleSave}
              isJointApplication={isJointApplication}
              modalDataId={input.modalDataId}
              setErrorFields={setErrorFields}
              setFlag={setFlag}
              setFormState={setFormState}
              key={input.modalDataId}
            />
          );
        }
      }
    });

    return (
      <>
        <div className="applicant-form-subsection-wrapper">
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <div className="subsection-input-fields">{inputElements}</div>
            <div className="subsection-uploader-wrapper">
              {uploaderElements}
            </div>
          </div>
          <div className="subsection-modal-wrapper">{modalElements}</div>
        </div>
      </>
    );
  }
  return <></>;
};

export default ApplicantFormSubsection;
