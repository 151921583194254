import React, { useState, useEffect, useRef } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Button } from 'reactstrap';
import { TextField } from '@mui/material';
import CssTextField from './baseComponents/fsTextfield/textField';

function PropertySelect(props) {
  const [properties, setProperties] = useState([]);
  const [list] = useState(props.list);
  const [quantity, setQuantity] = useState(1);
  const [selected, setSelected] = useState({});
  const [isAddButtonDisabled, setIsAddButtonDisabled] = useState(true);
  const didMount = useRef(false);

  const addProperty = () => {
    const updatedSelected = selected;
    let pivotDetails = updatedSelected.pivot || {};
    pivotDetails = { ...pivotDetails, quantity };
    updatedSelected.pivot = pivotDetails;

    props.func(updatedSelected);
  };

  const onChange = (e) => {
    setQuantity(e.target.value);
  };

  const checkRequiredFields = () => {
    if (Object.keys(selected).length && quantity > 0) return true;
  };

  useEffect(async () => {
    if (!didMount.current) {
      return (didMount.current = true);
    }
    setIsAddButtonDisabled(!checkRequiredFields());
  }, [quantity, selected]);

  useEffect(async () => {
    await axios.get('/properties').then((res) => {
      setProperties(res.data.properties);
    });
  }, []);

  return (
    <FormControl sx={{ m: 1, minWidth: 200 }}>
      <InputLabel id="select">Properties</InputLabel>
      <Select
        labelId="select"
        id="select"
        value={selected}
        label="Properties"
        onChange={(event) => {
          setSelected(event.target.value);
        }}
      >
        {properties.map((prop) => {
          if (!list.some((element) => element.id == prop.id)) {
            return (
              <MenuItem value={prop} key={prop.id}>
                {prop.name + ' (' + prop.bedrooms + ' Bed)'}
              </MenuItem>
            );
          }
        })}
      </Select>
      <CssTextField
        id="quantity"
        error={!quantity}
        fullWidth
        label="Quantity"
        inputProps={{ type: 'number' }}
        onChange={(e) => {
          onChange(e, 'quantity');
        }}
        placeholder="Quantity"
        required={true}
        size="medium"
        style={{ marginBottom: '15px', marginTop: '15px' }}
        value={quantity}
        variant="outlined"
      />
      <Button
        type="button"
        className="btn-primary react-strap-button-primary"
        disabled={isAddButtonDisabled}
        onClick={() => {
          addProperty();
        }}
      >
        Add
      </Button>
      {/* <FormHelperText>With label + helper text</FormHelperText> */}
    </FormControl>
  );
}
export default PropertySelect;
