import React, { useEffect, useRef, useState } from 'react';
import ApplicantFormSubsection from '../../ApplicantFormSubsection';
import FsAccordion from '../../../../components/baseComponents/fsAccordion/FsAccordion';
import FsInput from '../../../../components/baseComponents/fsInput/FsInput';
import linkRenderer from '../../../../components/EDlinkRenderer';
import FsButton from '../../../../components/baseComponents/fsButton/FsButton';
import HouseholdsModal from './HouseholdsModal';

import { AgGridReact } from 'ag-grid-react';

import './households.scss';
import { getFormattedDateUtil } from '../../../../../utils/utils';

const Households = ({
  errorFields,
  formState,
  handleUpload,
  handleSave,
  mainLayout,
  jointLayout,
  handleChange,
  setErrorFields,
  setFormState,
}) => {
  const gridRef = useRef();

  const [flag, setFlag] = useState(false);

  const [showHouseholdMembersModal, setShowHouseholdMembersModal] =
    useState(false);

  const [newHouseholdMember, setNewHouseholdMember] = useState({ id: null });

  const [columnDefs] = useState([
    {
      field: 'householdMemberFirstName',
      headerName: 'First Name',
      sortable: 'true',
      filter: 'agTextColumnFilter',
      width: 150,
    },
    {
      field: 'householdMemberLastName',
      headerName: 'Last Name',
      sortable: 'true',
      filter: 'agTextColumnFilter',
      width: 150,
    },
    {
      field: 'householdMemberDateOfBirth',
      headerName: 'Date of Birth',
      sortable: 'true',
      filter: 'agTextColumnFilter',
      width: 150,
      valueGetter: (params) => {
        return getFormattedDateUtil({
          dateString: params.data.householdMemberDateOfBirth,
        });
      },
    },
    {
      field: 'householdMemberAge',
      headerName: 'Age',
      sortable: 'true',
      filter: 'agTextColumnFilter',
      width: 150,
    },
    {
      field: 'householdMemberPPSN',
      headerName: 'PPSN',
      sortable: 'true',
      filter: 'agTextColumnFilter',
      width: 200,
    },
    {
      field: 'householdMemberRelationshipToApplicant',
      headerName: 'Relationship to Applicant',
      sortable: 'true',
      filter: 'agTextColumnFilter',
      width: 200,
    },
    {
      headerName: 'Action',
      cellRenderer: 'linkRenderer',
      width: 120,
      pinned: 'right',
    },
  ]);

  const [selectedIndex, setSelectedIndex] = useState(null);

  // joint applicant checkbox handler
  const handleJointChange = (e) => {
    let filtered = errorFields.filter((errorField) => {
      return !errorField.startsWith('jointApplicant_');
    });
    setErrorFields(filtered);

    setFormState({ ...formState, isJointApplication: e.target.checked });
  };

  const handleMainApplicantChange = (e, field) => {
    handleChange(e, field);
  };

  const handleJointApplicantChange = (e, field) => {
    handleChange(e, field, true);
  };

  const handleAddHouseholdMemberButtonClick = () => {
    setSelectedIndex(null);
    setShowHouseholdMembersModal(true);
  };

  const deleteHouseholdMember = (id) => {
    const filtered = formState.householdMembers.filter((member) => {
      return member.id !== id;
    });
    setFormState({ ...formState, householdMembers: filtered });
    setFlag(true);
  };

  const editHouseholdMember = (id) => {
    setSelectedIndex(id);
    setShowHouseholdMembersModal(true);
  };

  useEffect(() => {
    if (flag) {
      handleSave();
      setFlag(false);
    }
  }, [flag]);

  const mainApplicantAccordionContent = mainLayout.map((section, index) => {
    return (
      <ApplicantFormSubsection
        errorFields={errorFields}
        formState={formState}
        handleUpload={handleUpload}
        handleSave={handleSave}
        key={index}
        layout={section}
        onChange={handleMainApplicantChange}
        setErrorFields={setErrorFields}
        setFlag={setFlag}
        setFormState={setFormState}
      />
    );
  });

  const jointApplicantAccordionContent = jointLayout.map((section, index) => {
    return (
      <ApplicantFormSubsection
        errorFields={errorFields}
        formState={formState}
        handleSave={handleSave}
        handleUpload={handleUpload}
        isJointApplication={true}
        key={index}
        layout={section}
        onChange={handleJointApplicantChange}
        setErrorFields={setErrorFields}
        setFlag={setFlag}
        setFormState={setFormState}
      />
    );
  });

  const householdMembersAccordionContent = (
    <>
      <div className="button-wrapper-div d-flex align-items-center justify-content-between">
        <h6 style={{fontWeight:'bold'}}>Please note that all household member's, <u>excluding</u> the main & joint applicants, who intend to reside in the property should be listed under the Additional Household Members Section</h6>
        <FsButton
          buttonText="Add a Household Member"
          id="addHouseholdMemberButton"
          onClick={handleAddHouseholdMemberButtonClick}
        />
      </div>
      <div
        className="ag-theme-material"
        style={{
          height: '400px',
          overflow: 'scroll',
        }}
      >
        <AgGridReact
          ref={gridRef}
          height={100}
          reactUi={true}
          rowData={formState.householdMembers || []}
          defaultColDef={{
            sortable: 'true',
            resizable: true,
            editable: false,
            cellStyle: { textAlign: 'left' },
          }}
          columnDefs={columnDefs}
          frameworkComponents={{
            linkRenderer: linkRenderer,
          }}
          context={{
            editCall: (id) => editHouseholdMember(id),
            deleteCall: (id) => deleteHouseholdMember(id),
          }}
        ></AgGridReact>
      </div>
    </>
  );

  return (
    <div className="households-wrapper">
      <h5 className="affordable-purchase-form-section-header">Households</h5>
      <div className="affordable-purchase-form-households-joint-checkbox-section">
        <span style={{ padding: '15px', paddingRight: '5px' }}>
          Add Joint Applicant
        </span>
        <FsInput
          changeHandler={handleJointChange}
          value={formState.isJointApplication ?? ''}
          checked={formState.isJointApplication}
          field={{ id: 'isJointApplication', type: 'checkbox' }}
          formState={formState}
        />
      </div>
      <form>
        <FsAccordion
          headerContent="Main Applicant"
          bodyContent={mainApplicantAccordionContent}
        />
      </form>

      {formState.isJointApplication && (
        <form>
          <FsAccordion
            headerContent="Joint Applicant"
            bodyContent={jointApplicantAccordionContent}
          />
        </form>
      )}

      <FsAccordion
        headerContent="Additional Household Members"
        bodyContent={householdMembersAccordionContent}
      />

      {showHouseholdMembersModal && (
        <HouseholdsModal
          formState={formState}
          setFormState={setFormState}
          setShowModal={setShowHouseholdMembersModal}
          newMember={newHouseholdMember}
          setNewMember={setNewHouseholdMember}
          setFlag={setFlag}
          rowIndex={selectedIndex}
        />
      )}
    </div>
  );
};

export default Households;
