export const documentRequirements = [
  {
    requirement: 'Photographic Identification',
    tooltip: [
      'Valid, signed Passport/Passport card',
      'Current Valid EU/EEA Driving Licence. Must contain photograph (Irish Provisional Licence accepted)',
      'Current EU National Identity Card',
    ],
  },
  {
    requirement: 'Proof of Present Address dated within the last 6 months',
    tooltip: [
      'Current utility bill (gas, electricity, landline telephone (not mobile))',
      'Bank statement/credit union statement',
      'Document issued by government department that shows your address',
      'Statement of Liability P21 from Revenue',
    ],
  },
  {
    requirement: 'Proof of fresh start',
    tooltip: [
      'Court Decree / Solicitors letter confirming the applicant is divorced/separated or otherwise and have left the property and divested themselves of their interest in the property. Details of maintenance arrangement where applicable.',
      'Where the applicant has been divested of the property through insolvency or bankruptcy proceedings, proof of the applicant’s status on the bankruptcy register is required. Proof that any property you previously owned or built has been sold, or given as part of a personal insolvency, bankruptcy agreement or other legal insolvency process. A separate assessment of creditworthiness will be conducted.',
    ],
  },
  {
    requirement: 'Proof of unsuitable property',
    tooltip: ['Up to date valuation of your current property.'],
  },
  {
    requirement: 'Proof of Residency in the Republic of Ireland',
    tooltip: [
      'Summary (P60)',
      'Statement of Payments/Benefits from Dept. of Social Protection',
      'Tenancy agreement if you are renting',
      'Bank statements for at least 6 months showing day-to-day transactions or Direct Debits for utility bills',
      'An employment letter from your current employer, which includes your name, address and date you commenced work.',
    ],
  },
  {
    requirement:
      'Proof of Right to Reside in Ireland for non-Irish/EU nationals',
    tooltip: [
      'For non-Irish/EU nationals:',
      'Provide evidence of legal and habitual residence in Ireland by way of immigration permissions and stamps',
    ],
  },
  {
    requirement:
      'Proof of Right to Reside in Ireland',
    tooltip: [
      'For non-Irish/EU nationals:',
      'Provide evidence of legal and habitual residence in Ireland by way of immigration permissions and stamps',
    ],
  },
  {
    requirement: 'Proof of income',
    tooltip: [
      'PAYE employees: Salary Certificate, Employment Detail Summary, and payslips (3 if paid monthly, 6 if paid fortnightly and 12 if paid weekly).',
      'Self Employed: Documents for the previous 2 years:- Audited/Certified Accounts, Tax Balancing Statement and Tax Payment Receipt. Projected income for the coming 12 months. Self-Assessment income tax for the previous year',
      'Social Welfare Income: Documentary evidence of all social insurance and social assistance payments, allowances and pensions being received by members of the household. As income of household members over 18 is also included in the assessment for qualification, evidence of payments to these household members is also needed',
    ],
  },
  {
    requirement: 'Proof of additional income',
    tooltip: ['Documented proof of additional income'],
  },
  {
    requirement:
      'Proof of being or having been resident in the administrative area of Meath County Council for a minimum period of 5 years for applicants to qualify under the 30% Residency Rule.',
    tooltip: [
      'Utility bills (gas, electricity, landline telephone (not mobile))',
      'Bank statements/credit union statements',
      'Documents issued by government department that shows your address',
      'Statements of Liability P21 from Revenue dated for EACH of the last 5 years',
    ],
  },
  {
    requirement: 'Proof of PPSN/Tax Registration Number',
    tooltip: [
      'Statement of Liability P21/ Tax Assessment',
      'Notice of Credits from Revenue',
      'Letter from Revenue Commissioners addressed to you showing PPSN',
      'Employee details from Revenue/ Receipt for social welfare payment',
      'Letter from Department of Employment Affairs and Social Protection addressed to you showing your PPSN',
      'Current Medical Card',
      'Current Drug Payment Scheme Card',
      'Payslip',
      'P45',
    ],
  },
  {
    requirement: 'Evidence of savings/deposit',
    tooltip: ['Statements for the previous 6 months'],
  },
  {
    requirement: 'If not a First Time Buyer',
    tooltip: [
      'Fresh Start Principle :',
      '- Court Decree / Solicitors letter confirming the applicant is divorced/separated or otherwise and have left the property and divested themselves of their interest in the property. Details of maintenance arrangement where applicable.',
      'Where the applicant has been divested of the property through insolvency or bankruptcy proceedings, proof of the applicant’s status on the bankruptcy register is required. Proof that any property you previously owned or built has been sold, or given as part of a personal insolvency, bankruptcy agreement or other legal insolvency process. A separate assessment of creditworthiness will be conducted.',
      'Unsuitable Dwelling :',
      '- Up to date valuation of your current property.',
    ],
  },
  {
    requirement: 'Confirmation of eligibility for Help to Buy Scheme',
    tooltip: [
      'Print out from Revenue portal (myAccount PAYE applicants)',
      'ROS (Self-assessed applicants) confirming names of applicant(s) and maximum entitlement under the scheme.',
      'Note that applicants are considered first-time-buyers only if BOTH are buying their home for the first time',
    ],
  },
  {
    requirement: 'Confirmation of Mortgage Approval In Principle',
    tooltip: [
      'Loan approval in principle letter stating the maximum mortgage available to applicants',
    ],
  },
  {
    requirement: 'Employed Income Help',
    tooltip: [
      'Salary Certificate, Employment Detail Summary, and payslips (3 if paid monthly, 6 if paid fortnightly and 12 if paid weekly).',
    ],
  },
  {
    requirement: 'Self Employed Income Help',
    tooltip: [
      'Documents for the previous 2 years:',
      ' - Audited/Certified Accounts, Tax Balancing Statement and Tax Payment Receipt. Projected income for the coming 12 months. Self-Assessment income tax for the previous year',
    ],
  },

  {
    requirement: 'Not Employed Income Help',
    tooltip: [
      'Documentary evidence of all social insurance and social assistance payments, allowances and pensions being received by members of the household. All income of household members over 18 is also included in the assessment for qualification, evidence of payments to these household members is also needed.',
    ],
  },
  {
    requirement: 'Employer Reference',
    tooltip: ['Dated within the previous 6 weeks'],
  },
  {
    requirement: 'Landlord Reference',
    tooltip: ['Dated within the previous 6 weeks'],
  },
  {
    requirement: 'Bank Statements',
    tooltip: ['For the previous 6 months'],
  },
];
