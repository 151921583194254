import React, { useEffect, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles, styled } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { Grid, Snackbar, IconButton, Portal } from '@material-ui/core';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import './support.scss';

const useStyles = makeStyles({
  paper: {
    width: '100%',
    height: '100%',
    minHeight: '920px',
  },
});

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const testData = [{ bgcolor: '#6a1b9a', completed: 60 }];

function Support() {
  const classes = useStyles();

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid
        container
        spacing={2}
        direction="row"
        alignItems="center"
        justifyContent="center"
      >
        <Grid item lg={12} sm={12} xs={12}>
          <Item>
            <div>
              <h1 className={'heading'}>FAQs</h1>
            </div>
            <Grid item xs={12} md={12} lg={12} xl={12}>
              <Accordion elevation={3}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <h5 className="Alt-head2">How do I submit an application?</h5>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid
                    container
                    direction="row"
                    justifycontent="center"
                    alignItems="center"
                    spacing={1}
                  >
                    <p>
                      To submit your application follow each application step
                      carefully. Make sure to fill in all required information
                      and upload all required documentation. You can watch the
                      video walkthrough provided on the homepage for reference.
                    </p>
                  </Grid>
                </AccordionDetails>
              </Accordion>

              <Accordion elevation={3}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <h5 className="Alt-head2">
                    Can I save my application and continue it later?
                  </h5>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid
                    container
                    direction="row"
                    justifycontent="center"
                    alignItems="center"
                    spacing={1}
                  >
                    <p>
                      Yes, every time you fill in a step and click next the
                      information will automatically save. If you need to leave
                      the application or close your browser, any step that has
                      been fully completed is saved automatically. If you
                      partially complete a step and want to close the browser
                      make sure you click next to save the information before
                      you leave.
                    </p>
                  </Grid>
                </AccordionDetails>
              </Accordion>
              <Accordion elevation={3}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <h5 className="Alt-head2">
                    I need to change my application but I have already
                    submitted, what should I do?
                  </h5>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid
                    container
                    direction="row"
                    justifycontent="center"
                    alignItems="center"
                    spacing={1}
                  >
                    <p>
                      Unfortunately, once you click submit on the final step you
                      cannot edit your application. You will need to contact
                      Meath County Council directly
                    </p>
                  </Grid>
                </AccordionDetails>
              </Accordion>
              <Accordion elevation={3}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <h5 className="Alt-head2">
                    How do I remove a document I have uploaded?
                  </h5>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid
                    container
                    direction="row"
                    justifycontent="center"
                    alignItems="center"
                    spacing={1}
                  >
                    <p>
                      If you need to remove a document for any reason you can
                      click the 'Documents' button on the card of the
                      development you are applying to on the homepage or you can
                      click the 'Documents' button on the top of the application
                      form. This will show a list of all the documents you have
                      already uploaded and you can delete one or multiple
                      documents from here. You will also be able to upload
                      additional documents using the 'Upload' button.
                    </p>
                  </Grid>
                </AccordionDetails>
              </Accordion>
              <Accordion elevation={3}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <h5 className="Alt-head2">
                    What document formats can I upload?
                  </h5>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid
                    container
                    direction="row"
                    justifycontent="center"
                    alignItems="center"
                    spacing={1}
                  >
                    <p>
                      Accepted document formats are *.jpg, .jpeg, .png, .pdf
                    </p>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Support;
