import React, { useEffect, useState } from 'react';
import { CircularProgress } from '@material-ui/core';

function Reporting() {
  const [url, setURL] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(async () => {
    setLoading(true);
    await axios.get('/getReportingURL').then((res) => {
      setURL(res.data.reportingURL);
    });
    setLoading(false);
  }, []);

  if (loading) {
    return (
      <div className="circProgress">
        <CircularProgress size={40} />
      </div>
    );
  }
  return (
    <iframe
      src={url}
      id="dashboard-frame"
      width="100%"
      height="1200px"
      allowfullscreen
      frameborder="0"
    ></iframe>
  );
}

export default Reporting;
