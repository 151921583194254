import React, { useEffect, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';

import FsButton from '../../../../components/baseComponents/fsButton/FsButton';
import AddAdditionalIncomeModal from './AddAdditionalIncomeModal';
import linkRenderer from '../../../../components/EDlinkRenderer';

const AddAdditionalIncomeSection = ({
  columnDefs,
  errorFields,
  errorMessage,
  formState,
  isJointApplication,
  modalDataId,
  setErrorFields,
  setFlag,
  setFormState,
}) => {
  const [selectedIndex, setSelectedIndex] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const gridRef = useRef();

  const handleOpenModal = () => {
    global.showExtraAdditionalIncomeFields = false;
    setSelectedIndex(null);
    setShowModal(true);
  };

  const editOtherIncome = (id) => {
    setSelectedIndex(id);
    setShowModal(true);
  };

  const deleteOtherIncome = (id) => {
    const filtered = formState[modalDataId].filter((otherIncome) => {
      return otherIncome.id !== id;
    });
    setFormState({ ...formState, [modalDataId]: filtered });
    setFlag(true);
  };

  return (
    <div key={modalDataId + '-modal'} style={{ width: '100%' }}>
      {errorFields.includes(modalDataId) && (
        <p className="input-error-message">{errorMessage}</p>
      )}
      <div className="button-wrapper-div">
        <FsButton
          buttonText="Add Additional Income"
          id={'addAdditionalIncomeButton_' + modalDataId}
          onClick={(e) => handleOpenModal()}
        />
      </div>
      <div
        className="ag-theme-material"
        style={{
          height: '400px',
          overflow: 'scroll',
        }}
      >
        <AgGridReact
          ref={gridRef}
          height={100}
          reactUi={true}
          rowData={formState[modalDataId]}
          defaultColDef={{
            cellStyle: { textAlign: 'left' },
            editable: false,
            resizable: true,
            flex: 2,
            sortable: 'true',
          }}
          frameworkComponents={{
            linkRenderer: linkRenderer,
          }}
          context={{
            deleteCall: (id) => deleteOtherIncome(id),
            editCall: (id) => editOtherIncome(id),
          }}
          columnDefs={columnDefs}
        ></AgGridReact>
      </div>

      {showModal && (
        <AddAdditionalIncomeModal
          tableGridRef={gridRef}
          formState={formState}
          headerText="Add Additional Income"
          modalDataId={modalDataId}
          isJointApplication={isJointApplication}
          rowIndex={selectedIndex}
          setFlag={setFlag}
          setShowModal={setShowModal}
          setErrorFields={setErrorFields}
          setFormState={setFormState}
        />
      )}
    </div>
  );
};

export default AddAdditionalIncomeSection;
