import React from 'react';
import Grid from '@material-ui/core/Grid';
import CssTextField from '../../../components/baseComponents/fsTextfield/textField';
import { getFormattedDateUtil } from '../../../../utils/utils';

const EmployedFormExtras = ({ applicantData }) => {
  return (
    <>
      <Grid item xs={12} sm={12} md={3} lg={3}>
        <CssTextField
          fullWidth
          id="outlined-read-only-input"
          label="Occupation"
          defaultValue={applicantData?.employment?.occupation}
          InputProps={{
            readOnly: true,
          }}
          InputLabelProps={{ shrink: !!applicantData?.employment?.occupation }}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={3} lg={3}>
        <CssTextField
          fullWidth
          id="outlined-read-only-input"
          label="Date of Commencement"
          defaultValue={getFormattedDateUtil({
            dateString: applicantData?.employment?.dateOfCommencement,
          })}
          InputProps={{
            readOnly: true,
          }}
          InputLabelProps={{
            shrink: !!applicantData?.employment?.dateOfCommencement,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={3} lg={3}>
        <CssTextField
          fullWidth
          id="outlined-read-only-input"
          label="Employment terms"
          defaultValue={applicantData?.employment?.employmentTerms}
          InputProps={{
            readOnly: true,
          }}
          InputLabelProps={{
            shrink: !!applicantData?.employment?.employmentTerms,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={3} lg={3}>
        <CssTextField
          fullWidth
          id="outlined-read-only-input"
          label="Frequency of Payment"
          defaultValue={applicantData?.employment?.freqOfPay}
          InputProps={{
            readOnly: true,
          }}
          InputLabelProps={{ shrink: !!applicantData?.employment?.freqOfPay }}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={3} lg={3}>
        <CssTextField
          fullWidth
          id="outlined-read-only-input"
          label="Annual Gross Income"
          defaultValue={applicantData?.employment?.grossIncome}
          InputProps={{
            readOnly: true,
          }}
          InputLabelProps={{ shrink: !!applicantData?.employment?.grossIncome }}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={3} lg={3}>
        <CssTextField
          fullWidth
          id="outlined-read-only-input"
          label="Overtime per annum"
          defaultValue={applicantData?.employment?.overtime}
          InputProps={{
            readOnly: true,
          }}
          InputLabelProps={{ shrink: !!applicantData?.employment?.overtime }}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={3} lg={3}>
        <CssTextField
          fullWidth
          id="outlined-read-only-input"
          label="Bonus per Annum"
          defaultValue={applicantData?.employment?.bonus}
          InputProps={{
            readOnly: true,
          }}
          InputLabelProps={{ shrink: !!applicantData?.employment?.bonus }}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={3} lg={3}>
        <CssTextField
          fullWidth
          id="outlined-read-only-input"
          label="Commission per Annum"
          defaultValue={applicantData?.employment?.commission}
          InputProps={{
            readOnly: true,
          }}
          InputLabelProps={{ shrink: !!applicantData?.employment?.commission }}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={3} lg={3}>
        <CssTextField
          fullWidth
          id="outlined-read-only-input"
          label="Employer Name"
          defaultValue={applicantData?.employment?.employerName}
          InputProps={{
            readOnly: true,
          }}
          InputLabelProps={{
            shrink: !!applicantData?.employment?.employerName,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={3} lg={3}>
        <CssTextField
          fullWidth
          id="outlined-read-only-input"
          label="Employer Address Line 1"
          defaultValue={applicantData?.employment?.employerAddressLine1}
          InputProps={{
            readOnly: true,
          }}
          InputLabelProps={{
            shrink: !!applicantData?.employment?.employerAddressLine1,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={3} lg={3}>
        <CssTextField
          fullWidth
          id="outlined-read-only-input"
          label="Employer Address Line 2"
          defaultValue={applicantData?.employment?.employerAddressLine2}
          InputProps={{
            readOnly: true,
          }}
          InputLabelProps={{
            shrink: !!applicantData?.employment?.employerAddressLine2,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={3} lg={3}>
        <CssTextField
          fullWidth
          id="outlined-read-only-input"
          label="Employer City"
          defaultValue={applicantData?.employment?.employerCity}
          InputProps={{
            readOnly: true,
          }}
          InputLabelProps={{
            shrink: !!applicantData?.employment?.employerCity,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={3} lg={3}>
        <CssTextField
          fullWidth
          id="outlined-read-only-input"
          label="Employer County"
          defaultValue={applicantData?.employment?.employerCounty}
          InputProps={{
            readOnly: true,
          }}
          InputLabelProps={{
            shrink: !!applicantData?.employment?.employerCounty,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={3} lg={3}>
        <CssTextField
          fullWidth
          id="outlined-read-only-input"
          label="Employer Eircode"
          defaultValue={applicantData?.employment?.employerEircode}
          InputProps={{
            readOnly: true,
          }}
          InputLabelProps={{
            shrink: !!applicantData?.employment?.employerEircode,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={3} lg={3}>
        <CssTextField
          fullWidth
          id="outlined-read-only-input"
          label="Type Of Business"
          defaultValue={applicantData?.employment?.typeOfBusiness}
          InputProps={{
            readOnly: true,
          }}
          InputLabelProps={{
            shrink: !!applicantData?.employment?.typeOfBusiness,
          }}
        />
      </Grid>
    </>
  );
};

export default EmployedFormExtras;
