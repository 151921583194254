import React from 'react';
import './fsButton.scss';

const FsButton = ({ onClick, buttonText, disabled, className, id, icon }) => {
  return (
    <button
      className={`${className ?? ''} ${
        disabled && 'fs-button-disabled'
      } fs-button`}
      disabled={disabled}
      onClick={() => onClick()}
      type="button"
      id={id}
    >
      {buttonText}
      {icon}
    </button>
  );
};

export default FsButton;
