import React, { Component } from 'react';
import { Button, Form } from 'reactstrap';
import Modal from 'react-modal';
import {
  Paper,
  CircularProgress,
  Collapse,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
  IconButton,
  Grid,
} from '@material-ui/core';
import { FaTimes } from 'react-icons/fa';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import linkRenderer from './EDlinkRenderer';
import CssTextField from '../components/baseComponents/fsTextfield/textField';
import {
  RichTextEditorComponent,
  Toolbar,
  Inject,
  Image,
  Link,
  HtmlEditor,
  Count,
  QuickToolbar,
  Table,
} from '@syncfusion/ej2-react-richtexteditor';
import { getFormattedDateUtil } from '../../utils/utils';

import 'ag-grid-enterprise';

const items = [
  {
    tooltipText: 'Insert Variable Placeholders',
    template:
      '<button id="toolsRTE_toolbar_variables" class="e-rte-dropdown-btn e-control e-dropdown-btn e-lib e-btn e-rte-dropdown-popup e-rte-dropdown-items e-formats-tbar-btn e-rte-elements e-tbar-btn" aria-haspopup="true" aria-expanded="false" aria-owns="toolsRTE_toolbar_Formats-popup" type="button" aria-label="Variables"" tabindex="-1"><span style="display: inline-flex;width:65px"><span class="e-rte-dropdown-btn-text">Variables</span></span><span class="e-btn-icon e-icons e-icon-right e-caret"></span></button>',
  },
  '|',
  'Bold',
  'Italic',
  'Underline',
  'FontName',
  'FontSize',
  'FontColor',
  'BackgroundColor',
  'LowerCase',
  'UpperCase',
  '|',
  'Formats',
  'Alignments',
  'NumberFormatList',
  'BulletFormatList',
  'Outdent',
  'Indent',
  '|',
  'CreateTable',
  'CreateLink',
  '|',
  'ClearFormat',
  'SourceCode',
  'FullScreen',
  '|',
  'Undo',
  'Redo',
];
const toolbarSettings = {
  items: items,
};
const quickToolbarSettings = {
  table: [
    'TableHeader',
    'TableRows',
    'TableColumns',
    'TableCell',
    '-',
    'BackgroundColor',
    'TableRemove',
    'TableCellVerticalAlign',
    'Styles',
  ],
};

Modal.setAppElement(document.getElementById('root'));
const customStyles = {
  overlay: {
    maxWidth: '95%',
    maxHeight: '95%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'fixed',
    top: 85,
    left: 200,
    right: -10,
    bottom: 0,
    padding: '1rem',
    zIndex: '9999',
    opacity: 1,
    animation: '$show .5s ease',
    background: 'transparent',
  },
};

class EmailTemplates extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      templates: [],
      values: {},
      formTitle: '',

      openDialog: false,
      showModal: false,
      showSendModal: false,
      openDelDialog: false,
      delTempId: 0,
      TinyMCE_APIkey: '65dv9slci6t50alil0l9mjgbvv2swpvz7geiz8tn9re0t0w6',
      rteObj: null,
      elements: [
        'id',
        'fullname',
        'firstname',
        'surname',
        'email',
        'development',
        'property',
        'income',
        'status',
        'sitetitle',
        'siteurl',
        'siteemail',
      ],

      snackOpen: false,
      snackMsg: '',
      snackType: 'bg-success',

      // AG Grid
      columnDefs: [
        {
          field: 'alias',
          headerName: 'Alias',
          width: 150,
        },
        {
          field: 'subject',
          headerName: 'Subject',
        },
        {
          field: 'custom_from_address',
          headerName: 'From',
        },
        /*{
                    field: 'created_at',
                    headerName: 'Created',
                    filter: 'agDateColumnFilter',
                    valueFormatter: this.dateFormatter,
                },
                {
                    field: 'updated_at',
                    headerName: 'Last Updated',
                    filter: 'agDateColumnFilter',
                    valueFormatter: this.dateFormatter,
                },*/
        {
          field: 'empty',
          headerName: 'Action',
          cellRenderer: 'linkRenderer',
          width: 100,
        },
      ],
      frameworkComponents: {
        linkRenderer: linkRenderer,
      },
      defaultColDef: {
        sortable: true,
        flex: 1,
        filter: true,
        minWidth: 100,
        resizable: true,
      },
    };
  }

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.setDomLayout('autoHeight');
    //this.gridOption.suppressCellSelection = true
  };
  dateFormatter = (props) => {
    return props.value != null
      ? getFormattedDateUtil({ dateString: props.value })
      : '';
  };
  handleOpenSnack = () => this.setState({ snackOpen: true });
  handleCloseSnack = () => this.setState({ snackOpen: false });
  sendMail = async () => {
    let mailForm = document.getElementById('sendForm');
    let formData = new FormData(mailForm);
    await axios
      .post('/sendemail', formData)
      .then((response) => {
        this.setState({ snackMsg: 'Test email sent successful' });
        this.setState({ snackType: 'bg-success' });
        this.setState({ snackOpen: true });
      })
      .catch((error) => {
        this.setState({
          snackMsg: 'Something went wrong, email not sent',
        });
        this.setState({ snackType: 'bg-danger' });
        this.setState({ snackOpen: true });
        console.log('ERROR:: ', error.response.data);
      });
  };
  emailSubmitDialog = () => {
    this.setState({ openDialog: true });
  };

  cancelDialog = () => {
    this.setState({ openDialog: false });
    this.setState({ snackMsg: 'Update cancelled!' });
    this.setState({ snackType: 'bg-info' });
    this.setState({ snackOpen: true });
  };

  updateDialog = () => {
    // validation
    this.setState({ openDialog: false });
    const values = this.state.values;
    let error = [];
    this.state.settings.map((prop) => {
      if (values[prop.id] === '') {
        error.push(prop.title);
      }
    });
    if (error.length > 0) {
      this.setState({
        snackMsg: 'Please fill the empty fields (' + error.join(', ') + ')!',
      });
      this.setState({ snackType: 'bg-danger' });
      this.setState({ snackOpen: true });
      return false;
    } else {
      this.update();
    }
  };
  update = async () => {
    // console.log(this.state.rteObj.value);

    let urlData = this.state.values;
    urlData.message = this.state.rteObj.value;
    const res = await axios.post('/saveEmailTemplate', urlData);
    if (res.data.status === 200) {
      const modTemplate = res.data.template;
      const templates = this.state.templates;
      if (res.data.mode === 'add') {
        templates.push(modTemplate);
        this.setState({ snackMsg: 'Add new template successfuly' });
      } else {
        const rowIndex = templates.findIndex(
          (obj) => obj.id === modTemplate.id
        );
        templates[rowIndex] = modTemplate;
        this.setState({ snackMsg: 'Update successful' });
      }
      this.setState({ templates: templates });
      this.gridApi.setRowData(this.state.templates);
      this.setState({ snackType: 'bg-success' });
      this.setState({ snackOpen: true });

      this.setState({ showModal: false });
    } else {
      this.setState({ snackMsg: 'Something went wrong!' });
      this.setState({ snackType: 'bg-danger' });
      this.setState({ snackOpen: true });
      console.log('ERROR:: ', error.response.data);
    }
  };
  handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const values = this.state.values;
    values[name] = value;
    this.setState({ values: values });
  };
  handleMailsendChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const values = this.state.mailsend;
    values[name] = value;
    this.setState({ mailsend: values });
  };
  fetchEmailTemplates = async () => {
    const url = '/fetchEmailTemplates';
    const res = await axios.get(url);
    if (res.data.status === 200) {
      this.setState({ templates: res.data.templates });
      this.setState({ loading: false });
    }
  };
  initTMeditor = (editor) => {
    editor.ui.registry.addMenuItem('ins_fullname', {
      text: '[FullName]',
      onAction: function () {
        editor.insertContent(' [FullName] ');
      },
    });

    this.state.tmeditor = editor;
  };

  componentDidMount() {
    this.fetchEmailTemplates();
  }

  deleteTemplateDialog = async (id) => {
    this.setState({ delTempId: id });
    this.setState({ openDelDialog: true });
  };

  deleteEmailTemplate = async () => {
    const res = await axios.get('/delEmailTemplate/' + this.state.delTempId);
    if (res.data.status === 200) {
      const templates = this.state.templates;
      const rowIndex = templates.findIndex(
        (obj) => obj.id === this.state.delTempId
      );
      templates.splice(rowIndex, 1);
      this.setState({ templates: templates });
      this.gridApi.setRowData(this.state.templates);

      this.setState({ snackMsg: 'Email Template Deleted' });
      this.setState({ snackType: 'bg-success' });
      this.setState({ snackOpen: true });
    } else {
      console.log('ERROR:: ', error.response.data);
    }
  };
  editTemplate = async (id) => {
    const res = await axios.get('/getEmailTemplate/' + id);
    if (res.data.status === 200) {
      this.setState({ formTitle: 'Edit - Email Template' });
      this.setState({ values: res.data.template });
      this.setState({ showModal: true });
    } else {
      console.log('ERROR:: ', error.response.data);
    }
  };
  addTemplate = () => {
    this.setState({ formTitle: 'Add New Template' });
    const values = {
      id: 0,
      alias: '',
      subject: '',
      message: '',
    };
    this.setState({ values: values });

    this.setState({ showModal: true });
  };

  render() {
    if (this.state.loading) {
      return (
        <div className="circProgress">
          <CircularProgress size={40} />
        </div>
      );
    }

    return (
      <Paper elevation={4}>
        <div>
          <Collapse in={!this.state.showModal}>
            <div className={'pageContainerHeader'}>
              <div>&nbsp;</div>
              <div>
                <Button
                  className="react-strap-button-primary"
                  color="primary"
                  onClick={this.addTemplate}
                  variant="contained"
                >
                  Add New Email Template
                </Button>
              </div>
            </div>
            <div
              className="ag-theme-material"
              id="templatesGrid"
              style={{ height: '73vh', width: '100%' }}
            >
              <AgGridReact
                columnDefs={this.state.columnDefs}
                defaultColDef={this.state.defaultColDef}
                frameworkComponents={this.state.frameworkComponents}
                context={{
                  editCall: (id) => this.editTemplate(id),
                  deleteCall: (id) => this.deleteTemplateDialog(id),
                }}
                onGridReady={this.onGridReady}
                rowData={this.state.templates}
                gridOptions={this.gridOptions}
              />
            </div>
          </Collapse>

          <Modal isOpen={this.state.showModal} style={customStyles}>
            <Box p={3} style={{ height: '78vh', width: '100%' }}>
              <div>
                <div className="emailTemplateheaderdiv">
                  <h4 className="header1">{this.state.formTitle}</h4>
                </div>
                <Form id="templateForm" className={'formContainer'}>
                  <Grid container justifyContent="flex-start" spacing={3}>
                    <Grid item xs={8}>
                      <CssTextField
                        label="Template Subject"
                        name="subject"
                        onChange={this.handleChange}
                        required
                        value={this.state.values.subject}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <CssTextField
                        disabled={
                          this.state.formTitle === 'Edit - Email Template'
                        }
                        label="Template Alias"
                        name="alias"
                        onChange={this.handleChange}
                        required
                        value={this.state.values.alias}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={8}>
                      <CssTextField
                        label="From"
                        name="custom_from_address"
                        onChange={this.handleChange}
                        type="email"
                        value={this.state.values?.custom_from_address ?? ''}
                        variant="outlined"
                      />
                      <p>
                        The From address must be authorised on Azure to be used
                        with your Mailbox
                      </p>
                    </Grid>
                    <Grid item xs={12}>
                      <div className="rte-control-section">
                        <RichTextEditorComponent
                          id="toolsRTE"
                          ref={(richtexteditor) => {
                            this.state.rteObj = richtexteditor;
                          }}
                          height="53vh"
                          enableHtmlEncode={true}
                          toolbarSettings={toolbarSettings}
                          quickToolbarSettings={quickToolbarSettings}
                        >
                          <div>{this.state.values.message}</div>
                          <Inject
                            services={[
                              Toolbar,
                              Image,
                              Link,
                              HtmlEditor,
                              Count,
                              QuickToolbar,
                              Table,
                            ]}
                          />
                        </RichTextEditorComponent>
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <hr className={'simple-line'} />
                      <div className={'mailsend-buttons'}>
                        <Button
                          type="button"
                          className="appSubmit"
                          color="danger"
                          onClick={() => {
                            this.setState({
                              showModal: false,
                            });
                          }}
                          variant="contained"
                        >
                          Cancel
                        </Button>
                        <Button
                          className="appSubmit react-strap-button-primary"
                          color="primary"
                          onClick={() => {
                            this.update();
                          }}
                          type="button"
                          variant="contained"
                        >
                          {this.state.formTitle === 'Add New Template'
                            ? 'Create'
                            : 'Update'}
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </Form>
              </div>
            </Box>
          </Modal>
          <Modal
            isOpen={this.state.showSendModal}
            style={customStyles}
            onRequestClose={() => {
              this.setState({ showSendModal: false });
            }}
          >
            <div style={{ width: '600px' }}>
              <Form id="sendForm" onSubmit={this.sendMail}>
                <Grid container justifyContent="flex-start" spacing={3}>
                  <Grid item xs={12}>
                    <h4>Send Test Email</h4>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <CssTextField
                      variant="outlined"
                      name="recipient"
                      label="Recipient Email Address"
                      onChange={this.handleMailsendChange}
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <CssTextField
                      variant="outlined"
                      name="message"
                      label="Email Content"
                      onChange={this.handleMailsendChange}
                      multiline={true}
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <hr className={'simple-line'} />
                    <div className={'mailsend-buttons'}>
                      <Button
                        type="submit"
                        className="appSubmit"
                        variant="contained"
                        color="danger"
                        onClick={() => {
                          this.setState({
                            showSendModal: false,
                          });
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        type="submit"
                        className="appSubmit"
                        variant="contained"
                        color="primary"
                      >
                        Send Email
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </Form>
            </div>
          </Modal>
          <Dialog
            aria-labelledby="submitConfirmation"
            maxWidth="xs"
            onClose={(event, reason) => {
              if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                this.cancelDialog();
              }
            }}
            open={this.state.openDialog}
          >
            <DialogTitle id="submitConfirmationDialog">
              Update Email SMTP Settings
            </DialogTitle>
            <DialogContent dividers>
              <p>Are you sure you want to change the settings?</p>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                color="danger"
                onClick={() => this.cancelDialog()}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => this.updateDialog()}
              >
                Update
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            aria-labelledby="deleteConfirmation"
            maxWidth="xs"
            onClose={(event, reason) => {
              console.log(reason);
              if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                this.cancelDialog();
              }
            }}
            open={this.state.openDelDialog}
          >
            <DialogTitle id="deleteConfirmationDialog">
              Delete Confirmation
            </DialogTitle>
            <DialogContent dividers>
              <p>
                Are you sure you want to delete the email template?
                <br />
                This is not an irrevocable operation
              </p>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                color="danger"
                onClick={() => {
                  this.setState({ delTempId: 0 });
                  this.setState({ openDelDialog: false });
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => this.deleteEmailTemplate(this.state.delTempId)}
              >
                Delete
              </Button>
            </DialogActions>
          </Dialog>
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={this.state.snackOpen}
            autoHideDuration={3000}
            onClose={this.handleCloseSnack}
            message={this.state.snackMsg}
            className={this.state.snackType}
            action={
              <React.Fragment>
                <IconButton
                  size="small"
                  aria-label="close"
                  color="inherit"
                  onClick={this.handleCloseSnack}
                >
                  <FaTimes />
                </IconButton>
              </React.Fragment>
            }
          />
        </div>
      </Paper>
    );
  }
}

export default EmailTemplates;
