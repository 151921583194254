import React from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteForeverSharpIcon from '@mui/icons-material/DeleteForeverSharp';
import { Tooltip } from '@mui/material';

const ViewDeleteLinkRenderer = (props) => {
  const handleViewClick = () => {
    props.context.viewDoc(props.data);
  };

  const handleDeleteClick = () => {
    props.context.deleteDoc(props.data);
  };

  return (
    <div>
      <Tooltip title="View Document">
        <span className={'tableIconButton'}>
          <VisibilityIcon onClick={handleViewClick} />
        </span>
      </Tooltip>
      <Tooltip title="Delete Document">
        <span className={'tableIconButton'}>
          <DeleteForeverSharpIcon onClick={handleDeleteClick} />
        </span>
      </Tooltip>
    </div>
  );
};

export default ViewDeleteLinkRenderer;
