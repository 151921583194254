import React from 'react';

import './radioGroup.scss';

const RadioGroup = ({ formState, errorFields, field, changeHandler }) => {
  return (
    <>
      <div
        dangerouslySetInnerHTML={{
          __html:
            field.text + (field.required || field.requiredRules ? ' *' : ''),
        }}
      ></div>
      <div className={`fs-radio-group-wrapper ${field.customClass}`}>
        {field.options.map((option) => {
          const checked = `${option.value}`;
          return (
            <div key={option.value} className={`radio-group-option`}>
              <input
                checked={formState[field.id]?.toString() === checked}
                className="fs-input"
                id={`${field.id} - ${option.value}`}
                name={field.id}
                placeholder={field.placeholder}
                type="radio"
                value={option.value}
                onChange={(e) => {
                  changeHandler(e, field);
                }}
              />
              <label
                className="radio-label"
                htmlFor={`${field.id} - ${option.value}`}
              >
                {field.isBoolean ? (option.value ? 'Yes' : 'No') : null}
                {!field.isBoolean && option.text}
              </label>
            </div>
          );
        })}
      </div>
      {errorFields.includes(field.id) && (
        <p className="input-error-message">{field.errorMessage}</p>
      )}
    </>
  );
};

export default RadioGroup;
