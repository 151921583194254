import React from 'react';
import Grid from '@material-ui/core/Grid';
import CssTextField from '../../../components/baseComponents/fsTextfield/textField';
import {
  getBooleanValueDisplayUtil,
  getFormattedDateUtil,
} from '../../../../utils/utils';

const StaticApplicantFormSection = ({ applicantData }) => {
  return (
    <>
      <Grid item xs={12} sm={12} md={3} lg={3}>
        <CssTextField
          fullWidth
          label="First Name"
          value={applicantData?.firstname || ''}
          InputProps={{
            readOnly: true,
          }}
          InputLabelProps={{ shrink: !!applicantData?.firstname }}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={3} lg={3}>
        <CssTextField
          fullWidth
          label={'Last Name'}
          value={applicantData?.surname || ''}
          InputProps={{
            readOnly: true,
          }}
          InputLabelProps={{ shrink: !!applicantData?.surname }}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={3} lg={3}>
        <CssTextField
          fullWidth
          label={'Date of Birth'}
          value={getFormattedDateUtil(
            {
              dateString: applicantData?.DOB,
            } || ''
          )}
          InputProps={{
            readOnly: true,
          }}
          InputLabelProps={{ shrink: !!applicantData?.DOB }}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={3} lg={3}>
        <CssTextField
          fullWidth
          label="Email"
          value={applicantData?.email || ''}
          InputProps={{
            readOnly: true,
          }}
          InputLabelProps={{ shrink: !!applicantData?.email }}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={3} lg={3}>
        <CssTextField
          fullWidth
          label="Phone number"
          value={applicantData?.phone || ''}
          InputProps={{
            readOnly: true,
          }}
          InputLabelProps={{ shrink: !!applicantData?.phone }}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={3} lg={3}>
        <CssTextField
          fullWidth
          label="Nationality"
          value={applicantData?.nationality || ''}
          InputProps={{
            readOnly: true,
          }}
          InputLabelProps={{ shrink: !!applicantData?.nationality }}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={3} lg={3}>
        <CssTextField
          fullWidth
          label="Address Line 1"
          value={applicantData?.addressLine1 || ''}
          InputProps={{
            readOnly: true,
          }}
          InputLabelProps={{ shrink: !!applicantData?.addressLine1 }}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={3} lg={3}>
        <CssTextField
          fullWidth
          label="Address Line 2"
          value={applicantData?.addressLine2 || ''}
          InputProps={{
            readOnly: true,
          }}
          InputLabelProps={{ shrink: !!applicantData?.addressLine2 }}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={3} lg={3}>
        <CssTextField
          fullWidth
          label="City"
          value={applicantData?.city || ''}
          InputProps={{
            readOnly: true,
          }}
          InputLabelProps={{ shrink: !!applicantData?.city }}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={3} lg={3}>
        <CssTextField
          fullWidth
          label="County"
          value={applicantData?.county || ''}
          InputProps={{
            readOnly: true,
          }}
          InputLabelProps={{ shrink: !!applicantData?.county }}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={3} lg={3}>
        <CssTextField
          fullWidth
          label="Eircode"
          value={applicantData?.eircode || ''}
          InputProps={{
            readOnly: true,
          }}
          InputLabelProps={{ shrink: !!applicantData?.eircode }}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={3} lg={3}>
        <CssTextField
          fullWidth
          label="Relationship Status"
          value={applicantData?.relStatus || ''}
          InputProps={{
            readOnly: true,
          }}
          InputLabelProps={{ shrink: !!applicantData?.relStatus }}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={3} lg={3}>
        <CssTextField
          fullWidth
          label="PPSN"
          value={applicantData?.PPSN || ''}
          InputProps={{
            readOnly: true,
          }}
          InputLabelProps={{ shrink: !!applicantData?.PPSN }}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={3} lg={3}>
        <CssTextField
          fullWidth
          label="Years at Current Address"
          value={applicantData?.residency || ''}
          InputProps={{
            readOnly: true,
          }}
          InputLabelProps={{ shrink: !!applicantData?.residency }}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={3} lg={3}>
        <CssTextField
          fullWidth
          label="Right to Reside In Ireland"
          value={getBooleanValueDisplayUtil(applicantData?.rightToReside) || ''}
          InputProps={{
            readOnly: true,
          }}
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={3} lg={3}>
        <CssTextField
          fullWidth
          label="Own or interest in a property"
          value={getBooleanValueDisplayUtil(applicantData?.ownProperty) || ''}
          InputProps={{
            readOnly: true,
          }}
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
      {applicantData?.ownProperty && (
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <CssTextField
            fullWidth
            label="Method of Qualification"
            value={applicantData?.qualifying || ''}
            InputProps={{
              readOnly: true,
            }}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
      )}
      <Grid item xs={12} sm={12} md={3} lg={3}>
        <CssTextField
          fullWidth
          label="Employment Status"
          value={applicantData?.employmentStatus || ''}
          InputProps={{
            readOnly: true,
          }}
          InputLabelProps={{ shrink: !!applicantData?.employmentStatus }}
        />
      </Grid>
    </>
  );
};

export default StaticApplicantFormSection;
