import React, { useState, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import StatusRenderer from '../../../components/baseComponents/fsStatusRenderer/StatusRenderer';
import linkRenderer from '../../../components/appStatusLinkRenderer';
import toggleRenderer from '../../../components/toggleRenderer';
import { Button, Form } from 'reactstrap';
import Modal from 'react-modal';
import { ColorPickerComponent } from '@syncfusion/ej2-react-inputs';
import EmailTemplateSelect from '../../../components/emailTemplateSelect';
import { SwitchComponent } from '@syncfusion/ej2-react-buttons';
import {
  Grid,
  Paper,
  CircularProgress,
  Snackbar,
  IconButton,
  Portal,
  Box,
} from '@material-ui/core';
import { FaTimes } from 'react-icons/fa';
import CssTextField from '../../../components/baseComponents/fsTextfield/textField';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import FsModal from '../../../components/baseComponents/fsModal/FsModal';

import './statusSettingsView.scss';

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '2em',
  },
};

const statusSettingsView = ({
  applicationStatuses,
  setApplicationStatuses,
  type,
}) => {
  const gridRef = useRef();
  const [editingStatus, setEditingStatus] = useState({});
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [isDeleteStatusWarningModalOpen, setIsDeleteStatusWarningModalOpen] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const [saveDisabled, setSaveDisabled] = useState(true);
  const [snackMsg, setSnackMsg] = useState('');
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackType, setSnackType] = useState('bg-success');

  const columnDefs = [
    {
      cellRenderer: 'statusRenderer',
      field: 'name',
      headerName: 'Status',
    },
    {
      field: 'description',
      flex: 3,
      headerName: 'Description',
    },
    {
      field: 'emailTemplate',
      headerName: 'Email Template',
    },
    {
      cellRenderer: 'toggleRenderer',
      field: 'autosend',
      headerName: 'Auto send email on change',
    },
    {
      cellRenderer: 'linkRenderer',
      field: 'empty',
      headerName: 'Action',
      pinned: 'right',
      width: 100,
    },
  ];
  const closeSnack = () => {
    setSnackOpen(false);
    setSnackMsg('');
    setSnackType();
  };

  const openForm = () => {
    setEditingStatus({});
    setEditModalOpen(true);
  };

  const editStatus = async (id) => {
    setEditingStatus(applicationStatuses.find((e) => e['id'] == id));
    setEditModalOpen(true);
  };

  const handleDeleteStatus = async () => {
    await axios
      .post('/deleteStatus', editingStatus)
      .then((response) => {
        if (response.data.error) {
          setSnackMsg(response.data.message);
          setSnackType('bg-danger');
        } else {
          setApplicationStatuses(response.data.list);
          setSnackMsg(response.data.message);
          setSnackType('bg-success');
        }
        setSnackOpen(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const deleteStatusWarningModalContent = (
    <div className="modal-text-content">
      {`Are you sure you want to delete the ${editingStatus.name} status?`}
    </div>
  );

  const handleEmailSelect = (event) => {
    editingStatus.email_template_id = event;
    setSaveDisabled(false);
  };
  const handleToggle = (e) => {
    editingStatus.autosend = e.checked;
    setSaveDisabled(false);
  };
  const handleSave = async () => {
    setLoading(true);
    editingStatus.type_name = type;
    if (editingStatus.id) {
      if (editingStatus.autosend && !editingStatus.email_template_id) {
        setSnackMsg(
          'Autosend cannot be set to true with no email template selected.'
        );
        setSnackType('bg-danger');
        setSnackOpen(true);
      } else {
        await axios
          .post('/updateStatus', editingStatus)
          .then((response) => {
            setApplicationStatuses(response.data.list);
            setEditModalOpen(false);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } else {
      if (!editingStatus.name || !editingStatus.description) {
        setSnackMsg('Name and Description fields are mandatory.');
        setSnackType('bg-danger');
        setSnackOpen(true);
      } else if (editingStatus.autosend && !editingStatus.email_template_id) {
        setSnackMsg(
          'Autosend cannot be set to true with no email template selected.'
        );
        setSnackType('bg-danger');
        setSnackOpen(true);
      } else {
        if (!editingStatus.autosend) {
          editingStatus.autosend = false;
        }
        await axios
          .post('/createStatus', editingStatus)
          .then((response) => {
            setApplicationStatuses(response.data.list);
            setEditModalOpen(false);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }

    setLoading(false);
    setSaveDisabled(true);
  };

  if (loading) {
    return (
      <div className="circProgress">
        <CircularProgress size={40} />
      </div>
    );
  }

  const gridOptions = {
    alwaysShowHorizontalScroll: false,
    alwaysShowVerticalScroll: false,
  };

  const nameField = () => {
    const nameTextField = (
      <CssTextField
        defaultValue={editingStatus.name}
        disabled={editingStatus.system_dependant}
        fullWidth
        label="Name"
        name="subject"
        onChange={(e) => {
          editingStatus.name = e.target.value;
          editingStatus.id
            ? (document.getElementById('preview').innerHTML = e.target.value)
            : null;
          setSaveDisabled(false);
        }}
        variant="outlined"
      />
    );

    return editingStatus.system_dependant ? (
      <TooltipComponent
        content={'Cannot Edit: System Dependant Field'}
        style={{ display: 'inline' }}
      >
        {nameTextField}
      </TooltipComponent>
    ) : (
      nameTextField
    );
  };
  return (
    <div className="status-settings-view-wrapper">
      <Paper className="separateHeader" elevation={4}>
        <div className="status-settings-view-container">
          <div className="status-settings-view-header-container">
            <div className="">
              <h2 className="header">{type} Status</h2>
            </div>
            <div>
              <Button
                className="react-strap-button-primary"
                color="primary"
                onClick={() => {
                  openForm();
                }}
                variant="contained"
              >
                Add new {type} status
              </Button>
            </div>
          </div>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <div
              className="ag-theme-material"
              style={{ height: '52vh', width: '100%' }}
            >
              <AgGridReact
                ref={gridRef}
                reactUi={true}
                rowData={applicationStatuses}
                defaultColDef={{
                  sortable: 'true',
                  editable: false,
                  cellStyle: { textAlign: 'left' },
                }}
                context={{
                  editStatus: (id) => editStatus(id),
                  deleteStatus: (id) => {
                    setIsDeleteStatusWarningModalOpen(true);
                    setEditingStatus(
                      applicationStatuses.find((e) => e['id'] == id)
                    );
                  },
                }}
                columnDefs={columnDefs}
                frameworkComponents={{
                  statusRenderer: StatusRenderer,
                  linkRenderer: linkRenderer,
                  toggleRenderer: toggleRenderer,
                }}
                gridOptions={gridOptions}
              ></AgGridReact>
            </div>
          </Grid>
        </div>
      </Paper>
      <Portal>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={snackOpen}
          autoHideDuration={2500}
          onClose={closeSnack}
          message={snackMsg}
          className={snackType}
          action={
            <>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={closeSnack}
              >
                <FaTimes />
              </IconButton>
            </>
          }
        />
      </Portal>

      <Modal
        style={modalStyles}
        isOpen={editModalOpen}
        onRequestClose={() => {
          setEditModalOpen(false);
        }}
      >
        <div className="status-settings-modal-header-container">
          {editingStatus.id && (
            <h2 className="header">
              Edit Status
              <StatusRenderer
                data={editingStatus}
                id="preview"
                value={editingStatus.name}
              />
            </h2>
          )}
          {!editingStatus.id && <h2 className="header">Create New Status</h2>}
        </div>
        <Box p={3} style={{ height: '45vh', width: '100%' }}>
          <div>
            <Form id="templateForm" className={'formContainer'}>
              <Grid container justifyContent="flex-start" spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  {nameField()}
                  <CssTextField
                    fullWidth
                    multiline
                    variant="outlined"
                    name="description"
                    label="Description"
                    defaultValue={editingStatus.description}
                    onChange={(e) => {
                      editingStatus.description = e.target.value;
                      setSaveDisabled(false);
                    }}
                  />
                </Grid>
                <Grid item xs={4} sm={4} md={4} lg={4}>
                  Background Colour
                  <br />
                  <ColorPickerComponent
                    modeSwitcher={false}
                    showButtons={false}
                    id={editingStatus.name}
                    value={
                      editingStatus.colour ? editingStatus.colour : '#fdcd51'
                    }
                    change={(e) => {
                      editingStatus.colour = e.currentValue.hex;
                      editingStatus.id
                        ? (document.getElementById(
                            'preview'
                          ).style.backgroundColor = e.currentValue.hex)
                        : null;
                      setSaveDisabled(false);
                    }}
                  ></ColorPickerComponent>
                </Grid>
                <Grid item xs={4} sm={4} md={4} lg={4}>
                  Associate with an email template
                  <br />
                  <EmailTemplateSelect
                    func={handleEmailSelect}
                    sel={editingStatus.email_template_id}
                  />
                </Grid>
                <Grid item xs={4} sm={4} md={4} lg={4}>
                  Autosend the email on status Change
                  <br />
                  <SwitchComponent
                    id="switch"
                    cssClass="handle-color"
                    checked={editingStatus.autosend}
                    change={(e) => {
                      handleToggle(e);
                      setSaveDisabled(false);
                    }}
                  />
                </Grid>
              </Grid>
            </Form>
          </div>
        </Box>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <div className={'mailsend-buttons'}>
            <Button
              className="react-strap-button-primary"
              color="primary"
              onClick={() => {
                setEditModalOpen(false);
              }}
              variant="contained"
            >
              {' '}
              Cancel{' '}
            </Button>

            <Button
              className="react-strap-button-primary"
              color="primary"
              disabled={saveDisabled}
              onClick={(e) => {
                handleSave();
                setEditModalOpen(false);
              }}
              variant="contained"
            >
              {' '}
              Save{' '}
            </Button>
          </div>
        </Grid>
      </Modal>

      {isDeleteStatusWarningModalOpen && (
        <FsModal
          content={deleteStatusWarningModalContent}
          handleClose={() => setIsDeleteStatusWarningModalOpen(false)}
          handleSave={async () => {
            await handleDeleteStatus();
            await setIsDeleteStatusWarningModalOpen(false);
          }}
          headerText="Are you sure you want to delete this Status?"
          isDanger={true}
          rightButtonClassName="danger"
          rightButtonText="Delete"
        />
      )}
    </div>
  );
};

export default statusSettingsView;
