import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';
import axios from 'axios';
import StatusSettingsView from './statusSettingsView';

function StatusSettings() {
  const [loading, setLoading] = useState(true);
  const [applicationStatuses, setApplicationStatuses] = useState([]);
  /*
    TODO
    Development and Document statuses removed in ticket https://dev.azure.com/bynaric/FairSelect/_workitems/edit/10430
    May be added back in the future 
  */
  //   const [DevelopmentStatuses, setDevelopmentStatuses] = useState({});
  //   const [DocumentStatuses, setDocumentStatuses] = useState({});

  useEffect(async () => {
    setLoading(true);
    await axios.post('/getStatuses', { type: 'Application' }).then((res) => {
      setApplicationStatuses(res.data.statuses);
    });
    /*
        TODO
        Development and Document statuses removed in ticket https://dev.azure.com/bynaric/FairSelect/_workitems/edit/10430
        May be added back in the future 
        */
    // await axios.post("/getStatuses",{'type':'Development'}).then((res) => {
    //         setDevelopmentStatuses(res.data.statuses);
    // });
    // await axios.post("/getStatuses",{'type':'Document'}).then((res) => {
    //         setDocumentStatuses(res.data.statuses);
    // });
    setLoading(false);
  }, []);

  if (loading) {
    return (
      <div className="circProgress">
        <CircularProgress size={40} />
      </div>
    );
  }
  return (
    <div>
      <div className={'topDiv'}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <StatusSettingsView
              applicationStatuses={applicationStatuses}
              setApplicationStatuses={setApplicationStatuses}
              type="Application"
            />
          </Grid>

          {/*
            TODO
            Development and Document statuses removed in ticket https://dev.azure.com/bynaric/FairSelect/_workitems/edit/10430
            May be added back in the future 
            */}
          {/* <Grid item xs={12} sm={12} md={12} lg={12}>
            <StatusSettingsView
              statuses={DevelopmentStatuses}
              type="Development"
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12}>
            <StatusSettingsView statuses={DocumentStatuses} type="Document" />
          </Grid> */}
        </Grid>
      </div>
    </div>
  );
}

export default StatusSettings;
