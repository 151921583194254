import React, { useState, useEffect, useRef } from 'react';
import useAxios from '../../hooks/useAxios';
import FsModal from '../baseComponents/fsModal/FsModal';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';

import FsRichTextEditor from '../baseComponents/richTextEditor/FsRichTextEditor';

import CssTextField from '../baseComponents/fsTextfield/textField';

import './emailApplicantModal.scss';

const EmailApplicantModal = ({
  handleCloseModal,
  applicantId,
  applicantData,
}) => {
  const editorRef = useRef();

  const { callAxios } = useAxios();
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [subject, setSubject] = useState('');

  // Need the default values here or the ref updating won't work on switching to 'none' for the template.
  const [selectedTemplate, setSelectedTemplate] = useState({
    label: 'None',
    created_at: '',
    custom_from_address: null,
    id: 6,
    body: '',
    subject: '',
    updated_at: '',
  });

  const sendEmail = async () => {
    const response = await callAxios('post', '/mail/send', {
      body: editorRef.current.value,
      subject: subject,
      applicant_ids: [applicantId],
    });
    if (response.status === 200) {
      handleClose();
    }
  };

  const handleClose = () => {
    handleCloseModal();
  };

  const handleTemplateChange = (e) => {
    setSelectedTemplate(e.target.value);
  };

  const handleChangeSubject = (e) => {
    setSubject(e.target.value);
  };

  useEffect(() => {
    editorRef.current.value = selectedTemplate.body;
    setSubject(selectedTemplate.subject);
  }, [selectedTemplate]);

  useEffect(() => {
    const fetchEmailTemplates = async () => {
      const response = await callAxios('get', '/mail/template');

      // Adding the no template option to the list. Needs all fields to work properly.
      const newTemplates = [
        {
          label: 'None',
          created_at: '',
          custom_from_address: '',
          id: '',
          body: '',
          subject: '',
          updated_at: '',
        },
      ];
      if (response.status === 200) {
        setTemplates([...newTemplates, ...response.data]);
      }
    };

    fetchEmailTemplates();
  }, []);

  const modalContent = (
    <div className="email-applicant-modal-content-wrapper">
      <div className="modal-input-wrapper">
        <div style={{ height: '80px', width: '300px' }}>
          <CssTextField
            fullWidth
            id={'recipient'}
            label={'recipient'}
            InputProps={{
              readOnly: true,
            }}
            size="medium"
            value={`${applicantData.firstname} ${applicantData.surname}`}
          />
        </div>

        <div className="template-select-input">
          <FormControl fullWidth variant="outlined">
            <InputLabel className="mui-select-label-custom" id={'template'}>
              Template
            </InputLabel>

            <Select
              className="fs-input mui-select-input-custom"
              fullWidth
              label={'Template'}
              labelId={'template'}
              placeholder={'Email Template'}
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
                getContentAnchorEl: null,
              }}
              id={'template'}
              onChange={(e) => {
                handleTemplateChange(e);
              }}
              required={true}
              size="medium"
              value={selectedTemplate}
            >
              {templates?.map((template) => {
                return (
                  <MenuItem key={template.label} value={template}>
                    {template.label}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>

        <div style={{ height: '80px', width: '300px' }}>
          <CssTextField
            fullWidth
            id={'subject'}
            label={'Subject'}
            onChange={(e) => handleChangeSubject(e)}
            required={true}
            size="medium"
            value={subject}
            variant="outlined"
          />
        </div>
      </div>

      <FsRichTextEditor ref={editorRef} />
    </div>
  );

  return (
    <FsModal
      content={modalContent}
      headerText="Email Sending Form"
      handleClose={handleClose}
      handleSave={sendEmail}
      isSaveButtonDisabled={isSaveButtonDisabled}
      leftButtonText="Cancel"
      rightButtonText="Send"
      hasCloseButton={false}
    />
  );
};

export default EmailApplicantModal;
