import React, { useEffect, useState } from 'react';
import Uploads from '../../views/UploadsApplicants';
import Navbar from './components/Navigation';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import Support from '../../pages/Support';
import AffordablePurchaseForm from '../../pages/affordablePurchaseFormPage/AffordablePurchaseForm';
import ApplicationsLandingPage from '../../pages/applicationsLandingPage/ApplicationsLandingPage';
import { SelectedDevelopmentContext } from '../../contexts/selectedDevelopmentStore';

function Mainapplicant() {
  const [selectedDevelopment, setSelectedDevelopment] = useState(null);
  let navigate = useNavigate();
  let location = useLocation();

  useEffect(() => {
    if (location.pathname === '/home') {
      navigate('/applicant/my-applications');
    }
  }, []);

  return (
    <div style={{ height: '100%' }}>
      <SelectedDevelopmentContext.Provider
        value={{ selectedDevelopment, setSelectedDevelopment }}
      >
        <Navbar />
        <div id="Main">
          <Routes>
            <Route
              index
              path="applicant/my-applications"
              element={<ApplicationsLandingPage />}
            />
            <Route
              index
              path="applicant/applicationForm/:id"
              element={<AffordablePurchaseForm />}
            />
            <Route path="/applicant/documents/:id" element={<Uploads />} />
            <Route path="/applicant/supportpage" element={<Support />} />
          </Routes>
        </div>
      </SelectedDevelopmentContext.Provider>
    </div>
  );
}

export default Mainapplicant;
