import React, {Component} from 'react';
import DeleteForeverSharpIcon from '@mui/icons-material/DeleteForeverSharp';
import VisibilityIcon from '@mui/icons-material/Visibility';

export default class VDlinkRenderer extends Component {
    render() {
        const rowId = this.props.data.id;
        return <div>
            <span className={"tableIconButton"}><VisibilityIcon onClick={() => {
                this.props.context.viewCall(rowId);
            }}/></span>
            <span className={"tableIconButton"}><DeleteForeverSharpIcon onClick={() => {
                this.props.context.deleteCall(rowId);
            }}/></span>
        </div>
    }
}
