import React from 'react';

import ActivityDetail from './activityDetail/ActivityDetail';

import './timeline.scss';

const Timeline = ({ activities, deleteActivity }) => {
  return (
    <div className="timeline-wrapper">
      {!activities.length && (
        <div className="no-activity-message">There is nothing to display</div>
      )}
      {activities.map((activity, index) => {
        return (
          <ActivityDetail
            activity={activity}
            deleteActivity={deleteActivity}
            key={index}
          />
        );
      })}
    </div>
  );
};

export default Timeline;
