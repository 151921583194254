import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import Layout from './layout/Layout';
import '../../css/app.css';
import { registerLicense } from '@syncfusion/ej2-base';
import { LicenseManager } from 'ag-grid-enterprise';
// Registering Syncfusion license key
registerLicense(
  'Mgo+DSMBaFt/QHRqVVhkVFpFdEBBXHxAd1p/VWJYdVt5flBPcDwsT3RfQF5jS35UdkxnXnpfcnZRRA==;Mgo+DSMBPh8sVXJ0S0J+XE9AflRDX3xKf0x/TGpQb19xflBPallYVBYiSV9jS31TdEVkWXZad3FURGRcUw==;ORg4AjUWIQA/Gnt2VVhkQlFacldJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxQdkdjXn9XdHNQR2JZWEY=;MTA4MTIwOEAzMjMwMmUzNDJlMzBoVFhXYmdGd0xPZ0k0a3Q0QVFpOE1qemZQcXV4djRSVnMxcGx5aksvTk84PQ==;MTA4MTIwOUAzMjMwMmUzNDJlMzBMMGtNNlhnMm9MMzRsbjdMYytFSDFRaDUyRHlIRllHNFF2dUlNS1NLRGZJPQ==;NRAiBiAaIQQuGjN/V0Z+WE9EaFtKVmJLYVB3WmpQdldgdVRMZVVbQX9PIiBoS35RdUVhWXheeXBSQ2FfUkZ0;MTA4MTIxMUAzMjMwMmUzNDJlMzBaNmRLWjA3VUl6UytiVUtDVjVIbUl2Mm9Dck5kMzYrSTBaekduN1VMNHlFPQ==;MTA4MTIxMkAzMjMwMmUzNDJlMzBDYzVLVXV2OWlFMXZOdmtaUVcrZGxqRXVmTnMwZk5kSkdySE41cUIrL0RFPQ==;Mgo+DSMBMAY9C3t2VVhkQlFacldJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxQdkdjXn9XdHNQR2RdVUc=;MTA4MTIxNEAzMjMwMmUzNDJlMzBlOVpKQWtJTkVVUXBiaklrdzNwak0rMnlaOERuMzRVckprb3BvU0VCVHdvPQ==;MTA4MTIxNUAzMjMwMmUzNDJlMzBDN0l5Qk1mVmQwQ2ZNcENBS0NtUW1ZZWFtMlNyUlZ0UDNEVjVLTmxDc0s4PQ==;MTA4MTIxNkAzMjMwMmUzNDJlMzBaNmRLWjA3VUl6UytiVUtDVjVIbUl2Mm9Dck5kMzYrSTBaekduN1VMNHlFPQ=='
);
const AG_GRID_LICENSE =
  'CompanyName=Bynaric,LicensedApplication=Bynaric,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-011426,ExpiryDate=27_October_2021_[v2]_MTYzNTI4OTIwMDAwMA==9d7571bff34e2d8b2620204016b8dbec';
LicenseManager.setLicenseKey(AG_GRID_LICENSE);

function App() {
  const [companySettings, setCompanySettings] = useState(null);
  const [isLoading, setLoading] = useState(true);

  useEffect(async () => {
    setLoading(true);
    await axios.get('/getCompanySettings').then((res) => {
      setCompanySettings(res.data.company_settings);
      document.documentElement.style.setProperty(
        '--Primary',
        res.data.company_settings.primaryColour
      );
      document.documentElement.style.setProperty(
        '--primaryact',
        res.data.company_settings.primaryActiveColour
      );
      document.documentElement.style.setProperty(
        '--primarydis',
        res.data.company_settings.primaryDisabledColour
      );
      document.documentElement.style.setProperty(
        '--primaryBackground',
        res.data.company_settings.primaryBackground
      );
      document.documentElement.style.setProperty(
        '--primaryShadow',
        res.data.company_settings.primaryShadow
      );
      document.documentElement.style.setProperty(
        '--secondaryBackground',
        res.data.company_settings.secondaryColour
      );
      setLoading(false);
    });
  }, []);
  if (isLoading) {
    return <div className="App">Loading...</div>;
  }
  return <Layout />;
}
export default App;

if (document.getElementById('root')) {
  ReactDOM.render(<App />, document.getElementById('root'));
}
