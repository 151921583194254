import React, { useState } from "react";

import "./fsAccordion.scss";

const FsAccordion = ({ headerContent, bodyContent }) => {
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);

  const handleHeaderClick = () => {
    setIsAccordionOpen(!isAccordionOpen);
  };
  return (
    <div
      className={`fs-accordion-wrapper ${isAccordionOpen && "open-accordion"}`}
    >
      <div className="fs-accordion-header" onClick={() => handleHeaderClick()}>
        <div className="header-content-wrapper">{headerContent}</div>

        <div className="fs-accordion-toggler-arrow-wrapper">
          <div className="accordion-toggler-arrow"></div>
        </div>
      </div>

      <div className="fs-accordion-content">{bodyContent}</div>
    </div>
  );
};

export default FsAccordion;
