import {
  DATE_FORMAT_REGEX,
  EIRCODE_REGEX,
  EMAIL_REGEX,
  PPSN_REGEX,
  IRISH_PHONE_REGEX,
} from '../../utils//regexUtils';

import { employedSchema } from './employedSchema';
import { selfEmployedSchema } from './selfEmployedSchema';
import { notEmployedSchema } from './notEmployedSchema';

const getRightToResideSchema = (isJoint) => {
  const targetField = isJoint ? 'jointApplicant_nationality' : 'nationality';

  const rightToResideId = isJoint
    ? 'jointApplicant_rightToReside'
    : 'rightToReside';
  const proofId = isJoint
    ? 'jointApplicant_proofOfRightToReside'
    : 'proofOfRightToReside';
  const rightToResideSchema = [
    {
      errorMessage: 'Field required',
      id: rightToResideId,
      checkIsVisible: (formState) => {
        if (formState[targetField] === 'Non-Irish / Non-EU / Non-EEA') {
          return true;
        }
        return false;
      },
      options: [
        { text: 'Yes', value: true },
        { text: 'No', value: false },
      ],
      isBoolean: true,
      requiredRules: (formState) => {
        if (
          formState[targetField] &&
          formState[targetField] === 'Non-Irish / Non-EU / Non-EEA'
        ) {
          return true;
        }
        return false;
      },
      text: 'Do you have the right to reside in Ireland?',
      type: 'radioGroup',
      tooltip: 'Proof of Right to Reside in Ireland for non-Irish/EU nationals',
    },
    {
      errorMessage: 'Document required',
      id: proofId,
      checkIsVisible: (formState) => {
        if (
          formState[targetField] === 'Non-Irish / Non-EU / Non-EEA' &&
          formState[rightToResideId]?.toString() === 'true'
        ) {
          return true;
        }
        return false;
      },
      name: 'Proof of Right to Reside in Ireland',
      requiredRules: (formState) => {
        if (
          formState[targetField] === 'Non-Irish / Non-EU / Non-EEA' &&
          formState[rightToResideId]?.toString() === 'true'
        ) {
          return true;
        }
        return false;
      },
      tooltip: 'Proof of Right to Reside in Ireland',
      type: 'uploader',
    },
  ];
  return rightToResideSchema;
};

const getEmploymentStatusSchema = (isJointApplication) => {
  const employmentStatusId = isJointApplication
    ? 'jointApplicant_employmentStatus'
    : 'employmentStatus';

  let schema = [
    {
      errorMessage: 'Field required',
      id: employmentStatusId,
      onChangeCheckFields: [
        ...employedSchema(isJointApplication),
        ...selfEmployedSchema(isJointApplication),
        ...notEmployedSchema(isJointApplication),
      ],
      options: [
        { text: 'Employed', value: 'Employed' },
        { text: 'Self Employed', value: 'Self Employed' },
        { text: 'Not Employed', value: 'Not Employed' },
      ],
      required: true,
      text: 'Employment Status',
      type: 'radioGroup',
      isBoolean: false,
    },
  ];

  schema = [
    ...schema,
    ...employedSchema(isJointApplication),
    ...selfEmployedSchema(isJointApplication),
    ...notEmployedSchema(isJointApplication),
  ];
  return schema;
};

const getIdSchema = (isJoint) => {
  const firstNameId = isJoint ? 'jointApplicant_firstName' : 'firstName';
  const lastNameId = isJoint ? 'jointApplicant_lastName' : 'lastName';
  const dateOfBirthId = isJoint ? 'jointApplicant_dateOfBirth' : 'dateOfBirth';
  const emailId = isJoint ? 'jointApplicant_email' : 'email';
  const nationalityId = isJoint ? 'jointApplicant_nationality' : 'nationality';
  const phoneId = isJoint ? 'jointApplicant_phone' : 'phone';
  const proofId = isJoint ? 'jointApplicant_proofOfId' : 'proofOfId';

  return [
    {
      id: firstNameId,
      placeholder: 'First Name',
      type: 'text',
      readOnly: !isJoint,
      required: true,
      errorMessage: 'Field required',
    },
    {
      id: lastNameId,
      placeholder: 'Last Name',
      type: 'text',
      readOnly: !isJoint,
      required: true,
      errorMessage: 'Field required',
    },
    {
      id: dateOfBirthId,
      placeholder: 'Date Of Birth',
      type: 'date',
      required: true,
      regex: DATE_FORMAT_REGEX,
      errorMessage: 'Field required',
    },
    {
      id: emailId,
      placeholder: 'Email',
      type: 'text',
      readOnly: !isJoint,
      required: true,
      regex: EMAIL_REGEX,
    },
    {
      errorMessage: 'Field required',
      id: nationalityId,
      label: 'Nationality',
      onChangeCheckFields: getRightToResideSchema(isJoint),
      options: [
        { value: 'Irish Citizen' },
        { value: 'EU/EEA Citizen' },
        { value: 'UK Citizen' },
        { value: 'Non-Irish / Non-EU / Non-EEA' },
      ],
      placeholder: 'Nationality',
      required: true,
      type: 'select',
    },
    {
      id: phoneId,
      placeholder: 'Phone number',
      readOnly: !isJoint,
      type: 'text',
      required: true,
      regex: IRISH_PHONE_REGEX,
    },

    {
      errorMessage: 'Document required',
      id: proofId,
      name: 'Upload proof of ID',
      required: true,
      tooltip: 'Photographic Identification',
      type: 'uploader',
    },
  ];
};

const getAddressSchema = (isJoint) => {
  const addressLine1Id = isJoint
    ? 'jointApplicant_addressLine1'
    : 'addressLine1';
  const addressLine2Id = isJoint
    ? 'jointApplicant_addressLine2'
    : 'addressLine2';
  const cityId = isJoint ? 'jointApplicant_city' : 'city';
  const countyId = isJoint ? 'jointApplicant_county' : 'county';
  const eircodeId = isJoint ? 'jointApplicant_eircode' : 'eircode';
  const proofId = isJoint ? 'jointApplicant_proofOfAddress' : 'proofOfAddress';

  return [
    {
      id: addressLine1Id,
      placeholder: 'Address Line 1',
      type: 'text',
      required: true,
      errorMessage: 'Field required',
    },
    {
      id: addressLine2Id,
      placeholder: 'Address Line 2',
      type: 'text',
      required: false,
    },
    {
      id: cityId,
      placeholder: 'City',
      type: 'text',
      required: true,
      errorMessage: 'Field required',
    },
    {
      id: countyId,
      label: 'County',
      type: 'select',
      options: [
        { value: 'Antrim' },
        { value: 'Armagh' },
        { value: 'Carlow' },
        { value: 'Cavan' },
        { value: 'Clare' },
        { value: 'Cork' },
        { value: 'Derry' },
        { value: 'Donegal' },
        { value: 'Down' },
        { value: 'Dublin' },
        { value: 'Fermanagh' },
        { value: 'Galway' },
        { value: 'Kerry' },
        { value: 'Kildare' },
        { value: 'Kilkenny' },
        { value: 'Laois' },
        { value: 'Leitrim' },
        { value: 'Limerick' },
        { value: 'Longford' },
        { value: 'Louth' },
        { value: 'Mayo' },
        { value: 'Meath' },
        { value: 'Monaghan' },
        { value: 'Offaly' },
        { value: 'Roscommon' },
        { value: 'Sligo' },
        { value: 'Tipperary' },
        { value: 'Tyrone' },
        { value: 'Waterford' },
        { value: 'Westmeath' },
        { value: 'Wexford' },
        { value: 'Wicklow' },
      ],
      required: true,
      errorMessage: 'Field required',
    },
    {
      id: eircodeId,
      placeholder: 'Eircode',
      type: 'text',
      required: true,
      errorMessage: 'Field required',
      regex: EIRCODE_REGEX,
      limit: 10,
    },
    {
      errorMessage: 'Document required',
      id: proofId,
      name: 'Upload proof of Address',
      required: true,
      tooltip: 'Proof of Present Address dated within the last 6 months',
      type: 'uploader',
    },
  ];
};

const getRelationshipStatusSchema = (isJoint) => {
  const targetField = isJoint
    ? 'jointApplicant_relationshipStatus'
    : 'relationshipStatus';
  const relationshipStatusSchema = [
    {
      errorMessage: 'Field required',
      id: targetField,
      label: 'Relationship Status',
      options: [
        { value: 'Single' },
        { value: 'Married' },
        { value: 'Separated or Divorced' },
        { value: 'Widowed' },
        { value: 'Civil Partnership' },
        { value: 'Cohabiting' },
      ],
      required: true,
      type: 'select',
    },
  ];
  return relationshipStatusSchema;
};

const getPpsnSchema = (isJoint) => {
  const ppsnId = isJoint ? 'jointApplicant_ppsn' : 'ppsn';
  const proofId = isJoint ? 'jointApplicant_proofOfPpsn' : 'proofOfPpsn';

  return [
    {
      id: ppsnId,
      type: 'text',
      placeholder: 'PPSN',
      required: true,
      errorMessage: 'Invalid PPSN ',
      regex: PPSN_REGEX,
    },
    {
      errorMessage: 'Document required',
      id: proofId,
      name: 'Upload proof of PPSN/Tax registration number',
      required: true,
      tooltip: 'Proof of PPSN/Tax Registration Number',
      type: 'uploader',
    },
  ];
};

const getResidencySchema = (formState, isJoint) => {
  const targetField = isJoint ? 'jointApplicant_residency' : 'residency';

  const countyField = isJoint ? 'jointApplicant_county' : 'county';

  const proofId = isJoint
    ? 'jointApplicant_proofOfResidency'
    : 'proofOfResidency';

  const residencyUploader = {
    errorMessage: 'Document required',
    id: proofId,
    checkIsVisible: (formState) => {
      if (formState[targetField] === 'More than 5') {
        return true;
      }
      return false;
    },
    name: 'Proof of being or having been resident in the administrative area of Meath County Council for a minimum period of 5 years.',
    requiredRules: (formState) => {
      if (
        formState[countyField] === 'Meath' &&
        formState[targetField] === 'More than 5'
      ) {
        return true;
      }
      return false;
    },
    tooltip:
      'Proof of being or having been resident in the administrative area of Meath County Council for a minimum period of 5 years for applicants to qualify under the 30% Residency Rule.',
    type: 'uploader',
  };
  let residencySchema = [];
  if (formState[countyField] === 'Meath') {
    residencySchema = [
      {
        id: targetField,
        type: 'radioGroup',
        text: 'How many years have you lived in County Meath?',
        onChangeCheckFields: [residencyUploader],
        options: [
          { text: 'More than 5 years', value: 'More than 5' },
          { text: 'Less than 5 years', value: 'Less than 5' },
        ],
        required: true,
        isBoolean: false,
        errorMessage: ' Field required',
      },
      residencyUploader,
    ];
  }
  return residencySchema;
};

const getAdditionalIncomeSchema = (isJoint) => {
  const anyOtherIncomeId = isJoint
    ? 'jointApplicant_anyOtherIncome'
    : 'anyOtherIncome';

  const otherIncomeListId = isJoint
    ? 'jointApplicant_otherIncomeList'
    : 'otherIncomeList';

  const additionalIncomeModal = {
    errorMessage: 'Additional Income Information is Required',
    id: otherIncomeListId,
    name: 'Upload proof of additional income',
    checkIsVisible: (formState) => {
      if (formState[anyOtherIncomeId]?.toString() === 'true') {
        return true;
      }
      return false;
    },
    columnDefs: [
      {
        field: 'otherIncomeType',
        filter: 'agTextColumnFilter',
        headerName: 'Type',
        sortable: 'true',
      },
      {
        field: 'otherIncomeAmount',
        filter: 'agTextColumnFilter',
        headerName: 'Amount',
        sortable: 'true',
        valueFormatter: ({ value }) => {
          return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'EUR',
          }).format(value);
        },
      },
      {
        field: 'frequencyOfOtherPayment',
        filter: 'agTextColumnFilter',
        headerName: 'Frequency',
        sortable: 'true',
      },
      {
        headerName: 'Action',
        cellRenderer: 'linkRenderer',
        width: 200,
        pinned: 'right',
      },
    ],
    modalDataId: otherIncomeListId,
    requiredRules: (formState) => {
      if (formState[anyOtherIncomeId]?.toString() === 'true') {
        return true;
      }
      return false;
    },
    tooltip: 'Proof of additional income',
    type: 'modal',
  };
  return [
    {
      id: anyOtherIncomeId,
      text: 'Do you have any other income',
      type: 'radioGroup',
      onChangeCheckFields: [additionalIncomeModal],
      options: [
        { text: 'Yes', value: true },
        { text: 'No', value: false },
      ],
      isBoolean: true,
      required: true,
      errorMessage: 'Field required',
    },
    additionalIncomeModal,
  ];
};

const getBuiltOrPurchasedSchema = (formState, isJoint) => {
  const builtOrPurchasedId = isJoint
    ? 'jointApplicant_builtOrPurchased'
    : 'builtOrPurchased';

  const qualifyingId = isJoint ? 'jointApplicant_qualifying' : 'qualifying';
  const freshStartProofId = isJoint
    ? 'jointApplicant_proofOfFreshStartPrinciple'
    : 'proofOfFreshStartPrinciple';

  const unsuitableAccommodationProofId = isJoint
    ? 'jointApplicant_proofOfUnsuitableAccommodation'
    : 'proofOfUnsuitableAccommodation';

  let layout = [
    {
      errorMessage: 'Field required',
      id: builtOrPurchasedId,
      options: [
        { text: 'Yes', value: true },
        { text: 'No', value: false },
      ],
      isBoolean: true,
      required: true,
      text: 'Have you built or purchased a property before or do you have an interest, right, claim or legal share in a property?',
      type: 'radioGroup',
    },
  ];

  layout = [
    ...layout,
    {
      errorMessage: 'Field required',
      id: qualifyingId,
      text: 'How do you qualify for the affordable purchase scheme?',
      type: 'radioGroup',
      customClass: 'large-radio-item-group',
      checkIsVisible: (formState) => {
        return formState[builtOrPurchasedId]?.toString() === 'true';
      },
      requiredRules: (formState) => {
        return formState[builtOrPurchasedId]?.toString() === 'true';
      },
      isBoolean: false,
      options: [
        'Fresh Start Principle',
        'Existing home owner in properties which because of their sizes, are not suited to the current accommodation needs of my household',
      ],
      options: [
        { text: 'Fresh Start Principle', value: 'Fresh Start Principle' },
        {
          text: 'Existing home owner in properties which because of their sizes, are not suited to the current accommodation needs of my household',
          value: 'Unsuitable Accommodation',
        },
      ],
    },
    {
      errorMessage: 'Document required',
      id:
        formState[qualifyingId] === 'Fresh Start Principle'
          ? freshStartProofId
          : unsuitableAccommodationProofId,
      checkIsVisible: (formState) => {
        return (
          formState[builtOrPurchasedId]?.toString() === 'true' &&
          formState[qualifyingId]
        );
      },
      name:
        formState[qualifyingId] === 'Fresh Start Principle'
          ? 'Proof of Fresh Start Principle'
          : 'Proof that current property size is not suitable',
      requiredRules: (formState) => {
        return (
          formState[builtOrPurchasedId]?.toString() === 'true' &&
          formState[qualifyingId]
        );
      },
      tooltip:
        formState[qualifyingId] === 'Fresh Start Principle'
          ? 'Proof of fresh start'
          : 'Proof of unsuitable property',
      type: 'uploader',
    },
  ];

  return layout;
};

export const applicantSchema = (formState, isJointApplication) => {
  return [
    getIdSchema(isJointApplication),
    getAddressSchema(isJointApplication),
    getRelationshipStatusSchema(isJointApplication),
    getPpsnSchema(isJointApplication),
    getResidencySchema(formState, isJointApplication),
    getRightToResideSchema(isJointApplication),
    getBuiltOrPurchasedSchema(formState, isJointApplication),
    getEmploymentStatusSchema(isJointApplication),
    getAdditionalIncomeSchema(isJointApplication),
  ];
};
