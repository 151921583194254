import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';
import PropertiesListView from '../components/DevelopmentManagement/PropertiesListView';

function PropertySettings() {
  return (
    <div>
      <div className={'topDiv'}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <PropertiesListView />
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default PropertySettings;
