import React, { useState, useEffect, createContext } from 'react';

const SessionExpiryContext = createContext();

const SessionExpiryStore = ({ children }) => {
  const [isExpiryModalOpen, setIsExpiryModalOpen] = useState(false);
  const [intervalId, setIntervalId] = useState(null);

  const handleLogout = () => {
    setIsExpiryModalOpen(false);
    document.getElementById('logout-applicant-form').submit();
  };

  const timerFunction = (callback, duration = 3600000) => {
    const start = Date.now();
    const intervalId = setInterval(() => {
      const elapsed = Date.now() - start;
      if (elapsed >= duration) {
        clearInterval(intervalId);
        callback();
      }
    }, 1000);
    return intervalId;
  };

  useEffect(() => {
    if (intervalId === null) {
      // If intervalId is null, start a new timer using timerFunction and store the intervalID
      const newIntervalId = timerFunction(() => setIsExpiryModalOpen(true));
      setIntervalId(newIntervalId);
    }

    // when the component unmounts or when intervalId changes(is reset)
    return () => clearInterval(intervalId);
  }, [intervalId]);

  const handleResetTimer = () => {
    clearInterval(intervalId);
    setIntervalId(null);
    setIsExpiryModalOpen(false);
  };

  return (
    <SessionExpiryContext.Provider
      value={{
        isExpiryModalOpen,
        setIsExpiryModalOpen,
        handleResetTimer,
        handleLogout,
      }}
    >
      {children}
    </SessionExpiryContext.Provider>
  );
};

export { SessionExpiryStore, SessionExpiryContext };
