import React, { useState, useEffect, useContext } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { affordablePurchaseFormSchema } from '../../../utils/affordablePurchase/affordablePurchaseFormSchema';
import {
  addErrorField,
  getFieldHasError,
  removeErrorField,
} from '../../../utils/affordablePurchase/formStateUtils.js';

import { transformApplicationData } from '../../../utils/affordablePurchase/dateTransforms';

import useAxios from '../../hooks/useAxios';

import FsWizard from '../../components/baseComponents/fsWizard/FsWizard.jsx';
import Overview from './steps/overview/Overview';
import Developments from './steps/developments/Developments';
import Households from './steps/households/Households';
import Financials from './steps/financials/Financials';
import Declarations from './steps/declarations/Declarations';
import ApplicationSubmitted from './applicationSubmitted/ApplicationSubmitted';
import IpsSquareLoader from '../../components/baseComponents/ipsSquareLoader/IpsSquareLoader.jsx';
import ConfirmationModal from './confirmationModal/ConfirmationModal';

import {
  FaCity,
  FaEuroSign,
  FaHouseUser,
  FaWpforms,
  FaTimes,
  FaClipboardList,
} from 'react-icons/fa';
import { IconButton, Portal, Snackbar } from '@material-ui/core';
import { SelectedDevelopmentContext } from '../../contexts/selectedDevelopmentStore';

import './affordablePurchaseForm.scss';
import { isCurrentDateBetween } from '../../../utils/utils';

const AffordablePurchaseForm = () => {
  const { isLoading, error, callAxios } = useAxios();
  const { id: developmentId } = useParams();
  const { setSelectedDevelopment } = useContext(SelectedDevelopmentContext);
  const navigate = useNavigate();

  const [formSchema, setFormSchema] = useState({
    agreements: [],
    eligibility: [],
    developments: [],
    mainApplicant: [],
    jointApplicant: [],
    financials: [],
    declarations: [],
  });
  const [formState, setFormState] = useState({
    isSubmissionComplete: false,
  });
  const [activeStep, setActiveStep] = useState(0);
  const [completedSteps, setCompletedSteps] = useState([]);
  const [errorFields, setErrorFields] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackMsg, setSnackMsg] = useState('');
  const [snackType, setSnackType] = useState('bg-success');
  const [doAllCheckSteps, setDoAllCheckSteps] = useState(false);
  const [companySettings, setCompanySettings] = useState({});
  const [hasFetchedData, setHasFetchedData] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  useEffect(() => {
    if (doAllCheckSteps) {
      setDoAllCheckSteps(false);
      handleCheckForCompleteSteps();
    }
  }, [doAllCheckSteps]);

  useEffect(() => {
    if (error) {
      handleAxiosError(error);
    }
  }, [error]);

  useEffect(() => {
    setFormSchema(affordablePurchaseFormSchema(formState, companySettings));
  }, [companySettings, formState]);

  useEffect(() => {
    handleCheckForCompleteSteps();
  }, [formSchema]);

  const fetchApplicationDetails = async () => {
    return await callAxios(
      'get',
      `/my-application/affordable-purchase/${developmentId}`
    );
  };

  const fetchApplicationSettings = async () => {
    return await callAxios('get', '/application-settings');
  };

  useEffect(() => {
    fetchData();
  }, []);

  const jumpToFirstIncompleteStep = () => {
    const firstUncompletedStep = allSteps.find(
      (step) => !completedSteps.includes(step.stepNumber)
    );
    setActiveStep(firstUncompletedStep?.stepNumber || 0);
  };

  useEffect(() => {
    setDoAllCheckSteps(true);
    jumpToFirstIncompleteStep();
  }, [hasFetchedData]);

  const allSteps = [
    { stepNumber: 0, schemaSections: ['eligibility', 'agreements'] },
    { stepNumber: 1, schemaSections: ['developments'] },
    {
      stepNumber: 2,
      schemaSections: formState.isJointApplication
        ? ['mainApplicant', 'jointApplicant']
        : ['mainApplicant'],
    },
    { stepNumber: 3, schemaSections: ['financials'] },
    { stepNumber: 4, schemaSections: ['declarations'] },
  ];

  const handleCheckboxToggle = (e, layout) => {
    setFormState((prevState) => {
      const field = layout.find((field) => field.id === e.target.id);
      const updatedState = { ...prevState, [e.target.id]: e.target.checked };
      if (field?.required && !e.target.checked) {
        setErrorFields((prevFields) => [...prevFields, e.target.id]);
      } else {
        setErrorFields((prevFields) =>
          prevFields.filter((field) => field !== e.target.id)
        );
      }
      return updatedState;
    });
  };

  const handleFormInputChange = (e, field) => {
    const fieldId = field.id;
    let updatedErrorFields = errorFields;
    let fieldHasError = getFieldHasError(field, e.target.value, formState); // Could make this just the needed fields?
    let updatedFormState = formState;

    if (
      (fieldId === 'employmentStatus' ||
        fieldId === 'jointApplicant_employmentStatus') &&
      field.onChangeCheckFields?.length
    ) {
      field.onChangeCheckFields.forEach((relatedField) => {
        updatedErrorFields = updatedErrorFields.filter(
          (errorField) => errorField !== relatedField.id
        );
        if (relatedField.type !== 'uploader') {
          updatedFormState = { ...updatedFormState, [relatedField.id]: '' };
        }
      });
    } else {
      if (field.onChangeCheckFields?.length) {
        field.onChangeCheckFields.forEach((relatedField) => {
          if (
            getFieldHasError(relatedField, formState[relatedField.id], {
              [fieldId]: e.target.value,
            })
          ) {
            updatedErrorFields = addErrorField(
              updatedErrorFields,
              relatedField.id
            );
          } else {
            updatedErrorFields = removeErrorField(
              updatedErrorFields,
              relatedField.id
            );
          }
        });
      }
    }
    if (fieldHasError) {
      updatedErrorFields = addErrorField(updatedErrorFields, fieldId);
    } else {
      updatedErrorFields = removeErrorField(updatedErrorFields, fieldId);
    }
    setErrorFields(updatedErrorFields);
    updatedFormState = { ...updatedFormState, [fieldId]: e.target.value };
    setFormState(updatedFormState);
  };

  const checkRequiredFields = () => {
    const keys = Object.keys(formSchema);
    let failedField = null;

    const allRequiredFieldsValid = keys.every((key) =>
      formSchema[key].every((field) =>
        Array.isArray(field)
          ? field.every((subField) => {
              if (
                getFieldHasError(subField, formState[subField.id], formState)
              ) {
                failedField = subField;
                return false;
              }
              return true;
            })
          : !getFieldHasError(field, formState[field.id], formState)
      )
    );

    /* Note: Worth keeping this as useful to uncomment to debug error cases in the form */
    // if (failedField) {
    //   console.log(
    //     'All required fields not valid. First failed field: ',
    //     failedField
    //   );
    // }

    return allRequiredFieldsValid;
  };

  const checkFieldForErrors = (updatedErrorFields, field) => {
    let fieldHasError = getFieldHasError(field, formState[field.id], formState);

    if (fieldHasError) {
      updatedErrorFields = addErrorField(updatedErrorFields, field.id);
    } else {
      updatedErrorFields = updatedErrorFields.filter(
        (errorField) => errorField !== field.id
      );
    }
    return updatedErrorFields;
  };

  //ToDo
  // Maybe we could allow this to only test specific fields?
  // Currently will check all fields as is only used when clicking the disabled submit
  const checkFieldsForErrors = () => {
    const updatedErrorFields = Object.values(formSchema).reduce(
      (acc, schemaStep) =>
        schemaStep.reduce((acc, field) => {
          if (Array.isArray(field)) {
            return field.reduce(
              (acc, subField) => checkFieldForErrors(acc, subField),
              acc
            );
          } else {
            return checkFieldForErrors(acc, field);
          }
        }, acc),
      errorFields
    );

    setErrorFields(updatedErrorFields);
  };

  const checkForCompleteSteps = () => {
    let completedStepsChanged = false;

    allSteps.forEach((step) => {
      const isSectionValid = step.schemaSections.every((section) => {
        if (formSchema[section]?.length === 0) {
          return false;
        }
        return formSchema[section].every((field) =>
          Array.isArray(field)
            ? field.every(
                (arrayField) =>
                  !getFieldHasError(
                    arrayField,
                    formState[arrayField.id],
                    formState
                  )
              )
            : !getFieldHasError(field, formState[field.id], formState)
        );
      });
      const stepNumberIndex = completedSteps.indexOf(step.stepNumber);

      if (isSectionValid && stepNumberIndex === -1) {
        completedSteps.push(step.stepNumber);
        completedStepsChanged = true;
        return true;
      } else if (!isSectionValid && stepNumberIndex !== -1) {
        completedSteps.splice(stepNumberIndex, 1);
        completedStepsChanged = true;
        return true;
      }

      return false;
    });

    if (completedStepsChanged) {
      setCompletedSteps([...completedSteps]);
    }
  };

  const closeSnack = () => {
    setSnackOpen(false);
    setSnackMsg('');
    setSnackType();
  };

  const handleUpload = (e) => {
    const targetField = e.target.id;
    const filelist = e.target.files;

    setFormState((prevState) => ({
      ...prevState,
      [targetField]: [...(prevState[targetField] || []), ...filelist],
    }));

    setErrorFields((prevErrorFields) =>
      prevErrorFields.filter((field) => field !== targetField)
    );
  };

  const getAffordablePurchaseFormContent = () => {
    if (formState.isSubmissionComplete) {
      return <ApplicationSubmitted applicationId={formState.id} />;
    } else {
      return (
        <>
          <FsWizard
            activeStep={activeStep}
            checkFieldsForErrors={checkFieldsForErrors}
            completedSteps={completedSteps}
            formState={formState}
            onSubmit={onSubmit}
            setActiveStep={setActiveStep}
            steps={steps}
            submitButtonDisabled={submitButtonDisabled}
            handleSave={handleSave}
          />

          {showConfirmationModal && (
            <ConfirmationModal
              setShowConfirmationModal={setShowConfirmationModal}
              handleSubmit={handleSubmit}
            />
          )}
        </>
      );
    }
  };

  const onSubmit = async () => {
    setShowConfirmationModal(true);
  };

  const handleSubmit = () => {
    setFormState({ ...formState, isSubmissionComplete: true });
    setIsSubmitted(true);
  };

  useEffect(() => {
    if (isSubmitted) handleSave();
  }, [isSubmitted]);

  const handleSave = async () => {
    try {
      const response = await callAxios(
        'put',
        '/application/affordable-purchase',
        formState
      );
      if (!response?.data || response.status !== 200) {
        setFormState({ ...formState, isSubmissionComplete: false });
        setIsSubmitted(false);

        if (response?.data?.errors) {
          setErrorFields(Object.keys(response.data.errors));
          handleAxiosError({
            message:
              'Could not save:\n' + Object.values(response.data.errors)[0],
          });
        }
        return;
      }
      // If we have not received data but without a catch, call was successful but there was some errors with the form returned

      let updatedFormState = response.data.data;
      updatedFormState = transformApplicationData(updatedFormState);
      setFormState(updatedFormState);
      return response;
    } catch (error) {
      setFormState({ ...formState, isSubmissionComplete: false });
      setIsSubmitted(false);
      handleAxiosError(error);
    }
  };

  const handleCheckForCompleteSteps = () => {
    checkForCompleteSteps();
    if (checkRequiredFields() && errorFields.length === 0) {
      setSubmitButtonDisabled(false);
    } else {
      setSubmitButtonDisabled(true);
    }
  };

  const handleAxiosError = (error) => {
    setSnackOpen(true);
    setSnackMsg(error.message);
    setSnackType('bg-danger');
  };

  const fetchData = async () => {
    try {
      const [application, settings] = await Promise.all([
        fetchApplicationDetails(),
        fetchApplicationSettings(),
      ]);
      const developmentInApplicationSettings = settings.data.developments.find(
        (development) => development.id.toString() === developmentId
      );
      if (
        !developmentInApplicationSettings ||
        !isCurrentDateBetween(
          developmentInApplicationSettings.openingDate,
          developmentInApplicationSettings.closingDate
        )
      ) {
        return navigate('/applicant/my-applications');
      }
      const updatedApplicationData = transformApplicationData(
        application.data.data
      );
      setFormState(updatedApplicationData);
      setCompanySettings(settings.data.companySettings);
      setSelectedDevelopment({
        id: developmentId,
        name: updatedApplicationData.developmentType,
      });
      setHasFetchedData(true);
    } catch (error) {
      console.log(error);
    }
  };

  const steps = [
    {
      name: 'Overview',
      component: (
        <Overview
          agreementsLayout={formSchema?.agreements}
          eligibilityLayout={formSchema?.eligibility}
          errorFields={errorFields}
          formState={formState}
          handleCheckboxToggle={handleCheckboxToggle}
          setErrorFields={setErrorFields}
          setFormState={setFormState}
        />
      ),
      icon: <FaClipboardList />,
    },
    {
      name: 'Developments',
      component: (
        <Developments
          errorFields={errorFields}
          formState={formState}
          layout={formSchema?.developments}
          setErrorFields={setErrorFields}
          setFormState={setFormState}
          developmentId={developmentId}
        />
      ),
      icon: <FaCity />,
    },
    {
      name: 'Households',
      component: (
        <Households
          errorFields={errorFields}
          formState={formState}
          handleChange={handleFormInputChange}
          handleSave={handleSave}
          handleUpload={handleUpload}
          mainLayout={formSchema?.mainApplicant}
          jointLayout={formSchema?.jointApplicant}
          setErrorFields={setErrorFields}
          setFormState={setFormState}
        />
      ),
      icon: <FaHouseUser />,
    },
    {
      name: 'Financials',
      component: (
        <Financials
          errorFields={errorFields}
          formState={formState}
          handleChange={handleFormInputChange}
          handleSave={handleSave}
          handleUpload={handleUpload}
          layout={formSchema?.financials}
          setErrorFields={setErrorFields}
          setFormState={setFormState}
        />
      ),
      icon: <FaEuroSign />,
    },
    {
      name: 'Declarations',
      component: (
        <Declarations
          errorFields={errorFields}
          formState={formState}
          handleCheckboxToggle={handleCheckboxToggle}
          layout={formSchema?.declarations}
          setErrorFields={setErrorFields}
        />
      ),
      icon: <FaWpforms />,
    },
  ];

  return (
    <>
      <>
        {getAffordablePurchaseFormContent()}
        {isLoading && <IpsSquareLoader />}

        <Portal>
          <Snackbar
            action={
              <>
                <IconButton
                  aria-label="close"
                  color="inherit"
                  onClick={closeSnack}
                  size="small"
                >
                  <FaTimes />
                </IconButton>
              </>
            }
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            autoHideDuration={2500}
            className={snackType}
            message={snackMsg}
            onClose={closeSnack}
            open={snackOpen}
          />
        </Portal>
      </>

      <Portal>
        <Snackbar
          action={
            <>
              <IconButton
                aria-label="close"
                color="inherit"
                onClick={closeSnack}
                size="small"
              >
                <FaTimes />
              </IconButton>
            </>
          }
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          autoHideDuration={2500}
          className={snackType}
          message={snackMsg}
          onClose={closeSnack}
          open={snackOpen}
        />
      </Portal>
    </>
  );
};

export default AffordablePurchaseForm;
