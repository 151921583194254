import { Paper } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { LicenseManager } from 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import linkRenderer from '../components/linkRenderer';
import StatusRenderer from '../components/baseComponents/fsStatusRenderer/StatusRenderer';

import 'ag-grid-enterprise';

const AG_GRID_LICENSE =
  'CompanyName=Bynaric,LicensedApplication=Bynaric,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-011426,ExpiryDate=27_October_2021_[v2]_MTYzNTI4OTIwMDAwMA==9d7571bff34e2d8b2620204016b8dbec';

LicenseManager.setLicenseKey(AG_GRID_LICENSE);

function Lottery() {
  const [list, setList] = useState([]);
  const gridRef = useRef();
  const [rowData, setRowData] = useState([]);
  const [paginationPageSize, setPaginationPageSize] = useState(100);
  const [saveChanged, setSaveChanged] = useState(true);
  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    axios.post('/lotterylist').then((response) => {
      setList(response.data.list);
    });
  }, []);

  const frameworkComponents = {
    linkRenderer: linkRenderer,
    statusRenderer: StatusRenderer,
  };

  const selectionChange = () => {
    setSelectedRows(gridRef.current.api.getSelectedRows());
  };

  const changeSaveButton = () => {
    setSaveChanged(false);
  };

  const dateFormatter = (params) => {
    return params.value != null
      ? getFormattedDateUtil({ dateString: params.value, includeTime: true })
      : '';
  };

  const onGridReady = async () => {
    const updateData = (data) => {
      setRowData(data);
    };
  };

  const columnDefs = [
    {
      field: 'development',
      headerName: 'Development',
      sortable: 'true',
      width: 70,
      filter: false,
      menuTabs: [],
    },
    {
      field: 'property',
      headerName: 'Property Type',
      sortable: 'true',
      filter: 'agSetColumnFilter',
    },
    {
      field: 'lottery_date',
      headerName: 'Lottery Date',
      sortable: 'true',
      width: 100,
      filter: 'agDateColumnFilter',
      valueFormatter: dateFormatter,
    },
    {
      field: 'lotteryLog',
      headerName: 'Lottery Log',
      sortable: 'true',
      filter: 'agNumberColumnFilter',
      width: 50,
    },
  ];

  return (
    <div>
      <Paper>
        <div className={'pageContainerHeader'}>
          <div>
            <h2 className="head">Lottery Logs</h2>
          </div>
          {/* <div>
                            <Button variant="contained" color="primary" onClick={() => this.onBtnExport()}>
                                <FaDownload/> </Button>
                            <Button variant="contained" color="primary" onClick={() => { this.lotteryForm() }}>
                                Run Lottery</Button>
                            <Button variant="contained" color="primary" onClick={() => { this.clearFilters() }}>
                                Clear Filters</Button>
                        </div> */}
        </div>
        <div
          className="ag-theme-material"
          style={{ height: '82vh', width: '100%' }}
        >
          <AgGridReact
            ref={gridRef}
            defaultColDef={{
              flex: 1,
              minWidth: 20,
              filter: true,
              menuTabs: ['filterMenuTab', 'columnsMenuTab'],
              resizable: true,
              suppressNavigable: true,
              editable: false,
              cellClass: 'no-border',
            }}
            rowSelection="multiple"
            pagination={true}
            columnDefs={columnDefs}
            onGridReady={onGridReady}
            rowData={rowData}
            paginationPageSize={paginationPageSize}
            frameworkComponents={frameworkComponents}
            onSelectionChanged={selectionChange}
            onFilterChanged={changeSaveButton}
            onSortChanged={changeSaveButton}
          ></AgGridReact>
        </div>
      </Paper>
    </div>
  );
}

export default Lottery;
