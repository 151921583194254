import { format, isValid, parse, parseISO } from 'date-fns';

export const getBooleanValueDisplayUtil = (booleanValue) => {
  if (booleanValue) return 'Yes';
  if (booleanValue === false || booleanValue === 0) return 'No';
  return '';
};

export const getDevelopmentPublishedStatusUtil = ({
  value,
  fieldId,
  FormState,
}) => {
  const todaysDate = new Date();
  const closingDate = dateStringToDateUtil(FormState.closingDate);
  const openingDate = dateStringToDateUtil(FormState.openingDate);

  if (fieldId === 'openingDate' && closingDate) {
    if (value < todaysDate && todaysDate < closingDate) return 'Published';
    else return 'Unpublished';
  } else if (fieldId === 'closingDate' && openingDate) {
    if (openingDate < todaysDate && todaysDate < value) return 'Published';
    else return 'Unpublished';
  }
  return 'Unpublished';
};

export const getFormattedDateUtil = ({ dateString, includeTime = false }) => {
  if (!dateString) {
    return '';
  }

  if (dateString instanceof Date) {
    if (includeTime) {
      return format(dateString, 'dd/MM/yyyy HH:mm');
    } else {
      return format(dateString, 'dd/MM/yyyy');
    }
  }

  const parsedDate = parseDateAnyFormat(dateString);
  let outputFormat = includeTime ? 'dd/MM/yyyy HH:mm' : 'dd/MM/yyyy';
  return format(parsedDate, outputFormat);
};

export function parseDateAnyFormat(dateString) {
  if (!dateString) {
    return null;
  }

  // Check if the date is in ISO format
  if (dateString.includes('T')) {
    return parseISO(dateString);
  }

  const formats = [
    'dd-MM-yyyy',
    'dd/MM/yyyy',
    'yyyy-MM-dd',
    'yyyy/MM/dd',
    'dd-MM-yyyy HH:mm',
    'dd/MM/yyyy HH:mm',
    'yyyy-MM-dd HH:mm',
    'yyyy/MM/dd HH:mm',
    'dd-MM-yyyy HH:mm:ss',
    'dd/MM/yyyy HH:mm:ss',
    'yyyy-MM-dd HH:mm:ss',
    'yyyy/MM/dd HH:mm:ss',
  ];

  for (const format of formats) {
    const parsedDate = parse(dateString, format, new Date(), { strict: false });
    if (isValid(parsedDate)) {
      return parsedDate;
    }
  }

  return null;
}

export const dateStringToDateUtil = (dateString) => {
  if (!dateString) {
    return null;
  }

  if (dateString instanceof Date) return dateString;

  const parsedDate = parseDateAnyFormat(dateString);
  return new Date(parsedDate);
};

export const formatMoney = (amount) => {
  const parsedAmount = parseFloat(amount);

  if (!isNaN(parsedAmount)) {
    if (Number.isInteger(parsedAmount)) {
      return parsedAmount.toLocaleString();
    } else {
      const formattedAmount = parsedAmount.toFixed(2).replace(/\.?0+$/, '');

      if (formattedAmount.endsWith('.00')) {
        return formattedAmount.replace(/\.00$/, '').toLocaleString();
      }

      return formattedAmount.toLocaleString();
    }
  }

  return amount;
};

export const getSyncFusionDatepickerClass = ({
  datepickerError,
  datepickerFormData,
}) => {
  if (!datepickerError)
    return 'e-outline e-bigger sync-fusion-datepicker-custom';

  if (!datepickerFormData)
    return 'e-outline e-bigger sync-fusion-datepicker-custom sync-fusion-datepicker-custom-empty-error';
  return 'e-outline e-bigger sync-fusion-datepicker-custom sync-fusion-datepicker-custom-filled-error';
};

export const isCurrentDateBetween = (openingDate, closingDate) => {
  const currentDateObj = new Date();
  const openingDateObj = new Date(openingDate);
  const closingDateObj = new Date(closingDate);

  return currentDateObj >= openingDateObj && currentDateObj <= closingDateObj;
};
export const isCurrentDateBefore = (openingDate) => {
  const currentDateObj = new Date();
  const openingDateObj = new Date(openingDate);

  return currentDateObj <= openingDateObj;
};
export const isCurrentDateAfter = (closingDate) => {
  const currentDateObj = new Date();
  const closingDateObj = new Date(closingDate);

  return currentDateObj >= closingDateObj;
};
