import React, { useState } from 'react';
import { CircularProgress, Paper } from '@material-ui/core';
import { TabComponent } from '@syncfusion/ej2-react-navigations';
import PropertySettings from './PropertySettings';
import TypeSettings from './TypeSettings';
import './developmentManagement.scss';
function DevManagement() {
  const [loading, setLoading] = useState(false);
  const headertext = [{ text: 'List' }, { text: 'Types' }];

  if (loading) {
    return (
      <div className="circProgress">
        <CircularProgress size={40} />
      </div>
    );
  }
  return (
    <div>
      <Paper className="separateHeader" elevation={4}>
        <TabComponent>
          <div className="e-tab-header primary development-management-tabs">
            <div> {headertext[0].text} </div>
            <div> {headertext[1].text} </div>
          </div>
          <div className="e-content">
            <div>
              <PropertySettings />
            </div>
            <div>
              <TypeSettings />
            </div>
          </div>
        </TabComponent>
      </Paper>
    </div>
  );
}
export default DevManagement;
