import React, { useState, useEffect, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import StatusRenderer from '../../components/baseComponents/fsStatusRenderer/StatusRenderer';
import linkRenderer from '../../components/EDlinkRenderer';
import toggleRenderer from '../../components/toggleRenderer';
import { Button } from 'reactstrap';
import { FaTimes } from 'react-icons/fa';
import DevelopmentWizard from './DevelopmentWizard';
import { IconButton, Paper, Portal, Snackbar } from '@material-ui/core';
import FsModal from '../../components/baseComponents/fsModal/FsModal';

const DEFAULT_DEVELOPMENT = {
  address: '',
  auto_status: false,
  closingDate: '',
  form_id: null,
  income: '',
  name: '',
  openingDate: '',
  pre_reg: false,
  properties: [],
  status_id: null,
  status: '',
  type_id: null,
  type: null,
};

const DevelopmentsView = ({
  columnDefs,
  developments,
  handleDeleteDevelopment,
  setDevelopments,
}) => {
  const gridRef = useRef();
  const [activeDevelopment, setActiveDevelopment] =
    useState(DEFAULT_DEVELOPMENT);
  const [isAddDevelopmentModalOpen, setIsAddDevelopmentModalOpen] =
    useState(false);
  const [snackMsg, setSnackMsg] = useState('');
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackType, setSnackType] = useState('bg-success');
  const [documentIdToDelete, setDocumentIdToDelete] = useState();
  const [isDeleteDevelopmentModalOpen, setIsDeleteDevelopmentModalOpen] =
    useState(false);

  const closeSnack = () => {
    setSnackOpen(false);
    setSnackMsg('');
    setSnackType();
  };

  const viewItem = async (id) => {
    setActiveDevelopment(developments.find((e) => e['id'] == id));
    setIsAddDevelopmentModalOpen(true);
  };

  const editItem = async (id) => {
    setActiveDevelopment(developments.find((e) => e['id'] == id));
    setIsAddDevelopmentModalOpen(true);
  };

  const deleteModalContent = (
    <div className="modal-text-content">
      {`Any data associated with the ${activeDevelopment.name} development will be lost. Applicants will no
      longer be able to apply for this development`}
    </div>
  );

  return (
    <>
      <Paper>
        <div className={'pageContainerHeader'}>
          <div>
            <h2 className="head">Developments</h2>
          </div>
          <div className="float-right">
            <Button
              className="react-strap-button-primary"
              variant="contained"
              color="primary"
              onClick={() => {
                setActiveDevelopment(DEFAULT_DEVELOPMENT);
                setIsAddDevelopmentModalOpen(true);
              }}
            >
              Add new Development
            </Button>
          </div>
        </div>
        <div
          className="ag-theme-material"
          style={{ height: '82vh', width: '100%' }}
        >
          <AgGridReact
            ref={gridRef}
            reactUi={true}
            rowData={developments}
            defaultColDef={{
              flex: 1,
              sortable: 'true',
              editable: false,
              cellStyle: { textAlign: 'left' },
            }}
            context={{
              viewCall: (id) => viewItem(id),
              editCall: (id) => editItem(id),
              deleteCall: (id) => {
                setActiveDevelopment(developments.find((e) => e['id'] == id));
                setDocumentIdToDelete(id);
                setIsDeleteDevelopmentModalOpen(true);
              },
            }}
            columnDefs={columnDefs}
            frameworkComponents={{
              statusRenderer: StatusRenderer,
              linkRenderer: linkRenderer,
              toggleRenderer: toggleRenderer,
            }}
          ></AgGridReact>
        </div>
        <Portal>
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={snackOpen}
            autoHideDuration={2500}
            onClose={closeSnack}
            message={snackMsg}
            className={snackType}
            action={
              <React.Fragment>
                <IconButton
                  size="small"
                  aria-label="close"
                  color="inherit"
                  onClick={closeSnack}
                >
                  <FaTimes />
                </IconButton>
              </React.Fragment>
            }
          />
        </Portal>
      </Paper>
      {isAddDevelopmentModalOpen && (
        <FsModal
          content={
            <DevelopmentWizard
              activeDevelopment={activeDevelopment}
              handleClose={() => setIsAddDevelopmentModalOpen(false)}
              setDevelopments={setDevelopments}
            />
          }
          fullScreen={true}
          handleClose={() => setIsAddDevelopmentModalOpen(false)}
          headerText="Development Setup Wizard"
        />
      )}
      {isDeleteDevelopmentModalOpen && (
        <FsModal
          content={deleteModalContent}
          handleClose={() => setIsDeleteDevelopmentModalOpen(false)}
          handleSave={async () => {
            await handleDeleteDevelopment(documentIdToDelete);
            await setIsDeleteDevelopmentModalOpen(false);
          }}
          headerText="Are you sure you want to delete this development?"
          isDanger={true}
          rightButtonClassName="danger"
          rightButtonText="Delete"
        />
      )}
    </>
  );
};

export default DevelopmentsView;
