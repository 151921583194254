import React, { useRef, useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import Modal from 'react-modal';
import { Button } from 'reactstrap';
import PropertySelect from '../../components/PropertySelect';
import { AgGridReact } from 'ag-grid-react';
import vdlinkRenderer from '../../components/VDlinkRenderer';
import PropertyViewModal from '../../components/DevelopmentManagement/properties/PropertyViewModal';

const PropertiesStep = ({ properties, setProperties }) => {
  const gridRef = useRef();

  const modalStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: '2em',
      zIndex: '1',
    },
  };

  const [openPropertySelect, setOpenPropertySelect] = useState(false);
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [activeProperty, setActiveProperty] = useState({});

  const columnDefs = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      rowDrag: true,
    },
    {
      field: 'bedrooms',
      headerName: 'No. of Beds',
      flex: 1,
    },
    {
      field: 'pivot.quantity',
      headerName: 'Quantity',
      flex: 1,
    },
    {
      field: 'property_type.name',
      headerName: 'Property Type',
      flex: 1,
    },
    {
      field: 'pivot.sort',
      headerName: 'Order',
      flex: 1,
    },
    {
      field: 'empty',
      headerName: 'Action',
      flex: 1,
      cellRenderer: 'vdlinkRenderer',
    },
  ];

  const updateRowSorting = (e) => {
    e.api.forEachNode((node, index) =>
      node.setDataValue('pivot.sort', index + 1)
    );
  };

  const handleAddProperty = (e) => {
    setProperties([...properties, e]);
    setOpenPropertySelect(false);
  };

  const viewProperty = async (id) => {
    setActiveProperty(properties.find((e) => e['id'] == id));
    setViewModalOpen(true);
  };

  const deleteProperty = async (id) => {
    const newProps = properties.filter((e) => e['id'] != id);
    setProperties(newProps);
  };

  return (
    <div className="width80">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Button
            className="btn-primary react-strap-button-primary"
            onClick={() => {
              setOpenPropertySelect(true);
            }}
            type="button"
          >
            Add Property
          </Button>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <div
            className="ag-theme-material"
            style={{
              height: '52vh',
              width: '100%',
            }}
          >
            <AgGridReact
              ref={gridRef}
              reactUi={true}
              rowData={properties}
              defaultColDef={{
                sortable: 'true',
                editable: false,
                cellStyle: {
                  textAlign: 'left',
                },
              }}
              context={{
                deleteCall: (id) => deleteProperty(id),
                viewCall: (id) => viewProperty(id),
              }}
              columnDefs={columnDefs}
              frameworkComponents={{
                vdlinkRenderer: vdlinkRenderer,
              }}
              gridOptions={{
                suppressMoveWhenRowDragging: false,
                onRowDragEnd: updateRowSorting,
              }}
              rowDragManaged={true}
            ></AgGridReact>
          </div>
        </Grid>
      </Grid>
      <Modal
        style={modalStyles}
        isOpen={openPropertySelect}
        onRequestClose={() => {
          setOpenPropertySelect(false);
        }}
      >
        <PropertySelect func={handleAddProperty} list={properties} />
      </Modal>
      {viewModalOpen && (
        <PropertyViewModal
          activeProperty={activeProperty}
          setShowViewModal={setViewModalOpen}
        />
      )}
    </div>
  );
};

export default PropertiesStep;
