import React, { useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import Developments from '../../pages/Developments';
import Development from '../../views/Development';
import Applicant from '../../views/Applicant';
import Settings from '../../pages/settings/Settings';
import Summary from '../../pages/Summary';
import Lottery from '../../pages/Lottery';
import Applications from '../../pages/Applications';
import BoldBiReporting from '../../views/BoldBiReporting';
import { Alert } from '@mui/material';
import useAxios from '../../hooks/useAxios';

function Main() {
  const { callAxios } = useAxios();
  const [mailboxAuthorised, setMailboxAuthorised] = useState(true);

  const checkMailAuthorization = async () => {
    return await callAxios('get', '/mail/check-authorization');
  };

  useEffect(async () => {
    const [mailAuthSettings] = await Promise.all([checkMailAuthorization()]);
    setMailboxAuthorised(mailAuthSettings.data.authorized);
  }, []);

  return (
    <>
      {!mailboxAuthorised && (
        <Alert
          severity="error"
          style={{ backgroundColor: 'rgb(253, 237, 237)' }}
        >
          A Mailbox needs to be authorised for the system to be able to continue
          sending emails.
        </Alert>
      )}
      <div id="Main" className={'mainDiv'}>
        <Routes>
          <Route path="/app/applications" element={<Applications />} />
          <Route path="/home" element={<Applications />} />
          <Route path="/app/developments" element={<Developments />} />
          <Route path="/app/settings" element={<Settings />} />
          <Route path="/app/reporting" element={<BoldBiReporting />} />
          <Route path="/app/lottery" element={<Lottery />} />
          <Route path="/app/applicant/:id?" element={<Applicant />} />
          <Route path="/app/development/:id?" element={<Development />} />
          <Route path="/app/summary/:id?" element={<Summary />} />
        </Routes>
      </div>
    </>
  );
}

export default Main;
