import React, { useState, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import linkRenderer from './ShowLinkRenderer';
import { Button } from 'reactstrap';
import Modal from 'react-modal';
import { Grid } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { Controller } from 'react-hook-form';
import CssTextField from './baseComponents/fsTextfield/textField';
import InputAdornment from '@mui/material/InputAdornment';
import { InputLabel, FormControl, Select, MenuItem } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import AddIcon from '@mui/icons-material/Add';
import '../pages/apForm.scss';
import { getFormattedDateUtil } from '../../utils/utils';
import FsButton from './baseComponents/fsButton/FsButton';

const customStyles = {
  content: {
    position: 'absolute',
    top: '30%',
    left: '10%',
    right: '10%',
    bottom: 'auto',
    padding: '2em',
  },
};

const useStyles = makeStyles(() =>
  createStyles({
    formControl: {
      // margin: theme.spacing(1),
      minWidth: 120,
    },
    label: {
      '&.Mui-focused': {
        color: 'var(--Primary)',
      },
    },
    select: {
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: 'var(--Primary)',
      },
    },
  })
);
const columnDefs = [
  {
    field: 'income_type',
    headerName: 'Income Type',
    sortable: 'true',
    filter: 'agTextColumnFilter',
  },
  {
    field: 'income_amount',
    headerName: 'Income Amount',
    sortable: 'true',
    filter: 'agTextColumnFilter',
  },
  {
    field: 'income_frequency',
    headerName: 'Frequency',
    sortable: 'true',
    filter: 'agSetColumnFilter',
  },
  {
    headerName: 'Action',
    cellRenderer: 'linkRenderer',
    width: 120,
    pinned: 'right',
  },
];

const IncomeList = (props) => {
  const classes = useStyles();
  const gridRef = useRef();
  const [list, setList] = useState(props.list ?? []);
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [editModalOpen, setEditModalOpen] = useState(false);
  const {
    register,
    getValues,
    setValue,
    formState: { errors },
    control,
    reset,
    trigger,
  } = useForm({
    mode: 'all',
    reValidateMode: 'onTouched',
  });

  const showOtherIncome = (id) => {
    list.forEach((e) => {
      if (e.id == id) return setSelectedItem(e);
    });
    setShowModal(true);
  };

  return (
    <div>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={10} sm={10} md={10} lg={9}>
          <div>
            <h2 className="Applic-text">Additional Income List</h2>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <div
            className="ag-theme-material"
            style={{ height: '52vh', width: '100%' }}
          >
            <AgGridReact
              ref={gridRef}
              reactUi={true}
              rowData={list}
              defaultColDef={{
                flex: 2,
                sortable: 'true',
                editable: false,
                cellStyle: { textAlign: 'left' },
              }}
              frameworkComponents={{
                linkRenderer: linkRenderer,
              }}
              context={{
                showCell: (id) => showOtherIncome(id),
              }}
              columnDefs={columnDefs}
            ></AgGridReact>
          </div>
        </Grid>
      </Grid>

      <Modal
        style={customStyles}
        isOpen={showModal}
        onRequestClose={() => {
          setShowModal(false);
        }}
      >
        <header className={`fs-modal-header mb-3`}>
          <h5>Additional Income</h5>
          <div className="close-modal-icon">
            <FsButton
              buttonText="Close"
              onClick={() => {
                setShowModal(false);
              }}
            />
          </div>
        </header>

        <Grid
          container
          spacing={2}
          justifyContent="center"
          alignItems="center"
          direction="row"
        >
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <CssTextField
              fullWidth
              id="outlined-read-only-input"
              label="Occupation"
              defaultValue={selectedItem?.other_income_occupation}
              InputProps={{
                readOnly: true,
              }}
              InputLabelProps={{
                shrink: !!selectedItem?.other_income_occupation,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <CssTextField
              fullWidth
              id="outlined-read-only-input"
              label="Date of Commencement"
              defaultValue={getFormattedDateUtil({
                dateString: selectedItem?.other_income_dateOfCommencement,
              })}
              InputProps={{
                readOnly: true,
              }}
              InputLabelProps={{
                shrink: !!selectedItem?.other_income_dateOfCommencement,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <CssTextField
              fullWidth
              id="outlined-read-only-input"
              label="Employment terms"
              defaultValue={selectedItem?.other_income_employmentTerms}
              InputProps={{
                readOnly: true,
              }}
              InputLabelProps={{
                shrink: !!selectedItem?.other_income_employmentTerms,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <CssTextField
              fullWidth
              id="outlined-read-only-input"
              label="Frequency of Payment"
              defaultValue={selectedItem?.income_frequency}
              InputProps={{
                readOnly: true,
              }}
              InputLabelProps={{ shrink: !!selectedItem?.income_frequency }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <CssTextField
              fullWidth
              id="outlined-read-only-input"
              label="Annual Gross Income"
              defaultValue={selectedItem?.other_income_grossIncome}
              InputProps={{
                readOnly: true,
              }}
              InputLabelProps={{
                shrink: !!selectedItem?.other_income_grossIncome,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <CssTextField
              fullWidth
              id="outlined-read-only-input"
              label="Overtime per annum"
              defaultValue={selectedItem?.other_income_overtime}
              InputProps={{
                readOnly: true,
              }}
              InputLabelProps={{
                shrink: !!selectedItem?.other_income_overtime,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <CssTextField
              fullWidth
              id="outlined-read-only-input"
              label="Bonus per Annum"
              defaultValue={selectedItem?.other_income_bonus}
              InputProps={{
                readOnly: true,
              }}
              InputLabelProps={{ shrink: !!selectedItem?.other_income_bonus }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <CssTextField
              fullWidth
              id="outlined-read-only-input"
              label="Commission per Annum"
              defaultValue={selectedItem?.other_income_commission}
              InputProps={{
                readOnly: true,
              }}
              InputLabelProps={{
                shrink: !!selectedItem?.other_income_commission,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <CssTextField
              fullWidth
              id="outlined-read-only-input"
              label="Employer Name"
              defaultValue={selectedItem?.other_income_employerName}
              InputProps={{
                readOnly: true,
              }}
              InputLabelProps={{
                shrink: !!selectedItem?.other_income_employerName,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <CssTextField
              fullWidth
              id="outlined-read-only-input"
              label="Employer Address Line 1"
              defaultValue={selectedItem?.other_income_employerAddressLine1}
              InputProps={{
                readOnly: true,
              }}
              InputLabelProps={{
                shrink: !!selectedItem?.other_income_employerAddressLine1,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <CssTextField
              fullWidth
              id="outlined-read-only-input"
              label="Employer Address Line 2"
              defaultValue={selectedItem?.other_income_employerAddressLine2}
              InputProps={{
                readOnly: true,
              }}
              InputLabelProps={{
                shrink: !!selectedItem?.other_income_employerAddressLine2,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <CssTextField
              fullWidth
              id="outlined-read-only-input"
              label="Employer City"
              defaultValue={selectedItem?.other_income_employerCity}
              InputProps={{
                readOnly: true,
              }}
              InputLabelProps={{
                shrink: !!selectedItem?.other_income_employerCity,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <CssTextField
              fullWidth
              id="outlined-read-only-input"
              label="Employer County"
              defaultValue={selectedItem?.other_income_employerCounty}
              InputProps={{
                readOnly: true,
              }}
              InputLabelProps={{
                shrink: !!selectedItem?.other_income_employerCounty,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <CssTextField
              fullWidth
              id="outlined-read-only-input"
              label="Employer Eircode"
              defaultValue={selectedItem?.other_income_employerEircode}
              InputProps={{
                readOnly: true,
              }}
              InputLabelProps={{
                shrink: !!selectedItem?.other_income_employerEircode,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <CssTextField
              fullWidth
              id="outlined-read-only-input"
              label="Type Of Business"
              defaultValue={selectedItem?.other_income_typeOfBusiness}
              InputProps={{
                readOnly: true,
              }}
              InputLabelProps={{
                shrink: !!selectedItem?.other_income_typeOfBusiness,
              }}
            />
          </Grid>
        </Grid>
      </Modal>
    </div>
  );
};

export default IncomeList;
