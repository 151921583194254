import React, { useState, useEffect, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import linkRenderer from './DeleteLinkRenderer';
import { Button } from 'reactstrap';
import Modal from 'react-modal';
import { Grid } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { Controller } from 'react-hook-form';
import CssTextField from './baseComponents/fsTextfield/textField';
import { InputLabel, FormControl, Select, MenuItem } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import AddIcon from '@mui/icons-material/Add';
import '../pages/apForm.scss';
import { format } from 'date-fns';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import {
  getFormattedDateUtil,
  getSyncFusionDatepickerClass,
} from '../../utils/utils';
import { PPSN_REGEX } from '../../utils/regexUtils';

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '2em',
  },
};
const useStyles = makeStyles(() =>
  createStyles({
    formControl: {
      // margin: theme.spacing(1),
      minWidth: 120,
    },
    label: {
      '&.Mui-focused': {
        color: 'var(--Primary)',
      },
    },
    select: {
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: 'var(--Primary)',
      },
    },
  })
);

const HouseholdList = ({
  list,
  setList,
  func,
  func1,
  disabled,
  hideAddButton,
}) => {
  const classes = useStyles();
  const gridRef = useRef();
  const [editModalOpen, setEditModalOpen] = useState(false);
  const {
    register,
    getValues,
    setValue,
    formState: { errors },
    control,
    trigger,
    reset,
  } = useForm({
    mode: 'all',
    reValidateMode: 'onTouched',
  });

  const [columnDefs, setColumnDefs] = useState([
    {
      field: 'first_name',
      headerName: 'First Name',
      sortable: 'true',
      filter: 'agTextColumnFilter',
    },
    {
      field: 'last_name',
      headerName: 'Last Name',
      sortable: 'true',
      filter: 'agTextColumnFilter',
    },
    {
      field: 'DOB',
      headerName: 'Date Of Birth',
      filter: 'agSetColumnFilter',
      valueGetter: (params) => {
        return getFormattedDateUtil({ dateString: params.data.DOB });
      },
    },
    {
      field: 'age',
      headerName: 'Age',
      sortable: 'true',
      filter: 'agTextColumnFilter',
    },
    {
      field: 'PPSN',
      headerName: 'PPSN',
      sortable: 'true',
      filter: 'agTextColumnFilter',
    },
    {
      field: 'relationship',
      headerName: 'Relationship to Applicant',
      sortable: 'true',
      filter: 'agTextColumnFilter',
    },
  ]);

  const deleteItem = (id) => {
    const Newlist = list.filter((e) => e['id'] != id);
    setList(Newlist);
    gridRef.current.api.setRowData(Newlist);
    func('householdMembers', Newlist);
    func1(Newlist);
  };
  const handleSave = (e) => {
    trigger();
    setValue('id', list.length);
    const Newlist = [...list, getValues()];
    setList(Newlist);
    gridRef.current.api.setRowData(list);
    func('householdMembers', Newlist);
    func1(Newlist);
    reset();
  };

  useEffect(() => {
    if (!hideAddButton) {
      setColumnDefs([
        ...columnDefs,
        {
          field: 'empty',
          headerName: 'Action',
          cellRenderer: 'linkRenderer',
        },
      ]);
    }
  }, [hideAddButton]);

  return (
    <div>
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        {!hideAddButton && (
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <div className="float-right">
              <Button
                variant="contained"
                disabled={disabled}
                className="form-navigation-button"
                onClick={() => {
                  reset();
                  setEditModalOpen(true);
                }}
              >
                <AddIcon />
              </Button>
            </div>
          </Grid>
        )}
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <div
            className="ag-theme-material"
            style={{ height: '52vh', width: '100%' }}
          >
            <AgGridReact
              ref={gridRef}
              reactUi={true}
              rowData={list}
              defaultColDef={{
                flex: 2,
                sortable: 'true',
                editable: false,
                cellStyle: { textAlign: 'left' },
              }}
              context={{
                deleteCall: (id) => deleteItem(id),
              }}
              columnDefs={columnDefs}
              frameworkComponents={{
                linkRenderer: linkRenderer,
              }}
            ></AgGridReact>
          </div>
        </Grid>
      </Grid>
      <Modal
        style={modalStyles}
        isOpen={editModalOpen}
        onRequestClose={() => {
          setEditModalOpen(false);
        }}
      >
        <div>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <h2 className="header text-center">
                Add an Additional Household Member
              </h2>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={4}>
                  <CssTextField
                    {...register('first_name', {
                      required: true,
                    })}
                    fullWidth
                    id="first_name"
                    label="First Name"
                    error={errors.first_name}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4}>
                  <CssTextField
                    {...register('last_name', {
                      required: true,
                    })}
                    fullWidth
                    id="last_name"
                    label="Last Name"
                    error={errors.last_name}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4}>
                  <DatePickerComponent
                    {...register('DOB', {
                      required: true,
                    })}
                    cssClass={getSyncFusionDatepickerClass({
                      datepickerError: errors.DOB,
                      datepickerFormData: getValues()?.DOB,
                    })}
                    error={errors.DOB != null}
                    floatLabelType="Auto"
                    id="DOB"
                    name="DOB"
                    format="dd/MM/yyyy"
                    max={new Date()}
                    placeholder="Date Of Birth"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4}>
                  <CssTextField
                    {...register('age', {
                      required: true,
                      maxLength: 2,
                      pattern: /[0-9]/,
                    })}
                    fullWidth
                    id="age"
                    label="Age"
                    error={errors.age}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4}>
                  <CssTextField
                    {...register('PPSN', {
                      required: true,
                      pattern: {
                        value: PPSN_REGEX,
                        message: 'Please enter a valid PPS Number ',
                      },
                    })}
                    fullWidth
                    id="PPSN"
                    label="PPS Number"
                    error={errors.PPSN != null}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4}>
                  <Controller
                    name="relationship"
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({ field }) => (
                      <FormControl
                        fullWidth
                        className={classes.formControl}
                        variant="outlined"
                      >
                        <InputLabel className={classes.label} id="relationship">
                          Relationship to Applicant
                        </InputLabel>
                        <Select
                          {...field}
                          labelId="relationship"
                          label="Relationship Status"
                          fullWidth
                          size="normal"
                          error={errors.relationship != null}
                          className={classes.select}
                        >
                          <MenuItem value="Son">Son</MenuItem>
                          <MenuItem value="Daughter">Daughter</MenuItem>
                          <MenuItem value="Mother">Mother</MenuItem>
                          <MenuItem value="Father">Father</MenuItem>
                          <MenuItem value="Sibling">Sibling</MenuItem>
                          <MenuItem value="Other">Other</MenuItem>
                        </Select>
                      </FormControl>
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <div className={'mailsend-buttons'}>
                <Button
                  variant="contained"
                  className="form-navigation-button"
                  onClick={() => {
                    setEditModalOpen(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  className="form-navigation-button"
                  onClick={(e) => {
                    if (
                      !errors.age &&
                      !errors.PPSN &&
                      !errors.relationship &&
                      !errors.DOB &&
                      !errors.first_name &&
                      !errors.last_name
                    ) {
                      handleSave();
                      setEditModalOpen(false);
                    }
                  }}
                >
                  Save
                </Button>
              </div>
            </Grid>
          </Grid>
        </div>
      </Modal>
    </div>
  );
};

export default HouseholdList;
