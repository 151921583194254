import React, {Component} from 'react';
import { SwitchComponent } from '@syncfusion/ej2-react-buttons';


export default class toggleRenderer extends Component {
    render() {
        const status = this.props.value;

        return <div> 
            <SwitchComponent id="switch" cssClass='handle-color' checked={status} disabled={true}/>
        </div>;
    }
}
