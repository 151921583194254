import React, { useState, useEffect, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import linkRenderer from './DeleteLinkRenderer';
import { Button } from 'reactstrap';
import Modal from 'react-modal';
import { Grid } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { Controller } from 'react-hook-form';
import CssTextField from './baseComponents/fsTextfield/textField';
import InputAdornment from '@mui/material/InputAdornment';
import { InputLabel, FormControl, Select, MenuItem } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import AddIcon from '@mui/icons-material/Add';
import '../pages/apForm.scss';
import Uploader from './Uploader';
import { FaExclamationCircle } from 'react-icons/fa';

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '2em',
  },
};
const useStyles = makeStyles(() =>
  createStyles({
    formControl: {
      // margin: theme.spacing(1),
      minWidth: 120,
    },
    label: {
      '&.Mui-focused': {
        color: 'var(--Primary)',
      },
    },
    select: {
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: 'var(--Primary)',
      },
    },
  })
);

const AccountsList = ({
  func,
  func1,
  joint,
  list,
  setList,
  hideAddButton,
  disabled,
  count,
}) => {
  const classes = useStyles();
  const gridRef = useRef();

  const [columnDefs, setColumnDefs] = useState([
    {
      field: 'account_name',
      headerName: 'Account Name',
      sortable: 'true',
      filter: 'agTextColumnFilter',
    },
    {
      field: 'type',
      headerName: 'Account Type',
      sortable: 'true',
      filter: 'agTextColumnFilter',
    },
    {
      field: 'owner',
      headerName: 'Account Owner',
      sortable: 'true',
      filter: 'agTextColumnFilter',
    },
    {
      field: 'balance',
      headerName: 'Balance',
      sortable: 'true',
      filter: 'agTextColumnFilter',
    },
    {
      field: 'financial_institute',
      headerName: 'Financial Institution',
      sortable: 'true',
      filter: 'agTextColumnFilter',
    },
  ]);

  const [hasUpload, setHasUpload] = useState(false);

  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editingItem, setEditingItem] = useState();
  const {
    register,
    getValues,
    setValue,
    formState: { errors },
    control,
    reset,
    trigger,
  } = useForm({
    mode: 'all',
    reValidateMode: 'onTouched',
  });

  const [accountType, setAccountType] = useState('');

  const handleUpload = () => {
    setHasUpload(true);
  };

  const deleteItem = (id) => {
    const NewList = list.filter((e) => e['id'] != id);
    setList(NewList);
    func('accounts', NewList);
    func1(NewList);
    gridRef.current.api.setRowData(list);
  };

  const handleTypeChange = (e) => {
    setAccountType(e.target.value);
  };

  const handleSave = (e) => {
    if (!joint) {
      setValue('owner', 'Main Applicant');
    }
    setValue('type', accountType ?? '');
    setValue('id', list.length);
    const Newlist = [...list, getValues()];
    setList(Newlist);
    gridRef.current.api.setRowData(Newlist);
    func('accounts', Newlist);
    func1(Newlist);
  };
  const uploadHandler = async (files, type) => {
    if (files.length > 0) {
      const fdata = new FormData();
      Array.from(files).forEach(function (file) {
        fdata.append('files[]', file);
      });
      fdata.append('type', type);

      await axios
        .post('/uploadDocument', fdata)
        .then((response) => {})
        .catch((error) => {
          alert('ERROR:: ' + error.response.data.message);
        });
    }
  };

  useEffect(() => {
    if (!hideAddButton) {
      setColumnDefs([
        ...columnDefs,
        {
          field: 'empty',
          headerName: 'Action',
          cellRenderer: 'linkRenderer',
        },
      ]);
    }
  }, [hideAddButton]);

  return (
    <div>
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        {!hideAddButton && (
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <div className="float-right">
              <Button
                disabled={disabled}
                variant="contained"
                className="form-navigation-button"
                onClick={() => {
                  reset();
                  setEditModalOpen(true);
                }}
              >
                <AddIcon />
              </Button>
            </div>
          </Grid>
        )}
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <div
            className="ag-theme-material"
            style={{ height: '52vh', width: '100%' }}
          >
            <AgGridReact
              ref={gridRef}
              reactUi={true}
              rowData={list}
              defaultColDef={{
                flex: 2,
                sortable: 'true',
                editable: false,
                cellStyle: { textAlign: 'left' },
              }}
              context={{
                deleteCall: (id) => deleteItem(id),
              }}
              columnDefs={columnDefs}
              frameworkComponents={{
                linkRenderer: linkRenderer,
              }}
            ></AgGridReact>
          </div>
        </Grid>
      </Grid>
      <Modal
        isOpen={editModalOpen}
        onRequestClose={() => {
          setEditModalOpen(false);
        }}
        style={modalStyles}
      >
        <div>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <h2 className="header text-center">Add Account</h2>
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={8}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <CssTextField
                    {...register('account_name', {
                      required: true,
                      maxLength: 20,
                    })}
                    error={errors.accountName != null}
                    fullWidth
                    id="account_name"
                    label="Account Name"
                    required
                    variant="outlined"
                  />
                </Grid>
                {joint && (
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Controller
                      control={control}
                      name="owner"
                      rules={{
                        required: true,
                      }}
                      render={({ field }) => (
                        <FormControl
                          fullWidth
                          className={classes.formControl}
                          variant="outlined"
                        >
                          <InputLabel className={classes.label} id="owner">
                            Which applicant owns the account?
                          </InputLabel>
                          <Select
                            {...field}
                            error={errors.accountOwner != null}
                            className={classes.select}
                            labelId="owner"
                            id="owner"
                            label="Account Owner"
                            fullWidth
                            size="normal"
                          >
                            <MenuItem value={'Main Applicant'}>
                              Main Applicant
                            </MenuItem>
                            <MenuItem value={'Joint Applicant'}>
                              Joint Applicant
                            </MenuItem>
                            <MenuItem value={'Both Applicants'}>
                              Both Applicants
                            </MenuItem>
                          </Select>
                        </FormControl>
                      )}
                    />
                  </Grid>
                )}
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Controller
                    name="type"
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({ field }) => (
                      <FormControl
                        fullWidth
                        className={classes.formControl}
                        variant="outlined"
                      >
                        <InputLabel className={classes.label} id="type">
                          What type of account is it?
                        </InputLabel>
                        <Select
                          {...field}
                          error={errors.accountType != null}
                          className={classes.select}
                          labelId="type"
                          id="type"
                          label="Account Type"
                          fullWidth
                          size="normal"
                          onChange={(e) => handleTypeChange(e)}
                        >
                          <MenuItem value={'Current Account'}>
                            Current Account
                          </MenuItem>
                          <MenuItem value={'Savings Account'}>
                            Savings Account
                          </MenuItem>
                        </Select>
                      </FormControl>
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <CssTextField
                    {...register('balance', {
                      required: true,
                      maxLength: 20,
                    })}
                    fullWidth
                    id="balance"
                    label="Balance"
                    error={errors.balance != null}
                    InputProps={{
                      pattern: '[0-9]*',
                      startAdornment: (
                        <InputAdornment position="start">€</InputAdornment>
                      ),
                    }}
                    variant="outlined"
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <CssTextField
                    {...register('financial_institute', {
                      required: true,
                      maxLength: 20,
                    })}
                    fullWidth
                    id="financial_institute"
                    label="Financial Institution"
                    error={errors.financialInstitution != null}
                    variant="outlined"
                    required
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <div className="center">
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item xs={12} md={12}>
                    <h6 className="Alt-head2">
                      Upload 6 months banks statements
                    </h6>
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <Uploader
                      count={count}
                      func={uploadHandler}
                      onUpload={handleUpload}
                      type={accountType}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <div className="tooltip-wrapper">
                      <label className="Alt-head2">More information</label>
                      <FaExclamationCircle />
                      <div className="tooltip-hover-list">
                        <ul className="tooltip-list">
                          <li>Bank Statements for the previous 6 months</li>
                        </ul>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <div className={'mailsend-buttons'}>
                <Button
                  variant="contained"
                  className="form-navigation-button"
                  onClick={() => {
                    setEditModalOpen(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  className="form-navigation-button"
                  disabled={!hasUpload}
                  onClick={(e) => {
                    if (
                      !errors.accountName &&
                      !errors.accountOwner &&
                      !errors.accountType &&
                      !errors.balance &&
                      !errors.financialInstitution
                    ) {
                      handleSave();
                      setEditModalOpen(false);
                    }
                  }}
                  variant="contained"
                >
                  Save
                </Button>
              </div>
            </Grid>
          </Grid>
        </div>
      </Modal>
    </div>
  );
};

export default AccountsList;
