import React, { useState, useEffect } from 'react';
import FsModal from '../baseComponents/fsModal/FsModal';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import useAxios from '../../hooks/useAxios';
import { Alert } from '@mui/material';

const getAssociatedApplicantsId = ({ applicationData, applicantType }) => {
  if (applicantType === 'main') {
    return applicationData.applicants.find(
      (applicant) => applicant.main_tenant === true
    ).id;
  } else if (applicantType === 'joint') {
    return applicationData.applicants.find(
      (applicant) => applicant.main_tenant === false
    ).id;
  }
};

const DocumentUploadModal = ({
  applicationData = {},
  closeUploadModal,
  getUpdatedDocuments,
  isJointApp,
  role = 'applicant',
}) => {
  const { id: developmentId } = useParams();
  const { callAxios } = useAxios();
  const [uploaderState, setUploaderState] = useState({
    development_id: developmentId,
    applicant_type: '',
    type: '',
    file: null,
  });
  const [documentTypes, setDocumentTypes] = useState([]);

  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);

  const handleChange = (e, targetField) => {
    setUploaderState({ ...uploaderState, [targetField]: e.target.value });
  };

  const handleUpload = (e) => {
    setUploaderState({ ...uploaderState, file: e.target.files[0] }); // Do we need to allow more than one?
  };

  const handleSave = async () => {
    const payloadUploadData = { ...uploaderState };
    if (role === 'admin') {
      const applicantId = getAssociatedApplicantsId({
        applicationData,
        applicantType: uploaderState.applicant_type,
      });
      payloadUploadData.applicant_id = applicantId;
      delete payloadUploadData.applicant_type;
      delete payloadUploadData.development_id;
    }
    const response = await callAxios('post', '/document', payloadUploadData);
    if (response.status === 200) {
      await getUpdatedDocuments();
      closeUploadModal();
    }
  };

  const hasFilledFields = () => {
    if (
      uploaderState.applicant_type.length &&
      uploaderState.type.length &&
      uploaderState.file
    ) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (hasFilledFields()) {
      setIsSaveButtonDisabled(false);
    }
  }, [uploaderState]);

  useEffect(() => {
    const getDocumentTypes = async () => {
      const response = await callAxios('get', 'document-type');
      if (response.data.types) {
        setDocumentTypes(response.data.types);
      }
    };

    getDocumentTypes();
  }, []);

  const modalContent = (
    <div className="document-uploader-modal-content-wrapper">
      {applicationData.status.name === 'Valid Application' && (
        <div style={{ marginBottom: '20px' }}>
          <Alert severity="warning">
            Uploading additional documents after submitting a Valid Application
            may affect your application completion date and time.
          </Alert>
        </div>
      )}
      <div style={{ height: '80px', width: '300px' }}>
        <FormControl fullWidth variant="outlined">
          <InputLabel className="mui-select-label-custom" id={'applicant_type'}>
            Applicant
          </InputLabel>

          <Select
            className="fs-input mui-select-input-custom"
            fullWidth
            label={'Applicant'}
            labelId={'applicant'}
            placeholder={'Applicant Type'}
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
            }}
            id="applicant"
            onChange={(e) => {
              handleChange(e, 'applicant_type');
            }}
            required={true}
            size="medium"
            value={uploaderState.applicant_type}
          >
            <MenuItem key={2} value={'main'}>
              Main
            </MenuItem>
            {isJointApp && (
              <MenuItem key={1} value={'joint'}>
                Joint
              </MenuItem>
            )}
          </Select>
        </FormControl>
      </div>

      <div style={{ height: '80px', width: '300px' }}>
        <FormControl fullWidth variant="outlined">
          <InputLabel className="mui-select-label-custom" id={'documentType'}>
            Document Type
          </InputLabel>

          <Select
            className="fs-input mui-select-input-custom"
            fullWidth
            label={'Document Type'}
            labelId={'type'}
            placeholder={'Document Type'}
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
            }}
            id={'type'}
            onChange={(e) => {
              handleChange(e, 'type');
            }}
            required={true}
            size="medium"
            value={uploaderState.type}
          >
            {documentTypes?.map((type) => {
              return (
                <MenuItem key={type.id} value={type.label}>
                  {type.label}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </div>

      <div className="uploader-container">
        <label className="uploader-input-label">
          {uploaderState.file ? 'Document Added' : 'Add Document'}
          <input
            accept="image/*;capture=camera"
            className="uploader-input"
            disabled={uploaderState.file}
            id={'document'}
            name="uploadFile"
            onChange={(e) => {
              handleUpload(e);
            }}
            type="file"
          />
        </label>
        {/* <div className="uploaded-file-counter">
          {Object.keys(uploaderState.file).length && (
            <span>document uploaded</span>
          )}
        </div> */}
      </div>
    </div>
  );

  return (
    <FsModal
      content={modalContent}
      handleClose={closeUploadModal}
      handleSave={handleSave}
      hasCloseButton={false}
      headerText="Upload a document"
      isSaveButtonDisabled={isSaveButtonDisabled}
      rightButtonText="Save"
    />
  );
};

export default DocumentUploadModal;
