import React from 'react'
import ApplicationsList from "../views/Applications";

function Applications() {
    return (
        <div>
            <ApplicationsList/>
        </div>
      );
}

export default Applications;
