import React, { useState, useEffect, useRef } from 'react';
import { Paper } from '@material-ui/core';
import { AgGridReact } from 'ag-grid-react';
import StatusRenderer from './baseComponents/fsStatusRenderer/StatusRenderer';
import linkRenderer from './documentLinkRenderer';
import FileViewer from 'react-file-viewer';
import Modal from 'react-modal';
import FsCard from './baseComponents/fsCard/FsCard';
import FsButton from './baseComponents/fsButton/FsButton';
import JSZip from 'jszip';
import DocumentUploadModal from './documentUploadModal/DocumentUploadModal';
import useAxios from '../hooks/useAxios';
import { getFormattedDateUtil } from '../../utils/utils';

import 'ag-grid-enterprise';
import './documentList.scss';

function DocumentsList({
  applicantData,
  applicationData,
  documents,
  documentCount,
  documentTypes,
  hideAddButton,
  getApplicationDetails,
}) {
  const gridRef = useRef();
  const { callAxios } = useAxios();

  const [filePath, setFilePath] = useState('');
  const [isMobile, setIsMobile] = useState(false);
  const [open, setOpen] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [type, setType] = useState();

  const CheckboxRenderer = ({ value, onChange }) => (
    <input type="checkbox" checked={value} onChange={onChange} />
  );

  const [rowData, setRowData] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [columnDefs, setColumnDefs] = useState([
    {
      checkboxSelection: true,
      headerCheckboxSelection: true,
      headerName: 'Name',
      flex: '2',
      sortable: 'true',
      filter: 'agTextColumnFilter',
      valueGetter: (props) => {
        const name = props.data.name;
        const parts = name?.split('-');
        if (parts.length > 3) {
          return parts.slice(3).join('_');
        } else {
          return parts[3];
        }
      },
    },
    {
      field: 'applicantType',
      headerName: 'Applicant',
      sortable: 'true',
      filter: 'agTextColumnFilter',
    },
    // {
    //   field: 'status',
    //   headerName: 'Status',
    //   sortable: 'true',
    //   filter: 'agTextColumnFilter',
    //   cellRenderer: 'statusRenderer',
    // },
    {
      field: 'type',
      headerName: 'Type',
      sortable: 'true',
      filter: 'agTextColumnFilter',
    },
    {
      field: 'updated_at',
      headerName: 'Uploaded On',
      flex: '2',
      sortable: 'true',
      filter: 'agSetColumnFilter',
      valueGetter: (params) => {
        return getFormattedDateUtil({
          dateString: params.data.updated_at,
          includeTime: true,
        });
      },
    },
    {
      field: 'viewed',
      headerName: 'Viewed',
      sortable: 'true',
      hide: true,
      suppressToolPanel: true,
    },
  ]);

  useEffect(() => {
    setRowData(documents);
  }, [documents]);

  const deleteDoc = async (doc) => {
    try {
      await callAxios('delete', `/document/${doc.id}`);
      await getApplicationDetails();
      gridRef.current.api.deselectAll();
    } catch (error) {
      console.error('Failed to delete: ', error.message);
    }
  };

  const deleteSelectedDocs = () => {
    let selectedDocumentIds = [];
    const documentDeletePromises = selectedRows.map((document) => {
      selectedDocumentIds.push(document.id);
      return callAxios('delete', `/document/${document.id}`);
    });
    Promise.all(documentDeletePromises).then(async () => {
      await getApplicationDetails();
      gridRef.current.api.deselectAll();
    });
  };

  function getDownloadName(doc) {
    const name = doc.name;
    const parts = name.split('-');
    let downloadName = parts[3];
    if (parts.length > 3) {
      downloadName = parts.slice(3).join('-');
    }
    downloadName = downloadName.slice(0, downloadName.lastIndexOf('.'));
    return applicationData.id + '_' + downloadName;
  }

  const downloadSelectedDocs = () => {
    const zip = new JSZip();
    const documentPromises = selectedRows.map((document) => {
      return fetch(document.path)
        .then((response) => response.blob())
        .then((blob) => {
          zip.file(document.name, blob);
          return true;
        });
    });

    Promise.all(documentPromises).then(() => {
      return zip
        .generateAsync({ type: 'blob' })
        .then((zipBlob) => {
          const zipUrl = window.URL.createObjectURL(zipBlob);
          const a = document.createElement('a');
          a.href = zipUrl;
          a.download = `${applicantData.firstname}_${applicantData.surname}_${applicationData.development?.name}_${applicationData.id}.zip`;

          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);

          // revoke the URL object to free up memory
          window.URL.revokeObjectURL(zipUrl);
        })
        .catch((error) => console.error(error));
    });
  };

  const downloadAllDocs = async () => {
    const downloadUrl = `/downloadDocs/${applicationData.id}`;

    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = downloadUrl;
    a.download = `${applicantData.firstname}_${applicantData.surname}_${applicationData.development?.name}_${applicationData.id}.zip`;
    document.body.appendChild(a);

    a.click();
    document.body.removeChild(a);
  };

  const viewDoc = (doc, node) => {
    setFilePath(doc.path);
    setType(doc.ext?.toLowerCase());
    setOpen(true);
    node.setDataValue('viewed', true);
  };

  const getCardContent = ({ type, count }) => {
    return (
      <div className="document-info-card">
        <span className="document-info-card-field">{type}</span>
        <span className="document-info-card-value">{count}</span>
      </div>
    );
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (isMobile) {
      setColumnDefs([
        {
          checkboxSelection: true,
          headerCheckboxSelection: true,
          headerName: 'Name',
          flex: '2',
          sortable: 'true',
          filter: 'agTextColumnFilter',
          pinned: 'left',
          valueGetter: (props) => {
            const name = props.data.name;
            const parts = name?.split('-');
            if (parts.length > 3) {
              return parts.slice(3).join('-');
            } else {
              return parts[3];
            }
          },
        },
        {
          field: 'applicantType',
          headerName: 'Applicant',
          sortable: 'true',
          filter: 'agTextColumnFilter',
        },
        // {
        //   field: 'status',
        //   headerName: 'Status',
        //   sortable: 'true',
        //   filter: 'agTextColumnFilter',
        //   cellRenderer: 'statusRenderer',
        // },
      ]);
    } else {
      setColumnDefs([
        {
          checkboxSelection: true,
          headerCheckboxSelection: true,
          headerName: 'Name',
          flex: '2',
          pinned: 'left',
          sortable: 'true',
          filter: 'agTextColumnFilter',
          valueGetter: (props) => {
            const name = props.data.name;
            const parts = name?.split('-');
            if (parts.length > 3) {
              return parts.slice(3).join('-');
            } else {
              return parts[3];
            }
          },
        },

        {
          field: 'type',
          headerName: 'Type',
          sortable: 'true',
          filter: 'agTextColumnFilter',
        },
        {
          field: 'applicantType',
          headerName: 'Applicant',
          sortable: 'true',
          filter: 'agTextColumnFilter',
        },
        // {
        //   field: 'status',
        //   headerName: 'Status',
        //   sortable: 'true',
        //   filter: 'agTextColumnFilter',
        //   cellRenderer: 'statusRenderer',
        // },
        {
          field: 'updated_at',
          headerName: 'Uploaded On',
          flex: '2',
          sortable: 'true',
          filter: 'agSetColumnFilter',
          valueGetter: (params) => {
            return getFormattedDateUtil({
              dateString: params.data.updated_at,
              includeTime: true,
            });
          },
        },
      ]);
    }
  }, [isMobile]);

  useEffect(() => {
    if (!hideAddButton) {
      setColumnDefs([
        ...columnDefs,
        {
          field: 'empty',
          headerName: 'Action',
          cellRenderer: 'linkRenderer',
          pinned: 'right',
          width: 160,
        },
      ]);
    }
  }, [hideAddButton]);

  const handleCloseClick = () => {
    setOpen(false);
  };

  const downloadDoc = (doc) => {
    const downloadName = getDownloadName(doc);

    fetch(doc.path)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const a = document.createElement('a');
        a.href = url;
        a.download = downloadName + '.' + doc.ext;
        document.body.appendChild(a);
        a.click();

        setTimeout(() => {
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        }, 0);
      })

      .catch((error) => console.error(error));
  };

  const onSelectionChanged = () => {
    const selectedNodes = gridRef.current.api.getSelectedNodes();
    const selectedData = selectedNodes.map((node) => node.data);
    setSelectedRows(selectedData);
  };

  const handleClickUpload = () => {
    setShowUploadModal(true);
  };

  const closeUploadModal = () => {
    setShowUploadModal(false);
  };

  return (
    <>
      <Paper elevation={4}>
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
          }}
        >
          <div style={{ marginRight: 'auto', padding: '1.5em' }}>
            <h2 className="header" style={{ marginBottom: 0 }}>
              Documents Uploaded
            </h2>
          </div>
          {/* Only show the download button if any documents exist */}
          <div className="fs-ag-grid-header-buttons-container">
            {documents?.length > 0 && (
              <>
                <div className="fs-ag-grid-header-button">
                  <FsButton
                    buttonText="Delete Selected"
                    className="danger"
                    disabled={!selectedRows.length > 0}
                    onClick={() => deleteSelectedDocs()}
                  />
                </div>
                <div className="fs-ag-grid-header-button">
                  <FsButton
                    buttonText="Download Selected"
                    disabled={!selectedRows.length > 0}
                    onClick={() => downloadSelectedDocs()}
                  />
                </div>
                <div className="fs-ag-grid-header-button">
                  <FsButton
                    buttonText="Download All"
                    onClick={() => downloadAllDocs()}
                  />
                </div>
              </>
            )}
            <div className="fs-ag-grid-header-button">
              <FsButton buttonText="Upload" onClick={handleClickUpload} />
            </div>
          </div>
        </div>
        <div className="document-info-total-documents">
          Total Documents: {documentCount}
        </div>
        <div>
          <div className="document-info-cards-wrapper">
            {documentTypes.map((documentType, index) => {
              const cardContent = getCardContent(documentType);

              return (
                <FsCard
                  content={cardContent}
                  className="document-info-cards"
                  key={index}
                />
              );
            })}
          </div>
        </div>
        <div
          className="ag-theme-material"
          style={{ height: 300, width: '100%' }}
        >
          <AgGridReact
            ref={gridRef}
            reactUi={true}
            columnDefs={columnDefs}
            onSelectionChanged={onSelectionChanged}
            rowData={rowData}
            rowSelection={'multiple'}
            suppressRowClickSelection={true}
            gridOptions={{
              rowClassRules: {
                'document-list-row-viewed': function (params) {
                  return params.data.viewed == true;
                },
              },
            }}
            frameworkComponents={{
              checkboxRenderer: CheckboxRenderer,
              linkRenderer: linkRenderer,
              statusRenderer: StatusRenderer,
            }}
            context={{
              viewDoc: (doc, node) => viewDoc(doc, node),
              deleteDoc: (doc) => deleteDoc(doc),
              downloadDoc: (doc) => downloadDoc(doc),
            }}
          ></AgGridReact>
        </div>
        <Modal
          isOpen={open}
          onRequestClose={() => {
            setOpen(false);
          }}
        >
          <FsButton buttonText="Close" onClick={handleCloseClick} />

          <div className="file-view-wrapper">
            <FileViewer fileType={type} filePath={filePath} />
          </div>
        </Modal>
      </Paper>
      {showUploadModal && (
        <DocumentUploadModal
          applicationData={applicationData}
          closeUploadModal={closeUploadModal}
          getUpdatedDocuments={getApplicationDetails}
          isJointApp={applicationData?.jointApp}
          role="admin"
        />
      )}
    </>
  );
}
export default DocumentsList;
