import { getFormattedDateUtil } from '../utils';

export const transformApplicationData = (applicationData) => {
  applicationData.dateOfCommencement = getFormattedDateUtil({
    dateString: applicationData.dateOfCommencement,
  });

  applicationData.jointApplicant_dateOfCommencement = getFormattedDateUtil({
    dateString: applicationData.jointApplicant_dateOfCommencement,
  });

  applicationData.dateOfBirth = getFormattedDateUtil({
    dateString: applicationData.dateOfBirth,
  });

  applicationData.jointApplicant_dateOfBirth = getFormattedDateUtil({
    dateString: applicationData.jointApplicant_dateOfBirth,
  });

  return applicationData;
};
