import React from 'react';
import Modal from 'react-modal';
import {
  Card,
  CardBody,
  CardTitle,
  ListGroupItem,
  ListGroup,
} from 'reactstrap';
import FsButton from '../../baseComponents/fsButton/FsButton';

import { Grid } from '@material-ui/core';
import FsImageCarousel from '../../baseComponents/fsImageCarousel/FsImageCarousel';

const PropertyViewModal = ({ activeProperty, setShowViewModal }) => {
  return (
    <Modal isOpen={true}>
      <div>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <h2 className="header text-center">Property Details</h2>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Card className="my-2" color="light" outline>
              {activeProperty?.property_images?.length > 0 && (
                <FsImageCarousel images={activeProperty.property_images} />
              )}
              <CardBody>
                <CardTitle tag="h5">{activeProperty.name}</CardTitle>
                <ListGroup flush>
                  <ListGroupItem>
                    {activeProperty.bedrooms +
                      ' Bedroom ' +
                      activeProperty?.property_type?.name ?? ''}
                  </ListGroupItem>
                  <ListGroupItem>
                    {`Price of ${activeProperty.property_type?.name}: €
                    ${activeProperty.price}`}
                  </ListGroupItem>
                  <ListGroupItem>
                    {`Affordable Purchase Price: €
                    ${activeProperty.affordable_purchase_price}`}
                  </ListGroupItem>
                </ListGroup>
                <div className={'mailsend-buttons'}>
                  <FsButton
                    onClick={() => {
                      setShowViewModal(false);
                    }}
                    buttonText="Close"
                  />
                </div>
              </CardBody>
            </Card>
          </Grid>
        </Grid>
      </div>
    </Modal>
  );
};

export default PropertyViewModal;
