import React from 'react';
import './fsCard.scss';
const FsCard = ({ width = '12', height = 'auto', content, className = '' }) => {
  return (
    <div className={`fs-card-wrapper width-${width} ${className}`}>
      {content}
    </div>
  );
};
export default FsCard;
