import React, { useState, useEffect } from 'react';
import { InputLabel, FormControl, Select, MenuItem } from '@material-ui/core';
import './typeSelect.scss';

function TypeSelect(props) {
  const [types, setTypes] = useState([]);
  const [type] = useState(props.type);
  const [selected, setSelected] = useState('');

  useEffect(async () => {
    await axios.post('/getTypes', { type: type }).then((res) => {
      setTypes(res.data.types);

      const selectedType = res.data.types.find(
        (e) => e.id === props.selectedTypeId
      );
      setSelected(selectedType?.id ?? '');
    });
  }, []);

  const typeTitle = () => {
    switch (type) {
      case 'Developments':
        return 'Development Types';
        break;
      case 'Properties':
        return 'Property Types';
        break;
      case 'Documents':
        return 'Document Types';
        break;
      default:
        return 'Types';
    }
  };
  return (
    <FormControl
      size="small"
      fullWidth
      className={'formControl'}
      variant="outlined"
    >
      <InputLabel className={'label'} id="select">
        {typeTitle()}
      </InputLabel>
      <Select
        className={'select'}
        labelId="select"
        id="select"
        label="Property Type"
        fullWidth
        onChange={(event) => {
          props.func(event, event.target.value);
          setSelected(event.target.value);
        }}
        size="small"
        value={selected || ''}
      >
        {types.map((type) => (
          <MenuItem value={type.id} key={type.id}>
            {type.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
export default TypeSelect;
