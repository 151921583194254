import { createContext } from 'react';

export const SelectedDevelopmentContext = createContext({});

export const DevelopmentProvider = ({ children }) => {
  const [selectedDevelopment, setSelectedDevelopment] = useState({});

  return (
    <SelectedDevelopmentContext.Provider
      value={{ selectedDevelopment, setSelectedDevelopment }}
    >
      {children}
    </SelectedDevelopmentContext.Provider>
  );
};
