import React, { useState, useEffect, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import StatusRenderer from '../baseComponents/fsStatusRenderer/StatusRenderer';
import linkRenderer from '../EDlinkRenderer';
import toggleRenderer from '../toggleRenderer';
import { Button } from 'reactstrap';
import Modal from 'react-modal';
import { Grid, Snackbar, IconButton, Portal } from '@material-ui/core';
import { FaTimes } from 'react-icons/fa';
import CheckboxTable from '../CheckboxTable/CheckboxTable';
import useAxios from '../../hooks/useAxios';
import CssTextField from '../baseComponents/fsTextfield/textField';
import './listView.scss';

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '2em',
    width: '60%',
  },
};

const ListView = ({
  checkboxTableColumns = {},
  checkboxTableData = {},
  checkboxUpdateId = '',
  defaultItem,
  list,
  setList,
  apiEndpoint,
  title = '',
}) => {
  const { callAxios } = useAxios();
  const gridRef = useRef();
  const [editingItem, setEditingItem] = useState({});
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [errorFields, setErrorFields] = useState([]);
  const [saveDisabled, setSaveDisabled] = useState(true);
  const [snackMsg, setSnackMsg] = useState('');
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackType, setSnackType] = useState('bg-success');

  const columnDefs = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 4,
    },
    {
      field: 'empty',
      headerName: 'Action',
      flex: 2,
      cellRenderer: 'linkRenderer',
    },
  ];

  const formLayout = [
    {
      placeholder: 'Name',
      id: 'name',
      type: 'text',
      required: true,
    },
  ];

  const closeSnack = () => {
    setSnackOpen(false);
    setSnackMsg('');
    setSnackType();
  };

  const openForm = () => {
    setEditingItem(defaultItem);
    setEditModalOpen(true);
  };

  const viewItem = async (id) => {
    setEditingItem(JSON.parse(JSON.stringify(list[id])));
    setEditModalOpen(true);
  };

  const editItem = async (id) => {
    setEditingItem(list.find((e) => e['id'] == id));
    setEditModalOpen(true);
  };

  const deleteItem = async (id) => {
    const response = await callAxios('delete', `${apiEndpoint}/${id}`);

    if (response.status === 200) {
      setSnackType('bg-success');
      const filtered = list.filter((item) => {
        return item.id !== id;
      });
      setList(filtered);
      setSnackMsg(response?.data?.message || 'Item was successfully deleted');
      setSnackOpen(true);
    } else {
      setSnackType('bg-danger');
      setSnackMsg(response?.data?.message || 'Item could not be deleted');
      setSnackOpen(true);
    }
  };

  const handleSave = async () => {
    if (editingItem.id) {
      const response = await callAxios(
        'put',
        `${apiEndpoint}/${editingItem.id}`,
        editingItem
      );

      if (response.status === 200) {
        setSnackType('bg-success');
        setEditModalOpen(false);
        const editedIndex = list.findIndex((item) => {
          if (item.id === editingItem.id) {
            return editingItem;
          }
        });
        let tempList = [...list];
        tempList[editedIndex] = editingItem;
        setList(tempList);
      } else {
        setSnackType('bg-danger');
      }
    } else {
      const response = await callAxios('post', apiEndpoint, editingItem);
      if (response.status === 200) {
        const newPropertyType = response.data.property_type;
        setSnackType('bg-success');
        setEditModalOpen(false);
        setList([
          ...list,
          { id: newPropertyType.id, name: newPropertyType.name },
        ]);
        setSnackMsg(response?.data?.message || 'Item was successfully added');
      } else {
        setSnackType('bg-danger');
        setSnackMsg(response?.data?.message || 'Item could not be created');
      }
      setSnackOpen(true);
    }
    setSaveDisabled(true);
  };

  const onChange = (e, fieldId) => {
    let newInput = e.target.value || '';
    setEditingItem({
      ...editingItem,
      [e.target.id]: newInput,
    });

    let updatedErrorFields = errorFields;
    if (
      newInput.length === 0 &&
      formLayout.find((field) => field.id === fieldId)?.required
    ) {
      if (updatedErrorFields.indexOf(e.target.id) === -1) {
        updatedErrorFields.push(e.target.id);
      }
      setErrorFields(updatedErrorFields);
    } else {
      updatedErrorFields = updatedErrorFields.filter(
        (field) => field !== e.target.id
      );
      setErrorFields(updatedErrorFields);
    }
  };

  const onSelectionModelChange = (selectedIds) => {
    setEditingItem({
      ...editingItem,
      [checkboxUpdateId]: selectedIds,
    });
  };

  const checkRequiredFields = () => {
    return formLayout.every((field) => {
      if (!field.required) return true;

      if (
        typeof editingItem[field.id] === 'string' &&
        editingItem[field.id]?.length
      )
        return true;

      return !!editingItem[field.id];
    });
  };

  useEffect(() => {
    if (!Object.keys(editingItem).length) return;

    if (checkRequiredFields() && !errorFields.length) {
      setSaveDisabled(false);
    } else {
      setSaveDisabled(true);
    }
  }, [editingItem]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={10} sm={10} md={10} lg={9}>
          <div className="text-center padding20">
            <h2 className="header" style={{ marginBottom: 0 }}>
              {title}
            </h2>
          </div>
        </Grid>
        <Grid
          item
          xs={2}
          sm={2}
          md={2}
          lg={3}
          className="grid-button-container"
        >
          <div>
            <Button
              className="primaryBtn react-strap-button-primary"
              onClick={() => {
                openForm();
              }}
              variant="contained"
            >
              Add new
            </Button>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <div
            className="ag-theme-material"
            style={{ height: '52vh', width: '100%' }}
          >
            <AgGridReact
              ref={gridRef}
              reactUi={true}
              rowData={list}
              defaultColDef={{
                sortable: 'true',
                editable: false,
                cellStyle: { textAlign: 'left' },
              }}
              context={{
                viewCall: (id) => viewItem(id),
                editCall: (id) => editItem(id),
                deleteCall: (id) => deleteItem(id),
              }}
              columnDefs={columnDefs}
              frameworkComponents={{
                statusRenderer: StatusRenderer,
                linkRenderer: linkRenderer,
                toggleRenderer: toggleRenderer,
              }}
            ></AgGridReact>
          </div>
        </Grid>
      </Grid>
      <Portal>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={snackOpen}
          autoHideDuration={2500}
          onClose={closeSnack}
          message={snackMsg}
          className={snackType}
          action={
            <React.Fragment>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={closeSnack}
              >
                <FaTimes />
              </IconButton>
            </React.Fragment>
          }
        />
      </Portal>
      <Modal
        isOpen={editModalOpen}
        onRequestClose={() => {
          setEditModalOpen(false);
        }}
        style={modalStyles}
      >
        <div>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              {editingItem.id ? (
                <h2 className="header text-center">Edit {title}</h2>
              ) : (
                <h2 className="header text-center">Create New {title}</h2>
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Grid container spacing={2}>
                {Object.keys(editingItem).map((key) => {
                  if (
                    key !== 'created_at' &&
                    key !== 'updated_at' &&
                    key !== 'id' &&
                    key !== checkboxUpdateId
                  ) {
                    return (
                      <Grid item xs={12} sm={12} md={12} lg={12} key={key}>
                        <CssTextField
                          id={key}
                          error={errorFields.includes(key)}
                          fullWidth
                          label={
                            key.charAt(0).toUpperCase() +
                            key.substr(1).toLowerCase()
                          }
                          onChange={(e) => {
                            onChange(e, key);
                          }}
                          placeholder={
                            key.charAt(0).toUpperCase() +
                            key.substr(1).toLowerCase()
                          }
                          required={true}
                          size="small"
                          value={editingItem[key] || ''}
                          variant="outlined"
                        />
                      </Grid>
                    );
                  }
                })}
                {!!checkboxTableColumns.header && (
                  <h2 className="header1" style={{ marginTop: '10px' }}>
                    {checkboxTableColumns.header}
                  </h2>
                )}
                {checkboxTableColumns.columnFields?.length > 0 && (
                  <CheckboxTable
                    columns={checkboxTableColumns.columnFields}
                    onSelectionModelChange={onSelectionModelChange}
                    rows={checkboxTableData}
                  />
                )}
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <div className={'mailsend-buttons'}>
                <Button
                  className="primaryBtn react-strap-button-primary"
                  onClick={() => {
                    setEditModalOpen(false);
                    setEditingItem({});
                  }}
                  variant="contained"
                >
                  Cancel
                </Button>
                <Button
                  className="primaryBtn react-strap-button-primary"
                  disabled={saveDisabled}
                  onClick={(e) => {
                    handleSave();
                  }}
                  variant="contained"
                >
                  Save
                </Button>
              </div>
            </Grid>
          </Grid>
        </div>
      </Modal>
    </>
  );
};

export default ListView;
