import React, { useState, useEffect } from 'react';
import { Grid, Card } from '@material-ui/core';
import { Button, CardBody, CardImg, CardTitle } from 'reactstrap';
import {
  ColorPickerComponent,
  TextBoxComponent,
} from '@syncfusion/ej2-react-inputs';
import { DropzoneDialog } from 'material-ui-dropzone';
import { CircularProgress } from '@material-ui/core';
import axios from 'axios';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import CssTextField from '../components/baseComponents/fsTextfield/textField';

const dropAreaRef = null;
const uploadObj = null;

function BrandingSettings() {
  const [loading, setLoading] = useState(true);
  const [TFDisabled, setTFDisabled] = useState(true);
  const [saveDisabled, setSaveDisabled] = useState(true);
  const [companySettings, setCompanySettings] = useState(null);
  const [logoOpen, setLogoOpen] = useState(false);
  const [iconOpen, setIconOpen] = useState(false);

  useEffect(async () => {
    setLoading(true);
    await axios.get('/getCompanySettings').then((res) => {
      setCompanySettings(res.data.company_settings);
    });
    setLoading(false);
  }, []);

  const updateCompanySettings = async (e) => {
    e.preventDefault();
    await axios
      .post('/updateCompanySettings', companySettings)
      .then((response) => {
        window.location.reload(false);
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
    setSaveDisabled(true);
  };

  const uploadLogo = async (files, type) => {
    const fdata = new FormData();
    Array.from(files).forEach(function (file) {
      fdata.append('files[]', file);
    });
    fdata.append('type', type);

    await axios
      .post('/uploadLogo', fdata)
      .then((response) => {
        window.location.reload(false);
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  };
  if (loading) {
    return (
      <div className="circProgress">
        <CircularProgress size={40} />
      </div>
    );
  }
  return (
    <div>
      <div className={'topDiv'}>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={6} md={6} lg={6}>
            <Card
              style={{
                backgroundColor: companySettings.primaryBackground,
                height: '100%',
              }}
              className="text-center"
              elevation={4}
            >
              <CardTitle>
                <h2 className="head">Details</h2>
              </CardTitle>
              <CardBody>
                <Grid container spacing={2}>
                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <CssTextField
                      fullWidth
                      variant="outlined"
                      id="name"
                      label="Company Name"
                      defaultValue={companySettings.name}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) => {
                        companySettings.name = e.target.value;
                      }}
                      readOnly={TFDisabled}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <CssTextField
                      fullWidth
                      variant="outlined"
                      id="email"
                      label="Email"
                      defaultValue={companySettings.email}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) => {
                        companySettings.email = e.target.value;
                      }}
                      readOnly={TFDisabled}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <CssTextField
                      fullWidth
                      variant="outlined"
                      id="phone"
                      label="Company Phone"
                      defaultValue={companySettings.phone}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) => {
                        companySettings.phone = e.target.value;
                      }}
                      readOnly={TFDisabled}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <CssTextField
                      fullWidth
                      variant="outlined"
                      id="url"
                      label="Company Website"
                      defaultValue={companySettings.url}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) => {
                        companySettings.url = e.target.value;
                      }}
                      readOnly={TFDisabled}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <TooltipComponent
                      style={{ display: 'inline' }}
                      content="The About Us link appears in the
                                            email header."
                    >
                      <CssTextField
                        fullWidth
                        variant="outlined"
                        id="aboutUsLink"
                        label="About Us Link"
                        defaultValue={companySettings.aboutUsLink}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={(e) => {
                          companySettings.aboutUsLink = e.target.value;
                        }}
                        readOnly={TFDisabled}
                      />
                    </TooltipComponent>
                  </Grid>

                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <TooltipComponent
                      style={{ display: 'inline' }}
                      content="The Privacy Policy link appears on
                                            the form."
                    >
                      <CssTextField
                        fullWidth
                        variant="outlined"
                        id="privacyPolicyLink"
                        label="Privacy Policy Link"
                        defaultValue={companySettings.privacyPolicyLink}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={(e) => {
                          companySettings.privacyPolicyLink = e.target.value;
                        }}
                        readOnly={TFDisabled}
                      />
                    </TooltipComponent>
                  </Grid>

                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <TooltipComponent
                      style={{ display: 'inline' }}
                      content="The Contact Us link appears in
                                                the email header."
                    >
                      <CssTextField
                        fullWidth
                        variant="outlined"
                        id="contactUsLink"
                        label="Contact Us Link"
                        defaultValue={companySettings.contactUsLink}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={(e) => {
                          companySettings.contactUsLink = e.target.value;
                        }}
                        readOnly={TFDisabled}
                      />
                    </TooltipComponent>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <TooltipComponent
                      style={{ display: 'inline' }}
                      content="The FAQ link appears on the
                                                form."
                    >
                      <CssTextField
                        fullWidth
                        variant="outlined"
                        id="FAQLink"
                        label="FAQ Link"
                        defaultValue={companySettings.FAQLink}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={(e) => {
                          companySettings.FAQLink = e.target.value;
                        }}
                        readOnly={TFDisabled}
                      />
                    </TooltipComponent>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <div>
                      <Button
                        className="react-strap-button-primary"
                        color="primary"
                        onClick={() => {
                          setTFDisabled(false);
                          setSaveDisabled(false);
                        }}
                        variant="contained"
                      >
                        Edit
                      </Button>
                      <Button
                        className="react-strap-button-primary"
                        color="primary"
                        disabled={saveDisabled}
                        onClick={(e) => updateCompanySettings(e)}
                        variant="contained"
                      >
                        Save
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </CardBody>
            </Card>
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6}>
            <Card
              style={{
                backgroundColor: companySettings.primaryBackground,
                height: '100%',
              }}
              className="text-center"
              elevation={4}
            >
              <CardTitle>
                <h2 className="head">Logo</h2>
              </CardTitle>
              <CardImg
                top
                width="90%"
                src={companySettings.logo}
                alt={'Logo'}
              />
              <CardBody>
                <Button
                  className="react-strap-button-primary"
                  color="primary"
                  onClick={() => setLogoOpen(true)}
                  variant="contained"
                >
                  Upload Logo
                </Button>
                <DropzoneDialog
                  acceptedFiles={['image/png', 'image/jpg', 'image/jpeg']}
                  cancelButtonText={'Cancel'}
                  submitButtonText={'Upload'}
                  maxFileSize={5000000}
                  open={logoOpen}
                  showPreviewsInDropzone={true}
                  showPreviews={false}
                  onClose={() => setLogoOpen(false)}
                  onSave={(files) => {
                    uploadLogo(files, 'logo');
                    setLogoOpen(false);
                  }}
                />
              </CardBody>
            </Card>
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6}>
            <Card
              style={{
                backgroundColor: companySettings.primaryBackground,
                height: '100%',
              }}
              className="text-center"
              elevation={4}
            >
              <CardTitle>
                <h2 className="head">Icon</h2>
              </CardTitle>
              <CardImg src={companySettings.icon} alt={'Icon'} />
              <CardBody>
                <Button
                  className="react-strap-button-primary"
                  color="primary"
                  onClick={() => setIconOpen(true)}
                  variant="contained"
                >
                  Upload Icon
                </Button>
                <DropzoneDialog
                  acceptedFiles={[
                    'image/png',
                    'image/jpg',
                    'image/jpeg',
                    'image/ico',
                  ]}
                  cancelButtonText={'Cancel'}
                  submitButtonText={'Upload'}
                  maxFileSize={5000000}
                  open={iconOpen}
                  showPreviewsInDropzone={true}
                  showPreviews={false}
                  onClose={() => setIconOpen(false)}
                  onSave={(files) => {
                    uploadLogo(files, 'icon');
                    setIconOpen(false);
                  }}
                />
              </CardBody>
            </Card>
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6}>
            <Card
              style={{
                backgroundColor: companySettings.primaryBackground,
                height: '100%',
              }}
              className="text-center"
              elevation={4}
            >
              <CardTitle>
                <h2 className="head">Colours</h2>
              </CardTitle>
              <CardBody>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    Primary Colour
                  </Grid>
                  <Grid item xs={6}>
                    <ColorPickerComponent
                      id="primaryColour"
                      value={companySettings.primaryColour}
                      change={(e) => {
                        companySettings.primaryColour = e.currentValue.hex;
                        setSaveDisabled(false);
                        document.documentElement.style.setProperty(
                          '--Primary',
                          e.currentValue.hex
                        );
                      }}
                    ></ColorPickerComponent>
                  </Grid>
                  <Grid item xs={6}>
                    Primary Colour Active
                  </Grid>
                  <Grid item xs={6}>
                    <ColorPickerComponent
                      id="primaryActiveColour"
                      value={companySettings.primaryActiveColour}
                      change={(e) => {
                        companySettings.primaryActiveColour =
                          e.currentValue.hex;
                        setSaveDisabled(false);
                      }}
                    ></ColorPickerComponent>
                  </Grid>
                  <Grid item xs={6}>
                    Primary Disabled Colour
                  </Grid>
                  <Grid item xs={6}>
                    <ColorPickerComponent
                      id="primaryDisabledColour"
                      value={companySettings.primaryDisabledColour}
                      change={(e) => {
                        companySettings.primaryDisabledColour =
                          e.currentValue.hex;
                        setSaveDisabled(false);
                      }}
                    ></ColorPickerComponent>
                  </Grid>
                  <Grid item xs={6}>
                    Background Colour
                  </Grid>
                  <Grid item xs={6}>
                    <ColorPickerComponent
                      id="primaryBackground"
                      value={companySettings.primaryBackground}
                      change={(e) => {
                        companySettings.primaryBackground = e.currentValue.hex;
                        setSaveDisabled(false);
                      }}
                    ></ColorPickerComponent>
                  </Grid>
                  <Grid item xs={6}>
                    Primary Shadow
                  </Grid>
                  <Grid item xs={6}>
                    <ColorPickerComponent
                      id="primaryShadow"
                      value={companySettings.primary_shadow}
                      change={(e) => {
                        companySettings.primary_shadow = e.currentValue.rgba;
                        setSaveDisabled(false);
                      }}
                    ></ColorPickerComponent>
                  </Grid>
                  <Grid item xs={6}>
                    Secondary Colour
                  </Grid>
                  <Grid item xs={6}>
                    <ColorPickerComponent
                      id="secondaryColour"
                      value={companySettings.secondaryColour}
                      change={(e) => {
                        companySettings.secondaryColour = e.currentValue.hex;
                        setSaveDisabled(false);
                      }}
                    ></ColorPickerComponent>
                  </Grid>
                </Grid>
                <div>
                  <Button
                    className="react-strap-button-primary"
                    color="primary"
                    disabled={saveDisabled}
                    onClick={updateCompanySettings}
                    variant="contained"
                  >
                    Save Colours
                  </Button>
                </div>
              </CardBody>
            </Card>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default BrandingSettings;
