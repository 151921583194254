import React from 'react';

import './statusRenderer.scss';

const StatusRenderer = ({ data = {}, id = '', value = '' }) => {
  let backgroundColor = data.status?.colour || data.colour || '';
  return (
    <span className="status-renderer-label" id={id} style={{ backgroundColor }}>
      {value}
    </span>
  );
};

export default StatusRenderer;
