import React, { useState, useEffect } from 'react';
import FsInput from '../../../../components/baseComponents/fsInput/FsInput';
import useAxios from '../../../../hooks/useAxios';
import { formatMoney } from '../../../../../utils/utils';

import './developments.scss';

const Developments = ({
  errorFields,
  formState,
  layout,
  setErrorFields,
  setFormState,
  developmentId,
}) => {
  const { callAxios } = useAxios();
  const [propertyTypes, setPropertyTypes] = useState([]);

  const handleChange = (e, obj, value) => {
    // loop developments schema, clear errors with those ids
    let updatedErrorFields = errorFields;
    layout.forEach((field) => {
      updatedErrorFields = updatedErrorFields.filter(
        (errorField) => errorField !== field.id
      );
    });
    setErrorFields(updatedErrorFields);

    setFormState({ ...formState, propertyType: value });
  };

  useEffect(() => {
    const getData = async () => {
      // ToDO:Update this with proper endpoint.
      const response = await callAxios(
        'get',
        `/development/${developmentId}/property-types`
      );
      setPropertyTypes(response.data.propertyTypes);
    };

    getData();
  }, []);

  return (
    <>
      <h5 className="affordable-purchase-form-section-header">
        Property Types
      </h5>
      <div className="development-page-wrapper">
        <h3>Please select a property type</h3>

        <p
          style={{ textAlign: 'center', padding: '0 20px', maxWidth: '900px' }}
        >
          Please be aware that the property type you select must meet the family
          composition guidelines set out in the{' '}
          <a
            target="_blank"
            href="https://scanner.topsec.com/?d=1406&r=show&u=https%3A%2f%2fwww.meath.ie%2fsystem%2ffiles%2fmedia%2ffile-uploads%2f2023-02%2fApproved%2520-%2520Meath%2520County%2520Council%2520Schem%2520of%2520Priority%2520-%2520Affordable%2520Housing.pdf&t=6b2999cd621a1c3f76047ab54a670af47389a294"
          >
            Scheme of Priorities
          </a>
        </p>

        <form className="developments-form">
          <>
            {propertyTypes?.map((propertyType, index) => {
              return (
                <div
                  key={'property_' + index}
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <FsInput
                    changeHandler={handleChange}
                    errorFields={errorFields}
                    field={layout[0]}
                    formState={formState}
                    key={index}
                    label={`${propertyType.name}`}
                  />
                  (€{formatMoney(propertyType.affordable_purchase_price)} )
                </div>
              );
            })}
          </>
        </form>

        <p
          style={{
            textAlign: 'center',
            padding: '0 20px',
            marginTop: '20px',
            maxWidth: '900px',
          }}
        >
          The ‘Affordable Price’ paid by applicant when Local Authorities equity
          share has been deducted from the full market price. Equity shares are
          calculated on an individual basis and are linked to applicants’
          income, savings (if any) and mortgage capacity.
        </p>
      </div>
    </>
  );
};

export default Developments;
