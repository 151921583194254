import React from 'react';
import { TabComponent } from '@syncfusion/ej2-react-navigations';
import EmailSettings from '../../components/emailOauthSettings';
import EmailTemplates from '../../components/EmailTemplates';
import Paper from '@material-ui/core/Paper';
import OAuthSettings from '../../components/OAuthSettings';
import BrandingSettings from '../../components/BrandingSettings';
import StatusSettings from './statusSettings/statusSettings';
import ReportingSettings from '../../components/ReportingSettings';
// import UserManagement from '../views/UserManagement';
import DevelopmentManagement from '../../views/DevelopmentManagement';

function Settings() {
  const headerTexts = [
    { text: 'Property' },
    { text: 'Email' },
    { text: 'Status' },
    { text: 'Email Templates' },
    { text: 'Login' },
    { text: 'Company' },
    // TODO
    // Removed as part of https://dev.azure.com/bynaric/FairSelect/_workitems/edit/10343
    // Add back when we decide to actually implement it
    // { text: "User Management"},
    { text: 'Reporting' },
  ];

  return (
    <Paper style={{ height: '100%', padding: '10px' }}>
      <div className="settingsheaderdiv">
        <h2 className="header">Settings</h2>
      </div>
      <TabComponent>
        <div className="e-tab-header primary">
          {headerTexts.map((headerText, index) => (
            <div key={index}> {headerText.text} </div>
          ))}
        </div>
        <div className="e-content">
          <div>
            <DevelopmentManagement />
          </div>
          <div>
            <EmailSettings />
          </div>
          <div>
            <StatusSettings />
          </div>
          <div>
            <EmailTemplates />
          </div>
          <div>
            <OAuthSettings />
          </div>
          <div>
            <BrandingSettings />
          </div>
          {/* TODO
            // Removed as part of https://dev.azure.com/bynaric/FairSelect/_workitems/edit/10343
            // Add back when we decide to actually implement it
            */}
          {/* <div>
            <UserManagement />
          </div> */}
          <div>
            <ReportingSettings />
          </div>
        </div>
      </TabComponent>
    </Paper>
  );
}

export default Settings;
