import React from 'react';
import InfoText from './InfoText';
import Eligibility from './Eligibility';
import ApplicationProcedure from './ApplicationProcedure';
import DocumentRequirements from './DocumentRequirements';
import Agreements from './Agreements';

import './overview.scss';

const Overview = ({
  agreementsLayout,
  eligibilityLayout,
  errorFields,
  formState,
  handleCheckboxToggle,
  setErrorFields,
  setFormState,
}) => {
  const handleAgreementsCheckboxToggle = (e) => {
    handleCheckboxToggle(e, agreementsLayout);
  };

  const handleEligibilityCheckboxToggle = (e) => {
    handleCheckboxToggle(e, eligibilityLayout);
  };

  return (
    <>
      <InfoText />
      <Eligibility
        errorFields={errorFields}
        formState={formState}
        handleCheckboxToggle={handleEligibilityCheckboxToggle}
        layout={eligibilityLayout}
        setErrorFields={setErrorFields}
        setFormState={setFormState}
      />
      {/* <ApplicationProcedure /> */}
      <DocumentRequirements />
      <Agreements
        errorFields={errorFields}
        formState={formState}
        handleCheckboxToggle={handleAgreementsCheckboxToggle}
        layout={agreementsLayout}
        setErrorFields={setErrorFields}
        setFormState={setFormState}
      />
    </>
  );
};

export default Overview;
