import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ApplicantForm from './ApplicantForm';

const ApplicantsTab = ({
  applicantData,
  applicationData,
  jointApplicantData,
}) => {
  let jointApplicant = applicationData?.jointApp ?? null;

  return (
    <>
      {/* MAIN APPLICANT */}
      <Accordion disableGutters elevation={2}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className="finances-tab-accordion-header custom-accordion-wrapper"
        >
          <div>
            <h2>Main Applicant</h2>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <ApplicantForm applicantData={applicantData} />
        </AccordionDetails>
      </Accordion>

      {/* JOINT APPLICANT */}
      {!!jointApplicant && (
        <Accordion disableGutters elevation={2}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="finances-tab-accordion-header"
          >
            <div>
              <h2>Joint Applicant</h2>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <ApplicantForm applicantData={jointApplicantData} />
          </AccordionDetails>
        </Accordion>
      )}
    </>
  );
};

export default ApplicantsTab;
