import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';
import axios from 'axios';
import ListView from '../components/ListView/ListView';

/*
  TODO
  As part of https://dev.azure.com/bynaric/FairSelect/_workitems/edit/10405
  We have removed the Development and Document types sections. they may be added again later
*/
// const developmentCheckboxTableColumns = {
//   columnFields: [{ field: 'name', headerName: 'Name', width: 300 }],
//   header: 'Documents',
// };

function TypeSettings() {
  const [loading, setLoading] = useState(true);
  const [docTypes, setDocTypes] = useState({});
  const [devTypes, setDevTypes] = useState({});
  const [propertyTypes, setPropertyTypes] = useState({});

  useEffect(async () => {
    setLoading(true);
    var data = { type: 'Developments' };
    await axios.post('/getTypes', data).then((res) => {
      setDevTypes(res.data.types);
    });
    data = { type: 'Documents' };
    await axios.post('/getTypes', data).then((res) => {
      setDocTypes(res.data.types);
    });
    data = { type: 'Properties' };
    await axios.post('/getTypes', data).then((res) => {
      setPropertyTypes(res.data.types);
    });
    setLoading(false);
  }, []);

  if (loading) {
    return (
      <div className="circProgress">
        <CircularProgress size={40} />
      </div>
    );
  }
  return (
    <div className={'topDiv'}>
      <Grid container spacing={2}>
        {/*
        TODO
        As part of https://dev.azure.com/bynaric/FairSelect/_workitems/edit/10405
        We have removed the Development and Document types sections. they may be added again later
        */}
        {/* <Grid item xs={12} sm={12} md={6} lg={4}>
          <ListView
            columnDefs={columnDefs}
            checkboxTableColumns={developmentCheckboxTableColumns}
            checkboxTableData={docTypes}
            checkboxUpdateId={'documentTypeIds'}
            defaultItem={{ id: null, name: '', documentTypeIds: [] }}
            formLayout={listViewFormLayout}
            list={devTypes}
            setList={setDevTypes}
            type={'Development'}
            type_id={1}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <ListView
            columnDefs={columnDefs}
            defaultItem={{ id: null, name: '' }}
            formLayout={listViewFormLayout}
            list={docTypes}
            setList={setDocTypes}
            type_id={2}
            type={'Document'}
          />
        </Grid> */}
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <ListView
            defaultItem={{ id: null, name: '' }}
            list={propertyTypes}
            setList={setPropertyTypes}
            title={'Property Type'}
            apiEndpoint="/property-type"
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default TypeSettings;
