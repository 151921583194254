import React from 'react';
import Grid from '@material-ui/core/Grid';
import StaticApplicantFormSection from './StaticApplicantFormSection';
import IncomeList from '../../../components/IncomeList';

import EmployedFormExtras from './EmployedFormExtras';
import SelfEmployedFormExtras from './SelfEmployedFormExtras';
import NotEmployedFormExtras from './NotEmployedFormExtras';

import './applicantForm.scss';
import CssTextField from '../../../components/baseComponents/fsTextfield/textField';
import { getBooleanValueDisplayUtil } from '../../../../utils/utils';
const ApplicantForm = ({ applicantData }) => {
  return (
    <>
      <Grid
        container
        spacing={2}
        justifyContent="center"
        alignItems="center"
        direction="row"
      >
        <StaticApplicantFormSection applicantData={applicantData} />

        {applicantData?.employmentStatus === 'Employed' && (
          <EmployedFormExtras applicantData={applicantData} />
        )}

        {applicantData?.employmentStatus === 'Self Employed' && (
          <SelfEmployedFormExtras applicantData={applicantData} />
        )}

        {applicantData?.employmentStatus === 'Not Employed' && (
          <NotEmployedFormExtras applicantData={applicantData} />
        )}

        <Grid item xs={12} sm={12} md={3} lg={3}>
          <CssTextField
            fullWidth
            id="outlined-read-only-input"
            InputProps={{
              readOnly: true,
            }}
            InputLabelProps={{ shrink: true }}
            label="Other Income"
            value={getBooleanValueDisplayUtil(applicantData?.otherIncome)}
          />
        </Grid>
      </Grid>

      {applicantData?.otherIncome === true && (
        <div className="income-list-wrapper">
          <IncomeList list={applicantData?.other_incomes} />
        </div>
      )}
    </>
  );
};

export default ApplicantForm;
