import { DATE_FORMAT_REGEX, EIRCODE_REGEX, MONEY_FORMAT } from '../regexUtils';

export const employedSchema = (isJoint) => {
  const occupationId = isJoint ? 'jointApplicant_occupation' : 'occupation';
  const dateOfCommencementId = isJoint
    ? 'jointApplicant_dateOfCommencement'
    : 'dateOfCommencement';
  const employmentTermsId = isJoint
    ? 'jointApplicant_employmentTerms'
    : 'employmentTerms';
  const frequencyOfPaymentId = isJoint
    ? 'jointApplicant_frequencyOfPayment'
    : 'frequencyOfPayment';
  const grossIncomeId = isJoint ? 'jointApplicant_grossIncome' : 'grossIncome';
  const overtimeId = isJoint ? 'jointApplicant_overtime' : 'overtime';
  const bonusId = isJoint ? 'jointApplicant_bonus' : 'bonus';
  const commissionId = isJoint ? 'jointApplicant_commission' : 'commission';
  const employerNameId = isJoint
    ? 'jointApplicant_employerName'
    : 'employerName';
  const employerAddressLine1Id = isJoint
    ? 'jointApplicant_employerAddressLine1'
    : 'employerAddressLine1';
  const employerAddressLine2Id = isJoint
    ? 'jointApplicant_employerAddressLine2'
    : 'employerAddressLine2';
  const employerCityId = isJoint
    ? 'jointApplicant_employerCity'
    : 'employerCity';
  const employerCountyId = isJoint
    ? 'jointApplicant_employerCounty'
    : 'employerCounty';
  const employerEircodeId = isJoint
    ? 'jointApplicant_employerEircode'
    : 'employerEircode';
  const typeOfBusinessId = isJoint
    ? 'jointApplicant_typeOfBusiness'
    : 'typeOfBusiness';
  const proofOfIncomeId = isJoint
    ? 'jointApplicant_proofOfIncome'
    : 'proofOfIncome';
  const employerReferenceId = isJoint
    ? 'jointApplicant_employerReference'
    : 'employerReference';

  const targetField = isJoint
    ? 'jointApplicant_employmentStatus'
    : 'employmentStatus';

  const checkIsVisible = (formState) => {
    if (formState[targetField] === 'Employed') {
      return true;
    }
    return false;
  };

  const requiredRules = (formState) => {
    if (formState[targetField] === 'Employed') {
      return true;
    }
    return false;
  };

  const requiredRulesEmployed = (formState) => {
    if (
      formState[targetField] === 'Employed' ||
      formState[targetField] === 'Self Employed'
    ) {
      return true;
    }
    return false;
  };

  const requiredRulesAllEmployment = (formState) => {
    if (
      formState[targetField] === 'Employed' ||
      formState[targetField] === 'Self Employed' ||
      formState[targetField] === 'Not Employed'
    ) {
      return true;
    }
    return false;
  };

  return [
    {
      errorMessage: 'Field required',
      id: occupationId,
      checkIsVisible,
      placeholder: 'Occupation',
      requiredRules: requiredRulesEmployed,
      type: 'text',
    },
    {
      errorMessage: 'Field required',
      id: dateOfCommencementId,
      checkIsVisible,
      placeholder: 'Date of Commencement',
      regex: DATE_FORMAT_REGEX,
      type: 'date',
      requiredRules: requiredRules,
    },
    {
      errorMessage: 'Field required',
      id: employmentTermsId,
      checkIsVisible,
      label: 'Employment Terms',
      options: [
        { value: 'Permanent' },
        { value: 'Part-time' },
        { value: 'Fixed-term' },
      ],
      type: 'select',
      requiredRules: requiredRules,
    },
    {
      errorMessage: 'Field required',
      id: frequencyOfPaymentId,
      checkIsVisible,
      label: 'Frequency of Payment',
      options: [
        { value: 'Weekly' },
        { value: 'Fortnightly' },
        { value: 'Monthly' },
      ],
      requiredRules: requiredRules,
      type: 'select',
    },
    {
      errorMessage: 'Not a valid value',
      id: grossIncomeId,
      checkIsVisible,
      placeholder: 'Annual Gross Income',
      regex: MONEY_FORMAT,
      startAdornment: '€',
      requiredRules: requiredRulesEmployed,
      type: 'number',
    },
    {
      errorMessage: 'Not a valid value',
      id: overtimeId,
      checkIsVisible,
      placeholder: 'Overtime per annum',
      regex: MONEY_FORMAT,
      startAdornment: '€',
      type: 'number',
    },
    {
      errorMessage: 'Not a valid value',
      id: bonusId,
      checkIsVisible,
      placeholder: 'Bonus per annum',
      regex: MONEY_FORMAT,
      startAdornment: '€',
      type: 'number',
    },
    {
      errorMessage: 'Not a valid value',
      id: commissionId,
      checkIsVisible,
      placeholder: 'Commission',
      regex: MONEY_FORMAT,
      startAdornment: '€',
      type: 'number',
    },
    {
      errorMessage: 'Field required',
      id: employerNameId,
      checkIsVisible,
      placeholder: 'Employer Name',
      type: 'text',
      requiredRules: requiredRules,
    },
    {
      errorMessage: 'Field required',
      id: employerAddressLine1Id,
      checkIsVisible,
      placeholder: 'Employer Address Line 1',
      type: 'text',
      requiredRules: requiredRules,
    },
    {
      errorMessage: 'Field required',
      id: employerAddressLine2Id,
      checkIsVisible,
      placeholder: 'Employer Address Line 2',
      type: 'text',
    },
    {
      errorMessage: 'Field required',
      id: employerCityId,
      checkIsVisible,
      placeholder: 'Employer City',
      type: 'text',
      requiredRules: requiredRules,
    },
    {
      errorMessage: 'Field required',
      id: employerCountyId,
      checkIsVisible,
      label: 'Employer County',
      type: 'select',
      requiredRules: requiredRules,
      options: [
        { value: 'Antrim' },
        { value: 'Armagh' },
        { value: 'Carlow' },
        { value: 'Cavan' },
        { value: 'Clare' },
        { value: 'Cork' },
        { value: 'Derry' },
        { value: 'Donegal' },
        { value: 'Down' },
        { value: 'Dublin' },
        { value: 'Fermanagh' },
        { value: 'Galway' },
        { value: 'Kerry' },
        { value: 'Kildare' },
        { value: 'Kilkenny' },
        { value: 'Laois' },
        { value: 'Leitrim' },
        { value: 'Limerick' },
        { value: 'Longford' },
        { value: 'Louth' },
        { value: 'Mayo' },
        { value: 'Meath' },
        { value: 'Monaghan' },
        { value: 'Offaly' },
        { value: 'Roscommon' },
        { value: 'Sligo' },
        { value: 'Tipperary' },
        { value: 'Tyrone' },
        { value: 'Waterford' },
        { value: 'Westmeath' },
        { value: 'Wexford' },
        { value: 'Wicklow' },
      ],
    },
    {
      errorMessage: 'Field required',
      id: employerEircodeId,
      checkIsVisible,
      placeholder: 'Employer Eircode',
      regex: EIRCODE_REGEX,
      requiredRules: requiredRules,
      type: 'text',
    },
    {
      errorMessage: 'Field required',
      id: typeOfBusinessId,
      checkIsVisible,
      placeholder: 'Type of Business',
      requiredRules: requiredRulesEmployed,
      type: 'text',
    },
    {
      errorMessage: 'Document required',
      id: proofOfIncomeId,
      checkIsVisible,
      name: 'Proof of income',
      requiredRules: requiredRulesAllEmployment,
      tooltip: 'Employed Income Help',
      type: 'uploader',
    },
    {
      errorMessage: 'Document required',
      id: employerReferenceId,
      checkIsVisible,
      name: 'Employer Reference',
      tooltip: 'Employer Reference',
      type: 'uploader',
    },
  ];
};
