import { applicantSchema } from './applicantSchema';
import { financialsSchema } from './financialsSchema';

export const affordablePurchaseFormSchema = (
  formState,
  companySettings = {}
) => {
  let jointApplicantSchema = [];

  if (formState.isJointApplication) {
    jointApplicantSchema = applicantSchema(formState, true);
  }

  return {
    eligibility: [
      {
        text:
          '1. You are a First Time Buyer OR you meet the exceptions under the ' +
          '<a href="https://localauthorityhomeloan.ie/about/" target="_blank">Fresh Start Principle</a>' +
          ' OR if you own a dwelling and its size is not suitable to your current household needs.',
        id: 'checkbox1',
        required: true,
        type: 'checkboxSection',
      },
      {
        text: ' 2. The affordable home must be your normal residence.',
        id: 'checkbox2',
        required: true,
        type: 'checkboxSection',
      },
      {
        text: '3. Each person in the application has the right to reside indefinitely in the state.',
        id: 'checkbox3',
        required: true,
        type: 'checkboxSection',
      },
      {
        text: '4. Your purchasing power must not exceed 95% of the market value of the property.',
        id: 'checkbox4',
        required: true,
        type: 'checkboxSection',
      },
      {
        text: '5. You are below the income limit of the development location you are applying for.',
        id: 'checkbox5',
        required: true,
        type: 'checkboxSection',
      },
      {
        text: '6. You must be Mortgage Approved In Principle.',
        id: 'checkbox6',
        required: true,
        type: 'checkboxSection',
      },
    ],

    agreements: [
      {
        text:
          'I have read and understand the ' +
          '<a href=' +
          companySettings?.privacyPolicyLink +
          ' target="_blank" rel="noopener noreferrer">' +
          'privacy policy' +
          '</a>',
        id: 'privacyPolicy',
        required: true,
        type: 'checkboxSection',
      },
      {
        text: 'I understand the eligibility requirements and application procedure.',
        id: 'applicationProcedure',
        required: true,
        type: 'checkboxSection',
      },
    ],

    developments: [
      {
        id: 'propertyType',
        type: 'radio',
        name: 'propertyType',
        label: 'propertyType',
        required: true,
        errorMessage: 'Field required',
      },
    ],

    mainApplicant: applicantSchema(formState, false),

    jointApplicant: jointApplicantSchema,

    declarations: [
      {
        text: 'I declare that all the information input on this application to be true and accurate. This includes all applicants.* Applicants should note that giving untrue/incorrect information on their application may lead to the affordable housing purchase arrangement being terminated and the offer to purchase being withdrawn.',
        id: 'declaration1',
        required: true,
        type: 'checkboxSection',
      },
      {
        text: `I declare that all applicants are deemed "First Time Buyers" or "Fresh Start Applicants" or “existing home owners in properties which because of their size, are not suited to the current accommodation needs of my household” and they have provided proof of this. I authorise Meath County Council to conduct such checks as are necessary to confirm this such as conducting local property tax checks to verify First Time Buyer status.`,
        id: 'declaration2',
        required: true,
        type: 'checkboxSection',
      },
      {
        text: `I acknowledge that if successful in my application and offered a property for purchase under this scheme that I/we will have to fund the purchase of the property.`,
        id: 'declaration3',
        required: true,
        type: 'checkboxSection',
      },
      {
        text: `I am aware that a €5,000 booking deposit due on execution of contracts will be payable to the developer on the acceptance of any offer and that Meath County Council's designated contact will pass my name and contact details to the developer for the purposes of the sale of the property, as per the privacy statement.`,
        id: 'declaration4',
        required: true,
        type: 'checkboxSection',
      },
      {
        text: `I declare that I/We have read and understand the <a href='https://www.meath.ie/system/files/media/file-uploads/2023-05/APDA%20Income%20Assessment%20Policy.pdf' target="_blank" rel="noopener noreferrer"> Income Assessment Policy</a>.`,
        id: 'declaration5',
        required: true,
        type: 'checkboxSection',
      },
      {
        text: `In the event of my application being successful, I agree that house numbers will be distributed as per the Scheme of Priorities.`,
        id: 'declaration6',
        required: true,
        type: 'checkboxSection',
      },
      {
        text: `I permit Meath County Council to contact me regarding future schemes in the event I am unsuccessful.`,
        id: 'declaration7',
        required: true,
        type: 'checkboxSection',
      },
    ],
    financials: financialsSchema(formState),
  };
};
