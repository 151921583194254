import { styled } from '@mui/material/styles';
import { TextField } from '@material-ui/core';

const CssTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: 'var(--primaryLocal)',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'var(--primaryLocal)',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'disable',
    },
    '&:hover fieldset': {
      borderColor: 'var(--primaryLocal)',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'var(--primaryLocal)',
    },
  },
});

export default CssTextField;
