import React, { useState, useEffect, useCallback } from 'react';
import { Button, Form, Input, Label } from 'reactstrap';
import Modal from 'react-modal';
import { FaExclamationTriangle, FaTicketAlt, FaTimes } from 'react-icons/fa';
import FsCard from '../../components/baseComponents/fsCard/FsCard';
import FsButton from '../../components/baseComponents/fsButton/FsButton';
import Grid from '@material-ui/core/Grid';

import EmailApplicantModal from '../../components/emailApplicantModal/EmailApplicantModal';

import './applicantDetailsTopper.scss';
import axios from 'axios';

const customStyles = {
  content: {
    width: '500px',
    height: '550px',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

const ApplicantDetailsTopper = ({
  activeTab,
  handleTabClick,
  applicantData,
  applicationData,
}) => {
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [templateId, setTemplateId] = useState('');
  const [applicantId, setApplicantId] = useState('');
  const [numberOfHouseholdMembers, setNumberOfHouseholdMembers] =
    useState(null);
  const [statusChangeButtonDisabled, setStatusChangeButtonDisabled] =
    useState(true);
  const [statuses, setStatuses] = useState([]);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(null);

  const handleButtonClick = () => {
    fetchEmailTemplates();
    setShowEmailModal(true);
  };

  const handleCloseModal = () => {
    setShowEmailModal(false);
  };

  const fetchEmailTemplates = async () => {
    const url = '/fetchEmailTemplates';
    const res = await axios.get(url);
    if (res.data.status === 200) {
      setTemplates(res.data.templates);
    }
  };

  const handleStatusChange = (value) => {
    setSelectedStatus(statuses[value]);
    if (value) {
      setStatusChangeButtonDisabled(false);
    } else {
      setStatusChangeButtonDisabled(true);
    }
  };

  const fetchStatuses = useCallback(async () => {
    try {
      const response = await axios.post('/getStatuses', {
        type: 'Application',
      });
      if (response.data.statuses) {
        const status = [];
        response.data.statuses.forEach((st) => {
          status[st.id] = st;
        });
        setStatuses(status);
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  const getNumberOfHouseholdMembers = () => {
    let householdMemberCount = 1;
    if (applicationData?.jointApp) {
      householdMemberCount += 1;
    }
    if (applicantData?.household_members?.length || 0) {
      householdMemberCount += applicantData?.household_members?.length;
    }
    return householdMemberCount;
  };

  const openStatusModal = () => {
    setStatusChangeButtonDisabled(true);
    setShowStatusModal(true);
  };

  const changeStatus = async () => {
    const urlData = {
      application_id: applicantData.application_id,
      status: selectedStatus,
    };
    await axios
      .post('/setStatus', urlData)
      .then(() => {
        setShowStatusModal(false);
      })
      .catch((response) => {
        console.log('ERROR', response);
      });
    setSelectedStatus({});
  };

  useEffect(() => {
    const getApplications = async () => {
      const response = await callAxios('get', '/application');
      setRowData(response.data.applications);
    };

    Promise.all([fetchStatuses()]).then(() => {});
  }, []);

  useEffect(() => {
    if (applicantData) {
      setApplicantId(applicantData.id);
      setNumberOfHouseholdMembers(getNumberOfHouseholdMembers());
    }
  }, [applicantData, applicationData]);

  const cardContent = (
    <div className="applicant-details-topper">
      <div className="applicant-card-header">
        <h4>Application ID: {applicationData?.id ?? ''}</h4>

        <ul className="subheader-list">
          {applicantData?.addressLine1 && (
            <li className="subheader-list-item">
              {applicantData?.addressLine1 +
                ', ' +
                applicantData?.addressLine2 +
                ', ' +
                applicantData?.city +
                ', ' +
                applicantData?.county +
                ', ' +
                applicantData?.eircode}
            </li>
          )}
          <li className="subheader-list-item">
            {applicantData?.property_type}
          </li>
          <li className="subheader-list-item">{applicantData?.email}</li>
        </ul>
        <div className="email-button-wrapper">
          <Button
            className="react-strap-button-primary"
            variant="contained"
            color="primary"
            title="Change the Status of the selected Applicants"
            style={{ marginRight: 4, marginTop: -2 }}
            onClick={openStatusModal}
          >
            <FaTicketAlt /> Change Status
          </Button>
          <FsButton onClick={handleButtonClick} buttonText="Email Applicant" />
        </div>
      </div>

      <div className="applicant-info-cards-wrapper">
        {applicantData?.net_income && (
          <div className="applicant-info-card">
            <span className="applicant-info-card-value">
              {applicantData?.net_income}
            </span>
            <span className="applicant-info-card-field">Household Income</span>
          </div>
        )}

        <div className="applicant-info-card">
          <span className="applicant-info-card-value">
            {applicationData?.jointApp ? 'Yes' : 'No'}
          </span>
          <span className="applicant-info-card-field">Joint Application</span>
        </div>

        <div className="applicant-info-card">
          <span className="applicant-info-card-value">
            {numberOfHouseholdMembers}
          </span>
          <span className="applicant-info-card-field">Household Members</span>
        </div>

        {applicationData?.development?.name && (
          <div className="applicant-info-card">
            <span className="applicant-info-card-value">
              {applicationData?.development?.name}
            </span>
            <span className="applicant-info-card-field">Development Name</span>
          </div>
        )}

        {applicationData?.property?.name && (
          <div className="applicant-info-card">
            <span className="applicant-info-card-value">
              {applicationData?.property?.name}
            </span>
            <span className="applicant-info-card-field">Property Type</span>
          </div>
        )}
      </div>

      <div className="applicant-tabs">
        <ul className="applicant-tabs-list">
          <li
            className={`${activeTab === 'Applicants' && 'active-tab'}`}
            onClick={() => {
              handleTabClick('Applicants');
            }}
          >
            Applicants
          </li>
          <li
            className={`${activeTab === 'Household' && 'active-tab'}`}
            onClick={() => {
              handleTabClick('Household');
            }}
          >
            Household
          </li>
          <li
            className={`${activeTab === 'Finances' && 'active-tab'}`}
            onClick={() => {
              handleTabClick('Finances');
            }}
          >
            Finances
          </li>
          <li
            className={`${activeTab === 'Documents' && 'active-tab'}`}
            onClick={() => {
              handleTabClick('Documents');
            }}
          >
            Documents
          </li>
          <li
            className={`${activeTab === 'Comments' && 'active-tab'}`}
            onClick={() => {
              handleTabClick('Comments');
            }}
          >
            Comments
          </li>
        </ul>
      </div>

      {showEmailModal && (
        <EmailApplicantModal
          applicantId={applicantId}
          applicantData={applicantData}
          handleCloseModal={handleCloseModal}
        />
      )}

      <Modal
        style={customStyles}
        isOpen={showStatusModal}
        onRequestClose={() => {
          setShowStatusModal(false);
          setLoading(false);
        }}
      >
        <div id={'smallModalContainer'}>
          <div>
            <h2 className="head">Change Status</h2>
          </div>
          <div>Please choose a status below to update.</div>
          <div>&nbsp;</div>

          <Label for="appStatus">Select Status</Label>
          <Input
            type="select"
            name="appStatus"
            id="appStatus"
            defaultValue={null}
            onChange={(e) => {
              handleStatusChange(e.target.value);
            }}
          >
            <option key={0} value="">
              -- select an option --
            </option>
            {statuses.map((status) => (
              <option key={status.id} value={status.id}>
                {status.name}
              </option>
            ))}
          </Input>
          <div className={'infoBar'}>
            {selectedStatus && selectedStatus.autosend && (
              <div>
                <p className="redIcon">
                  <FaExclamationTriangle />
                </p>
                <p>
                  The status you have chosen is linked to the "
                  {selectedStatus.emailTemplate}" email template and will
                  dispatch the email automatically when you change the status.
                </p>
              </div>
            )}
          </div>
          <div className={'infoBar'}>
            <Button
              className="react-strap-button-primary"
              variant="contained"
              color="primary"
              disabled={statusChangeButtonDisabled}
              onClick={changeStatus}
            >
              Change Status
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );

  return <FsCard content={cardContent} />;
};

export default ApplicantDetailsTopper;
