import React from 'react';
import ApplicantDetails from './applicantDetails/ApplicantDetails';
import { useParams } from 'react-router-dom';

function Applicant() {
  const { id } = useParams();
  return (
    <div>
      <ApplicantDetails id={id} />
    </div>
  );
}

export default Applicant;
