import React, { useEffect } from 'react';
import FsButton from '../fsButton/FsButton';
import './fsModal.scss';

const FsModal = ({
  headerText,
  centeredHeader = false,
  handleClose,
  content,
  fullScreen = false,
  handleSave,
  isSaveButtonDisabled,
  rightButtonClassName = '',
  leftButtonText = 'Cancel',
  rightButtonText = 'Save',
  halfWidth = false,
  isDanger = false,
  hasCloseButton = true,
}) => {
  useEffect(() => {
    document.body.style.overflowY = 'hidden';
    return () => {
      document.body.style.overflowY = 'auto';
    };
  }, []);

  return (
    <div className="fs-modal-wrapper">
      <div
        className={`${halfWidth && 'half-width'} ${isDanger && 'danger'} ${
          fullScreen && 'full-screen'
        } fs-modal-container`}
      >
        <header
          className={`fs-modal-header ${!hasCloseButton && 'no-button'} ${
            centeredHeader && 'centered-header'
          }`}
        >
          <h5>{headerText}</h5>
          {hasCloseButton && (
            <div className="close-modal-icon">
              <FsButton buttonText="Close" onClick={handleClose} />
            </div>
          )}
        </header>

        <div className="fs-modal-main-content">{content}</div>
        {handleSave && (
          <div className="fs-modal-action-buttons">
            <FsButton buttonText={leftButtonText} onClick={handleClose} />
            <FsButton
              buttonText={rightButtonText}
              className={rightButtonClassName}
              disabled={isSaveButtonDisabled}
              onClick={() => {
                handleSave();
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default FsModal;
