import React, { useState, useEffect } from 'react';
import FsModal from '../../../../components/baseComponents/fsModal/FsModal';
import ApplicantFormSubsection from '../../ApplicantFormSubsection';

import { getFormattedDateUtil } from '../../../../../utils/utils';

import { householdMembersModalSchema } from '../../../../../utils/affordablePurchase/householdMembersModalSchema';
import {
  addErrorField,
  getFieldHasError,
  removeErrorField,
} from '../../../../../utils/affordablePurchase/formStateUtils';

const HouseholdsModal = ({
  rowIndex,
  formState,
  setFormState,
  setShowModal,
  setFlag,
}) => {
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [localErrors, setLocalErrors] = useState([]);
  const [currentHouseholdsMember, setCurrentHouseholdsMember] = useState({
    householdMemberDateOfBirth: '',
  });

  useEffect(() => {
    const errorsExist = householdMembersModalSchema().some((field) => {
      return getFieldHasError(
        field,
        currentHouseholdsMember[field.id],
        formState
      );
    });
    setIsSaveDisabled(errorsExist);
  }, [currentHouseholdsMember]);

  useEffect(() => {
    let currentHouseholdsMember;
    if (!rowIndex) return;
    formState.householdMembers.forEach((member) => {
      if (member.id === rowIndex) {
        currentHouseholdsMember = member;
      }
    });

    currentHouseholdsMember.householdMemberDateOfBirth = getFormattedDateUtil({
      dateString: currentHouseholdsMember.householdMemberDateOfBirth,
    });

    setCurrentHouseholdsMember(currentHouseholdsMember);
  }, [rowIndex]);

  const handleClose = () => {
    setShowModal(false);
  };

  const handleSave = () => {
    const newMembers = formState.householdMembers;
    if (!rowIndex) {
      setFormState({
        ...formState,
        householdMembers: [...newMembers, currentHouseholdsMember],
      });
    } else {
      newMembers[rowIndex] = currentHouseholdsMember;
      setFormState({ ...formState, householdMembers: newMembers });
    }

    setFlag(true);
    setShowModal(false);
  };

  const handleChange = (e, field) => {
    setCurrentHouseholdsMember({
      ...currentHouseholdsMember,
      [field.id]: e.target.value,
    });

    let updatedErrorFields = localErrors;
    const fieldHasError = getFieldHasError(field, e.target.value, formState);

    if (fieldHasError) {
      updatedErrorFields = addErrorField(updatedErrorFields, field.id);
    } else {
      updatedErrorFields = removeErrorField(updatedErrorFields, field.id);
    }

    setLocalErrors(updatedErrorFields);
  };

  const modalContent = (
    <ApplicantFormSubsection
      errorFields={localErrors}
      formState={currentHouseholdsMember}
      layout={householdMembersModalSchema()}
      onChange={handleChange}
      overrideFormState={currentHouseholdsMember}
      setErrorFields={setLocalErrors}
      setFormState={setFormState}
    />
  );

  return (
    <FsModal
      content={modalContent}
      handleClose={handleClose}
      handleSave={handleSave}
      headerText={
        rowIndex
          ? 'Edit a Household Member'
          : 'Add an Additional Household Member'
      }
      isSaveButtonDisabled={isSaveDisabled}
    />
  );
};

export default HouseholdsModal;
