import React, { useState, useEffect, useRef } from 'react';
import { CircularProgress } from '@material-ui/core';
import { AgGridReact } from 'ag-grid-react';
import Modal from 'react-modal';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import FileViewer from 'react-file-viewer';
import StatusRenderer from './baseComponents/fsStatusRenderer/StatusRenderer';
import linkRenderer from './ViewDeleteLinkRenderer';
import { Button } from 'reactstrap';
import CloseIcon from '@mui/icons-material/Close';
import FsCard from './baseComponents/fsCard/FsCard';
import { useNavigate, useParams } from 'react-router-dom';
import useAxios from '../hooks/useAxios';
import FsButton from './baseComponents/fsButton/FsButton';
import DocumentUploadModal from './documentUploadModal/DocumentUploadModal';
import './documentList.scss';
import { getFormattedDateUtil } from '../../utils/utils';
import 'ag-grid-enterprise';

const token = $('meta[name="csrf-token"]').attr('content');

function DocumentsListApplicants() {
  const gridRef = useRef();
  const { id: developmentId } = useParams();
  const { callAxios } = useAxios();

  const [columnDefs, setColumnDefs] = useState([]);
  const [developmentName, setDevelopmentName] = useState('');
  const [documents, setDocuments] = useState([]);
  const [documentTypes, setDocumentTypes] = useState([]);
  const [filePath, setFilePath] = useState('');
  const [isJointApp, setIsJointApp] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [totalDocs, setTotalDocs] = useState(0);
  const [type, setType] = useState();
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [applicationStatus, setApplicationStatus] = useState('');

  const navigate = useNavigate();

  const getCardContent = (documentType) => {
    return (
      <div className="document-info-card">
        <span className="document-info-card-value">{documentType.type}</span>
        <span className="document-info-card-field">{documentType.count}</span>
      </div>
    );
  };

  const deleteDoc = (doc) => {
    axios
      .post('/deleteDoc', doc, { headers: { 'X-CSRF-TOKEN': token } })
      .then((res) => {
        alert(res.data.message);
        window.location.reload(false);
      });
  };

  const viewDoc = async (doc) => {
    setFilePath(doc.path);
    setType(doc.ext);
    setOpen(true);
  };

  const getUpdatedDocuments = async () => {
    const response = await callAxios(
      'get',
      `/document/my-documents-by-development/${developmentId}`
    );

    if (response.status === 200) {
      setApplicationStatus(response.data.application_status);
      setDevelopmentName(response.data.development_name);
      setIsJointApp(response.data.joint_app);
      setDocuments(response.data.documents);
      setDocumentTypes(response.data.types);
      setTotalDocs(response.data.total);
    } else {
      setDevelopmentName('');
      setIsJointApp(false);
      setDocuments([]);
      setDocumentTypes([]);
      setTotalDocs(0);
    }
  };

  useEffect(async () => {
    setLoading(true);

    await getUpdatedDocuments();
    setLoading(false);

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (isMobile) {
      setColumnDefs([
        {
          field: 'name',
          headerName: 'Name',
          flex: '2',
          sortable: 'true',
          filter: 'agTextColumnFilter',
          valueGetter: (props) => {
            const name = props.data.name;
            const parts = name?.split('-');
            if (parts.length > 3) {
              return parts.slice(3).join('_');
            } else {
              return parts[3];
            }
          },
          width: 80,
        },
        {
          field: 'applicantType',
          headerName: 'Applicant',
          sortable: 'true',
          filter: 'agTextColumnFilter',
        },
        // {
        //   field: 'status',
        //   headerName: 'Status',
        //   sortable: 'true',
        //   filter: 'agTextColumnFilter',
        //   cellRenderer: 'statusRenderer',
        //   width: 120,
        // },
        {
          field: 'empty',
          headerName: 'Action',
          cellRenderer: 'linkRenderer',
          pinned: 'right',
          width: 120,
        },
      ]);
    } else {
      setColumnDefs([
        {
          field: 'type',
          headerName: 'Type',
          sortable: 'true',
          filter: 'agTextColumnFilter',
        },
        {
          field: 'name',
          headerName: 'Name',
          flex: '2',
          sortable: 'true',
          valueGetter: (props) => {
            const name = props.data.name;
            const parts = name?.split('-');
            if (parts.length > 3) {
              return parts.slice(3).join('_');
            } else {
              return parts[3];
            }
          },
          filter: 'agTextColumnFilter',
        },
        {
          field: 'applicantType',
          headerName: 'Applicant',
          sortable: 'true',
          filter: 'agTextColumnFilter',
        },
        // {
        //   field: 'status',
        //   headerName: 'Status',
        //   sortable: 'true',
        //   filter: 'agTextColumnFilter',
        //   cellRenderer: 'statusRenderer',
        // },

        {
          field: 'updated_at',
          headerName: 'Uploaded On',
          flex: '2',
          sortable: 'true',
          filter: 'agSetColumnFilter',
          valueGetter: (params) => {
            return getFormattedDateUtil({
              dateString: params.data.updated_at,
              includeTime: true,
            });
          },
        },
        {
          field: 'empty',
          headerName: 'Action',
          cellRenderer: 'linkRenderer',
          pinned: 'right',
          width: 120,
        },
      ]);
    }
  }, [isMobile]);

  if (loading) {
    return (
      <div className="circProgress">
        <CircularProgress size={40} />
      </div>
    );
  }

  return (
    <div className="document-list-page-wrapper">
      <div className="wizard-heading">
        <FsButton
          buttonText={'Back'}
          onClick={() =>
            navigate(`/applicant/applicationForm/${developmentId}`)
          }
        ></FsButton>
        <h2 style={{ marginLeft: '15px' }}>{developmentName}</h2>
      </div>
      <div className="document-info-total-documents">
        Total Documents: {totalDocs}
      </div>

      <div>
        <div className="document-info-cards-wrapper">
          {documentTypes.map((documentType, index) => {
            const cardContent = getCardContent(documentType);

            return (
              <FsCard
                content={cardContent}
                className="document-info-cards"
                key={index}
              />
            );
          })}
        </div>
      </div>

      <div className="documents-upload-button-wrapper">
        <FsButton
          buttonText="Upload"
          onClick={() => setShowUploadModal(true)}
        />
      </div>

      <div
        className="ag-theme-material"
        style={{
          height: 300,
          width: '100%',
        }}
      >
        <AgGridReact
          ref={gridRef}
          context={{
            viewDoc: (doc) => viewDoc(doc),
            deleteDoc: (doc) => deleteDoc(doc),
          }}
          columnDefs={columnDefs}
          defaultColDef={{
            flex: 1,
            minWidth: 100,
          }}
          alwaysShowHorizontalScroll={true}
          frameworkComponents={{
            statusRenderer: StatusRenderer,
            linkRenderer: linkRenderer,
          }}
          rowData={documents}
        ></AgGridReact>
      </div>

      <Modal
        isOpen={open}
        onRequestClose={() => {
          setOpen(false);
        }}
      >
        <Button
          variant="contained"
          color="Primary"
          onClick={() => {
            setOpen(false);
          }}
        >
          <CloseIcon /> Close
        </Button>
        <FileViewer fileType={type} filePath={filePath} />
      </Modal>

      {showUploadModal && (
        <DocumentUploadModal
          applicationData={{ status: applicationStatus }}
          closeUploadModal={() => setShowUploadModal(false)}
          getUpdatedDocuments={getUpdatedDocuments}
          isJointApp={isJointApp}
          role="applicant"
        />
      )}
    </div>
  );
}

export default DocumentsListApplicants;
