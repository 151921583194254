import React from 'react';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import {
  dateStringToDateUtil,
  getFormattedDateUtil,
  getSyncFusionDatepickerClass,
} from '../../../../utils/utils';
import RadioGroup from '../../inputComponents/radioGroup/RadioGroup';

import {
  FormControl,
  InputLabel,
  InputAdornment,
  MenuItem,
  Select,
} from '@material-ui/core';

import './fsInput.scss';
import CssTextField from '../fsTextfield/textField';

const FsInput = ({
  changeHandler,
  errorFields,
  field,
  formState,
  label,
  obj,
  overrideFormState = {},
}) => {
  const { type } = field;

  let targetField = field.id;

  let isRequiredByRule = field.requiredRules
    ? field.requiredRules(formState)
    : false;
  let isRequired = field.required || isRequiredByRule;

  const getInputComponent = () => {
    if (type === 'checkbox') {
      return (
        <input
          checked={formState?.[field.id] || ''}
          className={errorFields?.includes(field.id) ? 'error' : ''}
          id={field.id}
          onChange={(e) => {
            changeHandler(e);
          }}
          type="checkbox"
        />
      );
    } else if (type === 'checkboxSection') {
      return (
        <section className="checkbox-section">
          <div
            className="checkbox-section-text"
            dangerouslySetInnerHTML={{ __html: field.text }}
          ></div>
          <label className="checkbox-section-label">
            <input
              checked={formState?.[field.id] || ''}
              className={errorFields?.includes(field.id) ? 'error' : ''}
              id={field.id}
              onChange={(e) => {
                changeHandler(e);
              }}
              type="checkbox"
            />
            <span className="checkmark-span"></span>
            {/* {hasErrors && (
                    <span className="errorText">{checkboxErrorMessage}</span>
                  )} */}
          </label>
        </section>
      );
    } else if (type === 'date') {
      return (
        <div style={{ height: '80px', width: '300px' }}>
          <DatePickerComponent
            cssClass={getSyncFusionDatepickerClass({
              datepickerError: errorFields.find(
                (fieldId) => fieldId === field.id
              ),
              datepickerFormData: formState?.[field.id],
            })}
            floatLabelType="Auto"
            format="dd/MM/yyyy"
            max={new Date()}
            id={field.id}
            name={field.id}
            onBlur={(e) => {
              changeHandler(e, field);
            }}
            onChange={(e) => {
              const fakeEvent = { target: { value: '' } };
              fakeEvent.target.value = getFormattedDateUtil({
                dateString: e.value,
              });
              return changeHandler(fakeEvent, field);
            }}
            placeholder={field.placeholder + (isRequired ? ' *' : '')}
            value={
              dateStringToDateUtil(
                overrideFormState?.[targetField] || formState?.[targetField]
              ) || ''
            }
          />
          {errorFields.includes(targetField) && (
            <p className="input-error-message">{field.errorMessage}</p>
          )}
        </div>
      );
    } else if (type === 'radioGroup') {
      return (
        <RadioGroup
          formState={formState}
          errorFields={errorFields}
          field={field}
          changeHandler={changeHandler}
        />
      );
    } else if (type === 'radio') {
      return (
        <>
          <input
            checked={formState?.[targetField] === label}
            className="fs-input"
            id={`${targetField}_${label}`}
            name={`${targetField}_${label}`}
            onChange={(e) => changeHandler(e, obj, label)}
            placeholder={field.placeholder}
            type={field.type}
            value={formState?.[targetField] || ''}
          />
          <label className="radio-label" htmlFor={`${targetField}_${label}`}>
            {label}
          </label>
        </>
      );
    } else if (type === 'select') {
      return (
        <>
          <div style={{ height: '80px', width: '300px' }}>
            <FormControl fullWidth variant="outlined">
              <InputLabel className="mui-select-label-custom" id={targetField}>
                {field.label} {isRequired ? ' *' : ''}
              </InputLabel>

              <Select
                className="fs-input mui-select-input-custom"
                error={errorFields.includes(targetField)}
                fullWidth
                label={field.label}
                labelId={field.id}
                placeholder={field.label}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                  getContentAnchorEl: null,
                }}
                name={targetField}
                onChange={(e) => {
                  e.target.id = e.target.name;
                  if (field.id == 'otherIncomeType') {
                    if (e.target.value == 'Additional Employment') {
                      global.showExtraAdditionalIncomeFields = true;
                    } else {
                      global.showExtraAdditionalIncomeFields = false;
                    }
                  }
                  changeHandler(e, field);
                }}
                required={field.required}
                size="medium"
                value={
                  overrideFormState?.[targetField] ||
                  formState?.[targetField] ||
                  ''
                }
              >
                {field.options.map((option) => {
                  return (
                    <MenuItem key={option.value} value={option.value}>
                      {option.value}
                    </MenuItem>
                  );
                })}
              </Select>
              {errorFields.includes(targetField) && (
                <p className="input-error-message">{field.errorMessage}</p>
              )}
            </FormControl>
          </div>
        </>
      );
    } else if (type === 'text') {
      return (
        <div style={{ height: '80px', width: '300px' }}>
          <CssTextField
            error={errorFields.includes(targetField)}
            fullWidth
            id={targetField}
            label={field.placeholder}
            InputProps={{
              readOnly: field.readOnly,
              startAdornment: field.startAdornment ? (
                <InputAdornment position="start">
                  {field.startAdornment}
                </InputAdornment>
              ) : (
                ''
              ),
            }}
            inputProps={{
              maxLength: field.limit ?? 255,
            }}
            onChange={(e) => changeHandler(e, field)}
            required={field.required}
            size="medium"
            value={
              overrideFormState?.[targetField] || formState?.[targetField] || ''
            }
            variant="outlined"
          />
          {errorFields.includes(targetField) && (
            <p className="input-error-message">
              {isRequired &&
              !formState?.[targetField] &&
              !overrideFormState?.[targetField]
                ? 'Field required'
                : field.errorMessage}
            </p>
          )}
        </div>
      );
    } else if (type === 'number') {
      return (
        <div style={{ height: '80px', width: '300px' }}>
          <CssTextField
            error={errorFields.includes(targetField)}
            fullWidth
            id={targetField}
            label={field.placeholder}
            type="number"
            InputProps={{
              readOnly: field.readOnly,
              startAdornment: field.startAdornment ? (
                <InputAdornment position="start">
                  {field.startAdornment}
                </InputAdornment>
              ) : (
                ''
              ),
              step: 'any', // Allow decimal input
            }}
            inputProps={{
              maxLength: field.limit ?? 11,
            }}
            onChange={(e) => changeHandler(e, field)}
            required={isRequired}
            size="medium"
            value={
              overrideFormState?.[targetField] || formState?.[targetField] || ''
            }
            variant="outlined"
            onInput={maxLengthCheck}
          />
          {errorFields.includes(targetField) && (
            <p className="input-error-message">
              {isRequired &&
              !formState?.[targetField] &&
              !overrideFormState?.[targetField]
                ? 'Field required'
                : field.errorMessage}
            </p>
          )}
        </div>
      );
    }
  };

  const maxLengthCheck = (event) => {
    if (event.target.value.length > event.target.maxLength) {
      event.target.value = event.target.value.slice(0, event.target.maxLength);
    }
  };

  return (
    <div key={field.id} className="fs-input-wrapper">
      {getInputComponent()}
    </div>
  );
};

export default FsInput;
