import React from 'react';
import FsInput from '../../../../components/baseComponents/fsInput/FsInput';

const Agreements = ({
  errorFields,
  layout,
  formState,
  handleCheckboxToggle,
}) => {
  return (
    <div className="agreement-section">
      <div className="affordable-purchase-form-text-area">
        {layout.map((field, index) => {
          return (
            <div key={index}>
              <FsInput
                changeHandler={handleCheckboxToggle}
                errorFields={errorFields}
                field={field}
                formState={formState}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Agreements;
