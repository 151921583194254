otherIncomeModalSchema;

import {
  MONEY_FORMAT,
  DATE_FORMAT_REGEX,
  EIRCODE_REGEX,
} from '../../utils//regexUtils';

const getOtherIncomeModalSchema = (isJoint) => {
  const otherIncomeTypeId = 'otherIncomeType';
  const otherIncomeAmountId = 'otherIncomeAmount';
  const frequencyOfOtherPaymentId = 'frequencyOfOtherPayment';

  const otherIncomeProofId = isJoint
    ? 'jointApplicant_proofOfAdditionalIncome'
    : 'proofOfAdditionalIncome';

  const checkIsVisible = () => {
    return global.showExtraAdditionalIncomeFields || false;
  };

  let schema = [
    {
      errorMessage: 'Field required',
      id: otherIncomeTypeId,
      label: 'Income Type',
      options: [
        { value: 'Additional Employment', label: 'Additional Employment' },
        { value: 'Maintenance Payments', label: 'Maintenance Payments' },
        { value: 'Pensions', label: 'Pensions' },
        { value: 'Other', label: 'Other' },
      ],
      required: true,
      type: 'select',
    },
    {
      errorMessage: 'Not a valid value',
      id: otherIncomeAmountId,
      placeholder: 'Income Amount',
      regex: MONEY_FORMAT,
      required: true,
      startAdornment: '€',
      type: 'number',
    },
    {
      errorMessage: 'Field required',
      id: frequencyOfOtherPaymentId,
      label: 'Frequency of Payment',
      options: [
        { value: 'Weekly' },
        { value: 'Fortnightly' },
        { value: 'Monthly' },
      ],
      type: 'select',
      required: true,
    },
    {
      errorMessage: 'Field required',
      id: 'other_income_occupation',
      placeholder: 'Occupation',
      checkIsVisible,
      type: 'text',
    },
    {
      errorMessage: 'Field required',
      id: 'other_income_dateOfCommencement',
      checkIsVisible,
      placeholder: 'Date of Commencement',
      type: 'date',
      required: true,
    },
    {
      errorMessage: 'Field required',
      id: 'other_income_employmentTerms',
      checkIsVisible,
      label: 'Employment Terms',
      options: [
        { value: 'Permanent' },
        { value: 'Part-time' },
        { value: 'Fixed-term' },
      ],
      type: 'select',
      required: true,
    },
    {
      errorMessage: 'Not a valid value',
      id: 'other_income_grossIncome',
      checkIsVisible,
      placeholder: 'Annual Gross Income',
      regex: MONEY_FORMAT,
      startAdornment: '€',
      // requiredRules: requiredRulesEmployed,
      type: 'number',
    },
    {
      errorMessage: 'Not a valid value',
      id: 'other_income_overtime',
      checkIsVisible,
      placeholder: 'Overtime per annum',
      regex: MONEY_FORMAT,
      startAdornment: '€',
      type: 'number',
    },
    {
      errorMessage: 'Not a valid value',
      id: 'other_income_bonus',
      checkIsVisible,
      placeholder: 'Bonus per annum',
      regex: MONEY_FORMAT,
      startAdornment: '€',
      type: 'number',
    },
    {
      errorMessage: 'Not a valid value',
      id: 'other_income_commission',
      checkIsVisible,
      placeholder: 'Commission',
      regex: MONEY_FORMAT,
      startAdornment: '€',
      type: 'number',
    },
    {
      errorMessage: 'Field required',
      id: 'other_income_employerName',
      checkIsVisible,
      placeholder: 'Employer Name',
      type: 'text',
      required: true,
    },
    {
      errorMessage: 'Field required',
      id: 'other_income_employerAddressLine1',
      checkIsVisible,
      placeholder: 'Employer Address Line 1',
      type: 'text',
      required: true,
    },
    {
      errorMessage: 'Field required',
      id: 'other_income_employerAddressLine2',
      checkIsVisible,
      placeholder: 'Employer Address Line 2',
      type: 'text',
    },
    {
      errorMessage: 'Field required',
      id: 'other_income_employerCity',
      checkIsVisible,
      placeholder: 'Employer City',
      type: 'text',
      required: true,
    },
    {
      errorMessage: 'Field required',
      id: 'other_income_employerCounty',
      checkIsVisible,
      label: 'Employer County',
      type: 'select',
      required: true,
      options: [
        { value: 'Antrim' },
        { value: 'Armagh' },
        { value: 'Carlow' },
        { value: 'Cavan' },
        { value: 'Clare' },
        { value: 'Cork' },
        { value: 'Derry' },
        { value: 'Donegal' },
        { value: 'Down' },
        { value: 'Dublin' },
        { value: 'Fermanagh' },
        { value: 'Galway' },
        { value: 'Kerry' },
        { value: 'Kildare' },
        { value: 'Kilkenny' },
        { value: 'Laois' },
        { value: 'Leitrim' },
        { value: 'Limerick' },
        { value: 'Longford' },
        { value: 'Louth' },
        { value: 'Mayo' },
        { value: 'Meath' },
        { value: 'Monaghan' },
        { value: 'Offaly' },
        { value: 'Roscommon' },
        { value: 'Sligo' },
        { value: 'Tipperary' },
        { value: 'Tyrone' },
        { value: 'Waterford' },
        { value: 'Westmeath' },
        { value: 'Wexford' },
        { value: 'Wicklow' },
      ],
    },
    {
      errorMessage: 'Field required',
      id: 'other_income_employerEircode',
      checkIsVisible,
      placeholder: 'Employer Eircode',
      regex: EIRCODE_REGEX,
      required: true,
      type: 'text',
    },
    {
      errorMessage: 'Field required',
      id: 'other_income_typeOfBusiness',
      checkIsVisible,
      placeholder: 'Type of Business',
      required: true,
      type: 'text',
    },
    {
      errorMessage: 'Document required',
      id: 'documents',
      name: 'Upload proof of additional income',
      required: true,
      tooltip: 'Proof of additional income',
      type: 'uploader',
    },
  ];

  return schema;
};

export const otherIncomeModalSchema = (isJoint) => {
  return getOtherIncomeModalSchema(isJoint);
};
