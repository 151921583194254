import React, { useContext, useEffect, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles, styled } from '@material-ui/core/styles';
import DocumentsListApplicants from '../components/DocumentsListApplicants';
import 'react-circular-progressbar/dist/styles.css';
import Grid from '@material-ui/core/Grid';
import IpsSquareLoader from '../components/baseComponents/ipsSquareLoader/IpsSquareLoader';

const token = $('meta[name="csrf-token"]').attr('content');

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: 'center',
}));

function Uploads() {
  const [isLoading, setLoading] = useState(true);
  const [percentage, setPercentage] = useState(0);

  useEffect(async () => {
    setLoading(true);
    await axios
      .get('/getNumDocs', { headers: { 'X-CSRF-TOKEN': token } })
      .then((res) => {
        if (res.data.status === 200) {
          setPercentage(res.data.number);
        }
        setLoading(false);
      });
  }, []);

  if (isLoading) {
    return (
      <div className="App">
        <IpsSquareLoader />
      </div>
    );
  }

  return (
    <Grid
      alignItems="stretch"
      container
      direction="row"
      justifyContent="space-evenly"
    >
      <Grid item xs={12}>
        <Item>
          <center>
            <div>
              <DocumentsListApplicants />
            </div>
          </center>
        </Item>
      </Grid>
    </Grid>
  );
}

export default Uploads;
