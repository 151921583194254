import React, { Component } from 'react';
import DeleteForeverSharpIcon from '@mui/icons-material/DeleteForeverSharp';

export default class DeletelinkRenderer extends Component {
        
    render() {
        const rowId = this.props.data.id;
        return <div>
            <span className={"tableIconButton"}><DeleteForeverSharpIcon onClick={() => {
                this.props.context.deleteCall(rowId);
            }}/></span>
        </div>
    }
}
