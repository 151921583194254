import React from 'react';
import FsInput from '../../../../components/baseComponents/fsInput/FsInput';

const Declarations = ({
  errorFields,
  layout,
  formState,
  handleCheckboxToggle,
  setErrorFields,
}) => {
  const handleDeclarationsCheckboxToggle = (e) => {
    handleCheckboxToggle(e, layout);
  };

  return (
    <>
      <div>
        <h5 className="affordable-purchase-form-section-header">
          Declarations
        </h5>
        <div className="affordable-purchase-form-text-area">
          <p className="form-italic-text">
            <i>Check each box to confirm </i>
          </p>

          <div>
            {layout.map((field, index) => {
              return (
                <div key={index}>
                  <FsInput
                    changeHandler={handleDeclarationsCheckboxToggle}
                    errorFields={errorFields}
                    field={field}
                    formState={formState}
                    setErrorFields={setErrorFields}
                  />
                </div>
              );
            })}
          </div>
          <br />
          <i>
            NOTE: Terms and Conditions for Affordable Dwelling Purchase
            Arrangements are set out in the &nbsp;
            <a
              href="https://www.irishstatutebook.ie/eli/2021/act/25/enacted/en/print#part2"
              target="_blank"
            >
              Affordable Housing Act 2021.
            </a>
          </i>
        </div>
      </div>
    </>
  );
};

export default Declarations;
