import React, { useState, useEffect } from 'react';
import { FaExclamationCircle } from 'react-icons/fa';
import { documentRequirements } from '../../utils/affordablePurchase/documentRequirements';
import { replaceSpecialCharacters } from '../../utils/replaceSpecialCharacters';
import './uploader.scss';
import { Snackbar } from '@material-ui/core';

const MAX_FILE_SIZE = 10485760; // We set max file size on backend to 10MB
const Uploader = ({
  field,
  formState,
  handleSave,
  errorFields,
  isModal = false,
  onChange,
  overrideFormState,
}) => {
  const [hasAddedFile, setHasAddedFile] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackMsg, setSnackMsg] = useState('');
  const [snackType, setSnackType] = useState('bg-danger');

  const getDocumentCount = () => {
    const documentLength = overrideFormState
      ? overrideFormState[field.id]?.length
      : formState[field.id]?.length;
    return (
      <div className="uploaded-file-counter">
        {documentLength > 0 ? (
          <span>{documentLength} documents uploaded</span>
        ) : (
          <span>No documents uploaded</span>
        )}
      </div>
    );
  };

  const getDocumentTooltip = (tooltipId) => {
    const tooltipText = documentRequirements.find(
      (documentField) => documentField.requirement === tooltipId
    )?.tooltip;
    if (tooltipText?.length) {
      {
        return tooltipText.map((li, index) => {
          return <li key={'tooltipId-' + index}>{li}</li>;
        });
      }
    }
  };

  const closeSnack = () => {
    setSnackOpen(false);
    setSnackMsg('');
    setSnackType();
  };

  const handleChange = (e) => {
    let file = e.target.files[0];

    const modifiedFileName = replaceSpecialCharacters(file.name, '_');
    file = new File([file], modifiedFileName, { type: file.type });

    if (file?.size > MAX_FILE_SIZE) {
      e.target.value = null; // reset the input element to clear the selected file
      setSnackOpen(true);
      setSnackMsg('Chosen file size is too large. Maximum size is 10MB');
      setSnackType('bg-danger');
      return;
    }

    // IF file size is too large, return error
    onChange(e);
    setHasAddedFile(true);
  };

  useEffect(() => {
    if (hasAddedFile && !isModal) {
      handleSave();
    }
    setHasAddedFile(false);
  }, [hasAddedFile]);

  return (
    <div className="uploader-container">
      {errorFields?.includes(field.id) && (
        <div>
          <span className="uploader-label-error">{field.errorMessage}</span>
        </div>
      )}
      <div className="uploader-name">{field.name}</div>
      <label className="uploader-input-label">
        Add Document
        <input
          type="file"
          accept="image/*;capture=camera"
          id={field.id}
          name="uploadFile"
          onChange={(e) => {
            handleChange(e);
          }}
          className="uploader-input"
        />
      </label>
      {getDocumentCount()}
      {field.tooltip && (
        <div className="tooltip-wrapper">
          <FaExclamationCircle />
          <div className="tooltip-hover-list">
            <ul className="tooltip-list">
              {getDocumentTooltip(field.tooltip)}
            </ul>
          </div>
        </div>
      )}
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        autoHideDuration={5000}
        className={snackType}
        message={snackMsg}
        onClose={closeSnack}
        open={snackOpen}
      />
    </div>
  );
};
export default Uploader;
