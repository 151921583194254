import React from 'react';
import FsCard from '../../fsCard/FsCard';
import DeleteForeverSharpIcon from '@mui/icons-material/DeleteForeverSharp';

import './activityDetail.scss';

const ActivityDetail = ({ activity, deleteActivity }) => {
  const { id, user, performed_at, meta, message } = activity;

  const formattedDate = new Date(performed_at).toLocaleDateString('en-GB', {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
  });

  const cardContent = (
    <div className="activity-detail-wrapper">
      <div className="activity-detail-topper">
        <span className="activity-username">{user.name}</span>
        <div className="timestamp-and-action">
          {formattedDate}
          <div className="action-badge">{meta}</div>
        </div>

        <div
          className="delete-activity-icon-wrapper"
          onClick={() => deleteActivity(id)}
        >
          <DeleteForeverSharpIcon />
        </div>
      </div>

      <div className="activity-detail-description">
        <p>{message}</p>
      </div>
    </div>
  );

  return <FsCard content={cardContent} className="activity-detail-card" />;
};

export default ActivityDetail;
