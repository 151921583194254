import React from 'react';
import Grid from '@material-ui/core/Grid';
import CssTextField from '../../../components/baseComponents/fsTextfield/textField';

const NotEmployedFormExtras = ({ applicantData }) => {
  return (
    <>
      <Grid item xs={12} sm={12} md={3} lg={3}>
        <CssTextField
          fullWidth
          id="outlined-read-only-input"
          label="Annual Gross Income"
          defaultValue={applicantData?.employment?.maintenance}
          InputProps={{
            readOnly: true,
          }}
          InputLabelProps={{ shrink: !!applicantData?.employment?.maintenance }}
        />
      </Grid>
    </>
  );
};

export default NotEmployedFormExtras;
