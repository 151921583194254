import { MONEY_FORMAT } from '../../utils//regexUtils';

const getFinancialsSchema = () => {
  const accountsSchema = () => {
    return [{ field: 'accounts', id: 'accounts', required: true }];
  };

  const mortgageApprovedSchema = () => {
    const mortgageFields = [
      {
        errorMessage: 'Field required',
        id: 'mortgageProvider',
        // checkIsVisible: (formState) => {
        //   if (formState.mortgageApproved?.toString() === 'true') {
        //     return true;
        //   }
        //   return false;
        // },
        label: 'Mortgage Provider',
        // requiredRules: (formState) => {
        //   if (formState.mortgageApproved?.toString() === 'true') {
        //     return true;
        //   }
        //   return false;
        // },
        required: true,
        type: 'select',
        options: [
          { value: 'AIB' },
          { value: 'BOI' },
          { value: 'PTSB' },
          { value: 'HAVEN' },
          { value: 'EBS' },
          { value: 'Local Authority Mortgage' },
        ],
      },
      {
        errorMessage: 'Not a valid value',
        id: 'mortgageAmount',
        // checkIsVisible: (formState) => {
        //   if (formState.mortgageApproved?.toString() === 'true') {
        //     return true;
        //   }
        //   return false;
        // },
        placeholder: 'Mortgage Amount',
        regex: MONEY_FORMAT,
        startAdornment: '€',
        required: true,
        // requiredRules: (formState) => {
        //   if (formState.mortgageApproved?.toString() === 'true') {
        //     return true;
        //   }
        //   return false;
        // },
        type: 'number',
        limit: 11,
      },
      {
        errorMessage: 'Field required',
        id: 'proofOfMortgage',
        // checkIsVisible: (formState) => {
        //   if (formState.mortgageApproved?.toString() === 'true') {
        //     return true;
        //   }
        //   return false;
        // },
        required: true,
        name: 'Proof of mortgage',
        // requiredRules: (formState) => {
        //   if (formState.mortgageApproved?.toString() === 'true') {
        //     return true;
        //   }
        //   return false;
        // },
        tooltip: 'Confirmation of Mortgage Approval In Principle',
        type: 'uploader',
      },
    ];

    return [...mortgageFields];
  };

  const helpToBuySchema = () => {
    const helpToBuyFields = [
      {
        errorMessage: 'Not a valid value',
        id: 'htbAmount',
        checkIsVisible: (formState) => {
          if (formState.helpToBuyApproved?.toString() === 'true') {
            return true;
          }
          return false;
        },
        placeholder: 'HTB Amount',
        regex: MONEY_FORMAT,
        startAdornment: '€',
        requiredRules: (formState) => {
          return formState.helpToBuyApproved?.toString() === 'true';
        },
        type: 'number',
      },
      {
        errorMessage: 'Field required',
        id: 'proofOfHelpToBuy',
        checkIsVisible: (formState) => {
          if (formState.helpToBuyApproved?.toString() === 'true') {
            return true;
          }
          return false;
        },
        name: 'Proof of Help To Buy',
        requiredRules: (formState) => {
          if (formState.helpToBuyApproved?.toString() === 'true') {
            return true;
          }
          return false;
        },
        tooltip: 'Confirmation of eligibility for Help to Buy Scheme',
        type: 'uploader',
      },
    ];
    return [
      {
        errorMessage: 'Field required',
        id: 'helpToBuyApproved',
        isBoolean: true,
        onChangeCheckFields: [...helpToBuyFields],
        options: [
          { text: 'Yes', value: true },
          { text: 'No', value: false },
        ],
        required: true,
        text: `Are you <a href="https://revenue.ie/en/tax-professionals/ebrief/2023/no-0342023.aspx" target ="_blank">
        Help To Buy
      </a> approved?`,

        type: 'radioGroup',
      },
      ...helpToBuyFields,
    ];
  };
  return [accountsSchema(), mortgageApprovedSchema(), helpToBuySchema()];
};

export const financialsSchema = (formState) => {
  return getFinancialsSchema(formState);
};
