export const DATE_FORMAT_REGEX =
  /(^0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/(\d{4}$)/;
export const EIRCODE_REGEX =
  /^[ACDEFHKNPRTVWXYabcdefhknprtvwxy]{1}[0-9]{2}\s?[ACDEFHKNPRTVWXYabcdefhknprtvwxy0-9]{4}$/;

export const IRISH_PHONE_REGEX =
  /^\s*\(?\s*(\+353|\d{1,4})\s*\)?\s*[\d\s]{5,10}\s*$/;
export const MONEY_FORMAT =
  /^\$?(\d{1,3},?(\d{3},?)*\d{3}(\.\d{0,2})?|\d{1,3}(\.\d{0,2})?|\.\d{1,2}?)$/;
export const PPSN_REGEX = /^([0-9]{7})([A-Za-z]{1,2})$/;
export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const AGE_REGEX = /^(0?[1-9]|[1-9][0-9]|[1][1-9][1-9]|150)$/;
