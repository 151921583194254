import { MONEY_FORMAT } from '../regexUtils';

export const notEmployedSchema = (isJoint) => {
  const targetField = isJoint
    ? 'jointApplicant_employmentStatus'
    : 'employmentStatus';
  const maintenancePaymentsId = isJoint
    ? 'jointApplicant_maintenancePayments'
    : 'maintenancePayments';
  const proofOfIncomeId = isJoint
    ? 'jointApplicant_proofOfIncome'
    : 'proofOfIncome';
  const checkIsVisible = (formState) => {
    if (formState[targetField] === 'Not Employed') {
      return true;
    }
    return false;
  };

  const notEmployedRequired = (formState) => {
    const targetField = isJoint
      ? 'jointApplicant_employmentStatus'
      : 'employmentStatus';

    return formState[targetField] === 'Not Employed';
  };

  const requiredRulesAllEmployment = (formState) => {
    const targetField = isJoint
      ? 'jointApplicant_employmentStatus'
      : 'employmentStatus';
    if (
      formState[targetField] === 'Employed' ||
      formState[targetField] === 'Self Employed' ||
      formState[targetField] === 'Not Employed'
    ) {
      return true;
    }
    return false;
  };

  return [
    {
      errorMessage: 'Not a valid value',
      id: maintenancePaymentsId,
      checkIsVisible,
      placeholder: 'Annual Gross Income *',
      regex: MONEY_FORMAT,
      requiredRules: notEmployedRequired,
      startAdornment: '€',
      type: 'number',
    },
    {
      errorMessage: 'Document required',
      id: proofOfIncomeId,
      checkIsVisible,
      name: 'Proof of social assistance, allowances, pension or social insurance *',
      requiredRules: requiredRulesAllEmployment,
      tooltip: 'Not Employed Income Help',
      type: 'uploader',
    },
  ];
};
