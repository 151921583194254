import React, { useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';

const CheckboxTable = ({ columns = [], onSelectionModelChange, rows = [] }) => {
  return (
    <div className="checkbox-table" style={{ height: 400, width: '100%' }}>
      <DataGrid
        checkboxSelection
        onSelectionModelChange={onSelectionModelChange}
        columns={columns}
        pageSize={10}
        rows={rows}
        rowsPerPageOptions={[10]}
      />
    </div>
  );
};

export default CheckboxTable;
