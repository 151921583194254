import React, { useState, useEffect } from "react";
import { CircularProgress, Select } from "@material-ui/core";

function emailTemplateSelect(props){
    const [loading, setLoading] = useState(true);
    const [templates, setTemplates] = useState({});

    useEffect(async () => {
        setLoading(true);
        await axios.get("/fetchEmailTemplates").then((res) => {
            if (res.data.templates) {
                setTemplates(res.data.templates);
            }
        });
        setLoading(false);
    }, []);
    if (loading) {
        return (
            <div className="circProgress">
                <CircularProgress size={40} />
            </div>
        );
    }

    return (
        <Select native defaultValue={props.sel}
        onChange={(event) => {props.func(event.target.value)}}
        label="Email Templates"
        inputProps={{name: 'template',id: 'template',}}>
        <option aria-label={"None"} value={""} key={0}>-- Select Email Template --</option>
        {templates.map((template) => (
         <option value={template.id} key={template.id}>{template.subject}</option>
        ))}
         </Select>
    );
}
export default emailTemplateSelect;