import React, { useState, useEffect } from 'react';

import useAxios from '../../../hooks/useAxios';

import Timeline from '../../../components/baseComponents/timeline/Timeline';
import FsCard from '../../../components/baseComponents/fsCard/FsCard';
import FsButton from '../../../components/baseComponents/fsButton/FsButton';

import './commentsTab.scss';
const CommentsTab = ({ comments, setComments, applicationId }) => {
  const [newComment, setNewComment] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [inputHeight, setInputHeight] = useState('auto'); // State to track input height

  const { callAxios } = useAxios();

  const handleChangeComment = (e) => {
    setNewComment(e.target.value);
    if (e.target.value.replace(/\s/g, '').length) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
    const lines = e?.target?.value?.split('\n')?.length;
    const newHeight = `${lines * 20}px`; // Adjust this value according to your needs
    setInputHeight(newHeight);
  };

  const handleAddComment = async () => {
    const response = await callAxios(
      'post',
      `/application/${applicationId}/history`,
      {
        comment: newComment,
        action: 'comment',
      }
    );

    if (response.status === 200) {
      addCommentToList({
        id: response.data.history.id,
        message: response.data.history.message,
        meta: response.data.history.meta,
        user: response.data.history.user,
        performed_at: response.data.history.performed_at,
      });
    }
  };

  const addCommentToList = (newComment) => {
    setComments([newComment, ...comments]);
    setNewComment('');
  };

  const deleteComment = async (id) => {
    const response = await callAxios('delete', `/history/${id}`);

    if (response.status === 200) {
      const filtered = comments.filter((comment) => {
        return comment.id !== id;
      });

      setComments(filtered);
    }
  };

  const cardContent = (
    <>
      <div className="comment-input-wrapper">
        <textarea
          onChange={handleChangeComment}
          value={newComment}
          onSubmit={handleAddComment}
          style={{ minHeight: 150, width: 300, height: inputHeight }}
        />
        <FsButton
          onClick={handleAddComment}
          buttonText="Add"
          disabled={isButtonDisabled}
          className="add-comment-button"
        />
      </div>

      <Timeline activities={comments} deleteActivity={deleteComment} />
    </>
  );

  return <FsCard content={cardContent} className="comments-card-wrapper" />;
};

export default CommentsTab;
